import AxiosInstance from '@/services/AxiosInstance';

const sendChannelsEndpoint = '/send-channels';

/**
 * Class to interact with the Send Channels API
 */
export default class SendChannelRouter {
    /**
     * Retrieves all send channels.
     *
     * @async
     * @returns {Promise<Object>} - A promise that resolves to the list of all send channels.
     * @throws {Error} - Throws an error if retrieval fails.
     */
    async FindAll() {
        try {
            const response = await AxiosInstance.get(sendChannelsEndpoint);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to retrieve send channels: ${error.message}`);
        }
    }
}
