import AxiosInstance from '@/services/AxiosInstance';

const collaboratorCalendarEndpoint = '/collaborator-calendars';

/**
 * Class to interact with the Collaborator Calendar API
 */
export default class CollaboratorCalendarRouter {
    /**
     * Retrieves all collaborator calendars.
     *
     * @async
     * @returns {Promise<Object>} - A promise that resolves to the list of all collaborator calendars.
     * @throws {Error} - Throws an error if retrieval fails.
     */
    async FindAll() {
        try {
            const response = await AxiosInstance.get(collaboratorCalendarEndpoint);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to retrieve collaborator calendars: ${error.message}`);
        }
    }
}
