import Auth from "../services/Auth";
import Token from "../services/Token";

/**
 * Retrieves the current authorization token.
 * @type {Object|null} user - The current user's authorization token or null if not logged in.
 */
const user = Token.getAuthorizationToken();

/**
 * Defines the initial state based on whether the user is logged in or not.
 * @type {Object} initialState - The initial state object.
 * @property {Object} status - The status of the authentication.
 * @property {boolean} status.loggedIn - Indicates if the user is logged in.
 * @property {Object|null} user - The current user data or null if not logged in.
 */
const initialState = user
    ? { status: { loggedIn: true }, user }
    : { status: { loggedIn: false }, user: null };

/**
 * Vuex module for authentication management.
 * @namespace auth
 */
export const auth = {
    namespaced: true,
    state: initialState,

    /**
     * Vuex actions for handling authentication logic.
     * @property {Object} actions - Authentication actions.
     */
    actions: {
        /**
         * Logs in the user.
         * 
         * @async
         * @param {Object} context - Vuex action context.
         * @param {Object} user - The user data for login.
         * @returns {Promise<Object>} A promise that resolves with the user data if login is successful, or rejects with an error.
         */
        async login({ commit }, user) {
            return Auth.login(user)
                .then(
                    user => {
                        commit('loginSuccess', user);
                        return Promise.resolve(user);
                    },
                    error => {
                        commit('loginFailure');
                        return Promise.reject(error);
                    }
                );
        },

        /**
         * Logs out the user.
         * 
         * @async
         * @param {Object} context - Vuex action context.
         */
        async logout({ commit }) {
            Auth.logout();
            commit('logout');
        },

        /**
         * Refreshes the user's token.
         * 
         * @async
         * @param {Object} context - Vuex action context.
         * @param {string} refreshToken - The new refresh token.
         */
        async refresh({ commit }, refreshToken) {
            commit('refreshToken', refreshToken);
        }
    },

    /**
     * Vuex mutations for updating the state.
     * @property {Object} mutations - Authentication mutations.
     */
    mutations: {
        /**
         * Sets the state when login is successful.
         * @param {Object} state - Vuex state.
         * @param {Object} user - The user data.
         */
        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.user = user;
        },

        /**
         * Sets the state when login fails.
         * @param {Object} state - Vuex state.
         */
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },

        /**
         * Resets the state when the user logs out.
         * @param {Object} state - Vuex state.
         */
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        },

        /**
         * Updates the state with the new refresh token.
         * @param {Object} state - Vuex state.
         * @param {string} refreshToken - The new refresh token.
         */
        refreshToken(state, refreshToken) {
            state.status.loggedIn = true;
            state.user = { ...state.user, refreshToken: refreshToken };
        }
    }
};
