import mitt from 'mitt';

/**
 * Event emitter instance using the 'mitt' library.
 * 
 * This emitter allows components or services to publish and subscribe to events
 * in a decoupled manner, facilitating communication across different parts of the application.
 * 
 * @example
 * // To emit an event:
 * emitter.emit('eventName', eventData);
 * 
 * // To listen for an event:
 * emitter.on('eventName', eventHandler);
 * 
 * @constant {MittEmitter} emitter - The global event emitter.
 */
const emitter = mitt();

export default emitter;
