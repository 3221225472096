import AxiosInstance from '@/services/AxiosInstance';

const statusEndpoint = '/server/status';

/**
 * Class to interact with the Server Status API
 */
export default class StatusRouter {
    /**
     * Retrieves the server status.
     *
     * @async
     * @returns {Promise<Object>} - A promise that resolves to the server status data.
     * @throws {Error} - Throws an error if retrieval fails.
     */
    async Status() {
        try {
            const response = await AxiosInstance.get(statusEndpoint);
            return response?.data;
        } catch (error) {
            throw new Error(`Failed to retrieve server status: ${error.message}`);
        }
    }
}
