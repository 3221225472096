import AxiosInstance from '@/services/AxiosInstance';

const permissionsEndpoint = '/permissions';

/**
 * Class to interact with the Permissions API
 */
export default class PermissionRouter {
    /**
     * Searches for permissions based on the provided filter parameters.
     *
     * @async
     * @param {Object} filterParams - The search criteria for permissions.
     * @returns {Promise<Object>} - A promise that resolves to the search results.
     * @throws {Error} - Throws an error if the search fails.
     */
    async Search(filterParams) {
        try {
            const response = await AxiosInstance.get(`${permissionsEndpoint}?search=${filterParams}`);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to search permissions: ${error.message}`);
        }
    }

    /**
     * Retrieves all permissions.
     *
     * @async
     * @returns {Promise<Object>} - A promise that resolves to the list of all permissions.
     * @throws {Error} - Throws an error if retrieval fails.
     */
    async FindAll() {
        try {
            const response = await AxiosInstance.get(permissionsEndpoint);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to retrieve permissions: ${error.message}`);
        }
    }

    /**
     * Creates a new permission with the provided data.
     *
     * @async
     * @param {Object} payload - The data for the new permission.
     * @returns {Promise<Object>} - A promise that resolves to the created permission data.
     * @throws {Error} - Throws an error if creation fails.
     */
    async Create(payload) {
        try {
            const response = await AxiosInstance.post(permissionsEndpoint, payload);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to create permission: ${error.message}`);
        }
    }

    /**
     * Updates an existing permission with the provided data.
     *
     * @async
     * @param {Object} payload - The updated data for the permission.
     * @param {number} id - The unique identifier of the permission.
     * @returns {Promise<Object>} - A promise that resolves to the updated permission data.
     * @throws {Error} - Throws an error if the update fails.
     */
    async Update(payload, id) {
        try {
            const response = await AxiosInstance.put(`${permissionsEndpoint}/${id}`, payload);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to update permission: ${error.message}`);
        }
    }

    /**
     * Removes one or more permissions based on the provided data.
     *
     * @async
     * @param {Object} payload - The data identifying the permissions to remove.
     * @returns {Promise<Object>} - A promise that resolves when the removal is complete.
     * @throws {Error} - Throws an error if removal fails.
     */
    async Remove(payload) {
        try {
            const response = await AxiosInstance.delete(permissionsEndpoint, { data: payload });
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to remove permission(s): ${error.message}`);
        }
    }
}
