import AxiosInstance from '@/services/AxiosInstance';

const departmentEndpoint = '/departments';

/**
 * Class to interact with the Department API
 */
export default class DepartmentRouter {
    /**
     * Searches for departments based on the provided filter parameters.
     *
     * @async
     * @param {Object} filterParams - The search criteria for departments.
     * @returns {Promise<Object>} - A promise that resolves to the search results.
     * @throws {Error} - Throws an error if the search fails.
     */
    async Search(filterParams) {
        try {
            const response = await AxiosInstance.get(`${departmentEndpoint}?search=${filterParams}`);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to search departments: ${error.message}`);
        }
    }

    /**
     * Creates a new department with the provided data.
     *
     * @async
     * @param {Object} payload - The data for the new department.
     * @returns {Promise<Object>} - A promise that resolves to the created department data.
     * @throws {Error} - Throws an error if the creation fails.
     */
    async Create(payload) {
        try {
            const response = await AxiosInstance.post(departmentEndpoint, payload);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to create department: ${error.message}`);
        }
    }

    /**
     * Updates an existing department with the provided data.
     *
     * @async
     * @param {Object} payload - The updated data for the department.
     * @param {number} id - The unique identifier of the department.
     * @returns {Promise<Object>} - A promise that resolves to the updated department data.
     * @throws {Error} - Throws an error if the update fails.
     */
    async Update(payload, id) {
        try {
            const response = await AxiosInstance.put(`${departmentEndpoint}/${id}`, payload);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to update department: ${error.message}`);
        }
    }

    /**
     * Sets a manager for a department with the provided data.
     *
     * @async
     * @param {Object} payload - The data to set the manager for a department.
     * @returns {Promise<Object>} - A promise that resolves to the updated department data.
     * @throws {Error} - Throws an error if setting the manager fails.
     */
    async SetManager(payload) {
        try {
            const response = await AxiosInstance.patch(departmentEndpoint, payload);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to set manager: ${error.message}`);
        }
    }

    /**
     * Retrieves all departments.
     *
     * @async
     * @returns {Promise<Object>} - A promise that resolves to the list of all departments.
     * @throws {Error} - Throws an error if retrieval fails.
     */
    async FindAll() {
        try {
            const response = await AxiosInstance.get(departmentEndpoint);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to retrieve departments: ${error.message}`);
        }
    }

    /**
     * Removes one or more departments based on the provided data.
     *
     * @async
     * @param {Object} payload - The data identifying the departments to remove.
     * @returns {Promise<Object>} - A promise that resolves when the removal is complete.
     * @throws {Error} - Throws an error if removal fails.
     */
    async Remove(payload) {
        try {
            const response = await AxiosInstance.delete(departmentEndpoint, { data: payload });
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to remove departments: ${error.message}`);
        }
    }
}
