import AxiosInstance from '@/services/AxiosInstance';

const payrollDocumentModelEndpoint = '/payroll-document-model';

/**
 * Class to interact with the Payroll Document Model API
 */
export default class PayrollDocumentModelRouter {
    /**
     * Retrieves all payroll document models.
     *
     * @async
     * @returns {Promise<Object>} - A promise that resolves to the list of all payroll document models.
     * @throws {Error} - Throws an error if retrieval fails.
     */
    async FindAll() {
        try {
            const response = await AxiosInstance.get(payrollDocumentModelEndpoint);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to retrieve payroll document models: ${error.message}`);
        }
    }
}
