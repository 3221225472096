import AxiosInstance from '@/services/AxiosInstance';

const documentEndpoint = '/documents';
const payrollEndpoint = `${documentEndpoint}/payroll`;
const processPayrollEndpoint = `${payrollEndpoint}/process`;
const processPayrollStatusEndpoint = `${payrollEndpoint}/status?identifier=`;
const documentsFind = `${documentEndpoint}/find`;
const viewDocumentEndpoint = `${documentEndpoint}/view`;

/**
 * Class to interact with the Document API
 */
export default class DocumentRouter {
    /**
     * Searches for documents based on the provided filters.
     *
     * @async
     * @param {Object} filters - The search criteria for documents.
     * @returns {Promise<Object>} - A promise that resolves to the search results.
     * @throws {Error} - Throws an error if the search fails.
     */
    async Search(filters) {
        try {
            const response = await AxiosInstance.get(`${documentEndpoint}?search=${filters}`);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to search documents: ${error.message}`);
        }
    }

    /**
     * Exports documents based on the provided filters.
     *
     * @async
     * @param {Object} filters - The export criteria for documents.
     * @param {boolean} removeProtection - Whether to remove protection from the export.
     * @returns {Promise<Object>} - A promise that resolves to the exported data.
     * @throws {Error} - Throws an error if the export fails.
     */
    async Export(filters, removeProtection) {
        try {
            const response = await AxiosInstance.get(`${documentEndpoint}/export?filter=${filters}&removeProtection=${removeProtection}`);
            return response?.data;
        } catch (error) {
            throw new Error(`Failed to export documents: ${error.message}`);
        }
    }

    /**
     * Downloads the exported document with the given hash.
     *
     * @async
     * @param {string} hash - The unique hash for the document.
     * @returns {Promise<void>} - A promise that resolves when the download completes.
     * @throws {Error} - Throws an error if the download fails.
     */
    async Download(hash) {
        try {
            const response = await AxiosInstance.get(`${documentEndpoint}/export/download?hash=${hash}`, { responseType: 'blob' });

            const contentDisposition = response.headers.get('Content-Disposition');
            let filename = 'export.zip';
            if (contentDisposition) {
                const match = contentDisposition.match(/filename="(.+)"/);
                if (match) {
                    filename = match[1];
                }
            }

            const link = document.createElement('a');
            link.href = URL.createObjectURL(new Blob([response.data]));
            link.setAttribute('download', filename);
            link.style.display = 'none';

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
        } catch (error) {
            throw new Error(`Failed to download document: ${error.message}`);
        }
    }

    /**
     * Download a single document
     *
     * @async
     * @param {number} - The ID of document to download
     * @throws {Error} - Throws an error if the download fails.
     */
    async DocumentDownload(ID, user = false) {
        try {
            const response = await AxiosInstance.get(`${documentEndpoint}${user ? '/user' : ''}/download/${ID}`, {
                responseType: 'blob'
            });

            console.log(response.headers);

            const contentDisposition = response.headers['content-disposition'];
            let filename = 'document.pdf';
            if (contentDisposition) {
                const match = contentDisposition.match(/filename="(.+)"/);
                if (match) {
                    filename = match[1];
                }
            }

            const link = document.createElement('a');
            link.href = URL.createObjectURL(response.data);
            link.setAttribute('download', filename);
            link.style.display = 'none';

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
        } catch (error) {
            throw new Error(`Failed on exporting the report from assessment id: ${ID} error: ${error.message}`);
        }
    }

    /**
     * Retrieves all documents.
     *
     * @async
     * @returns {Promise<Object>} - A promise that resolves to the list of all documents.
     * @throws {Error} - Throws an error if retrieval fails.
     */
    async FindAll() {
        try {
            const response = await AxiosInstance.get(documentEndpoint);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to retrieve documents: ${error.message}`);
        }
    }

    /**
     * Updates a document with the provided payload.
     *
     * @async
     * @param {number} id - The unique identifier of the document.
     * @param {Object} payload - The updated data for the document.
     * @returns {Promise<Object>} - A promise that resolves to the updated document data.
     * @throws {Error} - Throws an error if the update fails.
     */
    async Update(id, payload) {
        try {
            const response = await AxiosInstance.put(`${documentEndpoint}/${id}`, payload);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to update document: ${error.message}`);
        }
    }

    /**
     * @deprecated Use Search(filters) instead.
     * Searches for documents using a slug and filters.
     *
     * @async
     * @param {Object} filters - The search criteria for documents.
     * @param {string} slug - The slug for the document type.
     * @returns {Promise<Object>} - A promise that resolves to the search results.
     * @throws {Error} - Throws an error if the search fails.
     */
    async Find(filters, slug) {
        try {
            const response = await AxiosInstance.get(`${documentsFind}?slug=${slug}&search=${filters}`);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to find documents by slug: ${error.message}`);
        }
    }

    /**
     * Processes payroll with the provided data.
     *
     * @async
     * @param {Object} payload - The data for payroll processing.
     * @returns {Promise<Object>} - A promise that resolves to the processed payroll data.
     * @throws {Error} - Throws an error if the payroll process fails.
     */
    async PayrollProcess(payload) {
        try {
            const response = await AxiosInstance.post(processPayrollEndpoint, payload);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to process payroll: ${error.message}`);
        }
    }

    /**
     * Retrieves the status of a payroll process.
     *
     * @async
     * @param {string} identifier - The unique identifier for the payroll process.
     * @returns {Promise<Object>} - A promise that resolves to the payroll process status.
     * @throws {Error} - Throws an error if the status retrieval fails.
     */
    async PayrollProcessStatus(identifier) {
        try {
            const response = await AxiosInstance.get(`${processPayrollStatusEndpoint}${identifier}`);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to retrieve payroll process status: ${error.message}`);
        }
    }

    /**
     * Views a document in the specified format.
     *
     * @async
     * @param {string} format - The format in which to view the document.
     * @param {Object} payload - The data for viewing the document.
     * @returns {Promise<Object>} - A promise that resolves to the viewed document data.
     * @throws {Error} - Throws an error if viewing the document fails.
     */
    async View(format, payload) {
        try {
            const response = await AxiosInstance.post(`${viewDocumentEndpoint}?format=${format}`, payload);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to view document: ${error.message}`);
        }
    }

    /**
     * Removes one or more documents based on the provided data.
     *
     * @async
     * @param {Object} payload - The data identifying the documents to remove.
     * @returns {Promise<Object>} - A promise that resolves when the removal is complete.
     * @throws {Error} - Throws an error if removal fails.
     */
    async Remove(payload) {
        try {
            const response = await AxiosInstance.delete(documentEndpoint, { data: payload });
            return response?.data;
        } catch (error) {
            throw new Error(`Failed to remove documents: ${error.message}`);
        }
    }
}
