import AxiosInstance from '@/services/AxiosInstance';

const assessmentMetricEndpoint = 'assessment-metrics';
const assessmentMetricTemplateEndpoint = `${assessmentMetricEndpoint}/templates`;

/**
 * Class to interact with the Assessment Metric API.
 */
export default class AssessmentMetricRouter {
    /**
     * Searches for assessment metrics based on the provided payload.
     *
     * @async
     * @param {Object} payload - The search criteria for assessment metrics.
     * @returns {Promise<Object>} - A promise that resolves to the search results.
     * @throws {Error} - Throws an error if the search fails.
     */
    async Search(payload) {
        try {
            const {
                data: { data }
            } = await AxiosInstance.post(`${assessmentMetricEndpoint}/search`, payload);
            return data;
        } catch (error) {
            throw new Error(`Failed to search assessment metrics: ${error.message}`);
        }
    }

    /**
     * Retrieves the first assessment metric by its ID.
     *
     * @async
     * @param {number} ID - The identifier of the assessment metric.
     * @returns {Promise<Object>} - A promise that resolves to the assessment metric data.
     * @throws {Error} - Throws an error if retrieval fails.
     */
    async FirstByID(ID) {
        try {
            const {
                data: { data }
            } = await AxiosInstance.get(`${assessmentMetricEndpoint}/${ID}`);
            return data;
        } catch (error) {
            throw new Error(`Failed to retrieve assessment metric by ID: ${error.message}`);
        }
    }

    /**
     * Retrieves the all assessment metrics associated with assessment
     *
     * @async
     * @param {number} ID - The identifier of the assessment
     * @returns {Promise<Object>} - A promise that resolves to the assessment metric data
     * @throws {Error} - Throws an error if retrieval faild
     */
    async FindAllByAssessmentID(ID) {
        try {
            const {
                data: { data }
            } = await AxiosInstance.get(`${assessmentMetricEndpoint}/assessment/${ID}`);
            return data;
        } catch (error) {
            throw new Error(`Failed to retrieve assessment metric by ID: ${error.message}`);
        }
    }

    /**
     * Updates an existing assessment metric by ID.
     *
     * @async
     * @param {number} ID - The unique identifier of the assessment metric.
     * @param {Object} payload - The updated data for the assessment metric.
     * @returns {Promise<Object>} - A promise that resolves when the update is complete.
     * @throws {Error} - Throws an error if the update fails.
     */
    async Update(ID, payload) {
        try {
            return await AxiosInstance.put(`${assessmentMetricEndpoint}/${ID}`, payload);
        } catch (error) {
            throw new Error(`Failed to update assessment metric: ${error.message}`);
        }
    }

    /**
     * Create assessment metric template.
     *
     * @async
     * @param {Object} payload - The updated data for the assessment metric template.
     * @returns {Promise<Object>} - A promise that resolves when the update is complete.
     * @throws {Error} - Throws an error if the update fails.
     */
    async CreateTemplate(payload) {
        try {
            return await AxiosInstance.post(`${assessmentMetricTemplateEndpoint}`, payload);
        } catch (error) {
            throw new Error(`Failed to create assessment metric template: ${error.message}`);
        }
    }

    /**
     * Updates an existing assessment metric template by ID.
     *
     * @async
     * @param {number} ID - The unique identifier of the assessment metric template.
     * @param {Object} payload - The updated data for the assessment metric template.
     * @returns {Promise<Object>} - A promise that resolves when the update is complete.
     * @throws {Error} - Throws an error if the update fails.
     */
    async UpdateTemplate(ID, payload) {
        try {
            return await AxiosInstance.put(`${assessmentMetricTemplateEndpoint}/${ID}`, payload);
        } catch (error) {
            throw new Error(`Failed to update assessment metric template: ${error.message}`);
        }
    }

    /**
     * Saves the form data for an assessment metric.
     *
     * @async
     * @param {number} ID - The unique identifier of the assessment metric.
     * @param {Object} [responseValue = {}] - The response value for the assessment metric.
     * @param {Object} [evaluatorComments = ''] - The evaluator's comments for the assessment metric.
     * @returns {Promise<Object>} - Resolves with the saved form data upon success.
     * @throws {Error} - Throws an error if saving the form data fails.
     */
    async FormSave(ID, responseValue = {}, evaluatorComments = '') {
        const payload = {
            evaluatorComments: evaluatorComments,
            responseValue: responseValue
        };

        if (responseValue != null) payload.responseValue = responseValue;

        try {
            const {
                data: { data }
            } = await AxiosInstance.patch(`${assessmentMetricEndpoint}/form-save/${ID}`, payload);
            return data;
        } catch (error) {
            throw new Error(`Unable to save assessment metric form data: ${error.message}`);
        }
    }

    /**
     * Removes one or more assessment metrics based on their IDs.
     *
     * @async
     * @param {Array<string>} IDs - The list of unique identifiers for the assessment metrics to be removed.
     * @returns {Promise<void>} - A promise that resolves when the deletion is complete.
     * @throws {Error} - Throws an error if the deletion fails.
     */
    async Remove(IDs) {
        try {
            await AxiosInstance.delete(assessmentMetricEndpoint, { data: IDs });
        } catch (error) {
            throw new Error(`Failed to remove assessment metrics: ${error.message}`);
        }
    }

    /**
     * Assigns options to a specific assessment metric template.
     *
     * @async
     * @param {string} assessmentMetricID - The unique identifier of the assessment metric.
     * @param {Array<string>} assessmentMetricOptionIDs - The list of option IDs to assign.
     * @returns {Promise<Object>} - A promise that resolves to the updated template data.
     * @throws {Error} - Throws an error if the assignment fails.
     */
    async AssignOptionsToTemplate(assessmentMetricID, assessmentMetricOptionIDs) {
        const payload = {
            assessmentMetricID: assessmentMetricID,
            assessmentMetricOptionIDs: assessmentMetricOptionIDs
        };

        try {
            const {
                data: { data }
            } = await AxiosInstance.post(`${assessmentMetricTemplateEndpoint}/assign-options`, payload);
            return data;
        } catch (error) {
            throw new Error(`Failed to assign options to assessment metric template: ${error.message}`);
        }
    }

    /**
     * Marks an assessment metric as finished.
     *
     * @async
     * @param {number} ID - The unique identifier of the assessment metric.
     * @param {Object} payload - The data to complete the assessment metric.
     * @returns {Promise<Object>} - A promise that resolves to the finished assessment metric data.
     * @throws {Error} - Throws an error if marking as finished fails.
     */
    async FinishAssessmentMetric(ID, payload) {
        try {
            const {
                data: { data }
            } = await AxiosInstance.post(`${assessmentMetricEndpoint}/finish/${ID}`, payload);
            return data;
        } catch (error) {
            throw new Error(`Failed to finish assessment metric: ${error.message}`);
        }
    }
}
