import AxiosInstance from '@/services/AxiosInstance';

const menusEndpoint = '/menus';

/**
 * Class to interact with the Menu API
 */
export default class MenuRouter {
    /**
     * Searches for menus based on the provided filter parameters.
     *
     * @async
     * @param {Object} filterParams - The search criteria for menus.
     * @returns {Promise<Object>} - A promise that resolves to the search results.
     * @throws {Error} - Throws an error if the search fails.
     */
    async Search(filterParams) {
        try {
            const response = await AxiosInstance.get(`${menusEndpoint}?search=${filterParams}`);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to search menus: ${error.message}`);
        }
    }

    /**
     * Retrieves all menus.
     *
     * @async
     * @returns {Promise<Object>} - A promise that resolves to the list of all menus.
     * @throws {Error} - Throws an error if retrieval fails.
     */
    async FindAll() {
        try {
            const response = await AxiosInstance.get(menusEndpoint);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to retrieve menus: ${error.message}`);
        }
    }

    /**
     * Retrieves a menu by its ID.
     *
     * @async
     * @param {number} id - The unique identifier of the menu.
     * @returns {Promise<Object>} - A promise that resolves to the menu data.
     * @throws {Error} - Throws an error if retrieval fails.
     */
    async FindById(id) {
        try {
            const response = await AxiosInstance.get(`${menusEndpoint}/${id}`);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to retrieve menu by ID: ${error.message}`);
        }
    }

    /**
     * Creates a new menu with the provided data.
     *
     * @async
     * @param {Object} payload - The data for the new menu.
     * @returns {Promise<Object>} - A promise that resolves to the created menu data.
     * @throws {Error} - Throws an error if creation fails.
     */
    async Create(payload) {
        try {
            const response = await AxiosInstance.post(menusEndpoint, payload);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to create menu: ${error.message}`);
        }
    }

    /**
     * Updates an existing menu with the provided data.
     *
     * @async
     * @param {Object} payload - The updated data for the menu.
     * @param {number} id - The unique identifier of the menu.
     * @returns {Promise<Object>} - A promise that resolves to the updated menu data.
     * @throws {Error} - Throws an error if the update fails.
     */
    async Update(payload, id) {
        try {
            const response = await AxiosInstance.put(`${menusEndpoint}/${id}`, payload);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to update menu: ${error.message}`);
        }
    }

    /**
     * Removes one or more menus based on the provided data.
     *
     * @async
     * @param {Object} payload - The data identifying the menus to remove.
     * @returns {Promise<Object>} - A promise that resolves when the removal is complete.
     * @throws {Error} - Throws an error if removal fails.
     */
    async Remove(payload) {
        try {
            const response = await AxiosInstance.delete(menusEndpoint, { data: payload });
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to remove menu(s): ${error.message}`);
        }
    }
}
