<script>
    import { FilterMatchMode } from 'primevue/api';
    import { defineComponent } from 'vue';
    import FilterModal from '../../Utils/Filter.vue';
    import ProgressModal from '../../Utils/Progress.vue';
    import CollaboratorModal from './CollaboratorModal.vue';
    import CollaboratorRouter from '@/api/routes/Collaborator';
    export default defineComponent({
        components: {
            FilterModal,
            ProgressModal,
            CollaboratorModal
        },
        data() {
            return {
                loading: false,
                pagination: {},
                pageRows: 10,
                filterModal: {
                    show: false,
                    showFilters: ['people', 'departments', 'companies', 'general'],
                    selectedFilters: {}
                },
                hasFilters: false,
                collaborators: {
                    records: [],
                    totalRecords: 0,
                    selection: []
                },
                collaboratorModal: {
                    show: false,
                    save: false,
                    valid: false,
                    progress: false,
                    action: 'create',
                    title: null,
                    collaborator: {}
                },
                filters: {
                    name: {
                        value: '',
                        matchMode: FilterMatchMode.CONTAINS
                    }
                },
                collaboratorApi: new CollaboratorRouter()
            };
        },
        created() {
            this.filterInit();
        },
        mounted() {
            this.pageRows = this.$refs.dt.rows;
            this.loadCollaborators();
        },
        methods: {
            showCollaboratorModal(action, slot) {
                this.collaboratorModal.action = action;
                this.collaboratorModal.collaborator = action == 'update' ? { ...slot } : null;
                this.collaboratorModal.title = `${action == 'create' ? 'Cadastrar' : 'Atualizar'} colaborador`;
                this.collaboratorModal.show = true;
            },
            valid(event) {
                if (event) {
                    this.collaboratorModal.valid = true;
                    return;
                }
                this.collaboratorModal.valid = false;
            },
            collaboratorModalResetState() {
                this.collaboratorModal.show = false;
                this.collaboratorModal.valid = false;
                this.collaboratorModal.save = false;
                this.collaboratorModal.collaborator = {};
                this.loadCollaborators();
            },
            collaboratorsRemove() {
                this.$confirm.require({
                    header: 'Excluir',
                    message: 'Ao realizar a exclusão não será possível desfazer a operação, deseja continuar?',
                    icon: 'far fa-question-circle',
                    acceptLabel: 'Sim',
                    rejectLabel: 'Não',
                    acceptClass: 'p-button-danger',
                    accept: () => {
                        this.collaboratorApi
                            .Remove(
                                this.collaborators.selection.map((c) => {
                                    return c.ID;
                                })
                            )
                            .then(() => {
                                this.$toast.add({
                                    severity: 'success',
                                    summary: 'Sucesso',
                                    detail: 'Operação realizada com sucesso',
                                    life: 3000
                                });
                            })
                            .finally(this.loadCollaborators());
                    }
                });
            },
            collaboratorStatus(eventsSlot) {
                try {
                    eventsSlot.sort(function (a, b) {
                        return new Date(b.createdAt) - new Date(a.createdAt);
                    });
                    return eventsSlot[0].collaboratorEvent.description;
                } catch (e) {
                    return '';
                }
            },
            loadCollaborators() {
                this.loading = true;
                setTimeout(() => {
                    this.collaboratorApi
                        .Search(JSON.stringify(this.pagination))
                        .then((resp) => {
                            this.collaborators.records = resp.collaborators;
                            this.collaborators.totalRecords = resp.totalRecords;
                        })
                        .finally((this.loading = false));
                }, Math.random() * 1000 + 250);
            },
            closeFilterModal() {
                this.filterModal.show = false;
            },
            showFilterModal() {
                this.filterModal.show = true;
            },
            makeFilter(filter) {
                this.filterModal.selectedFilters = Object.assign({}, this.filterModal.selectedFilters, filter);
                this.filters.global = Object.assign({}, this.filters.global, filter);
            },
            clearFilters() {
                this.filterModal.selectedFilters = [];
                this.filterInit();
                this.hasFilters = false;
                this.onFilter();
            },
            filterSearch() {
                this.filterModal.show = false;
                this.onFilter();
            },
            onPage(event) {
                this.pagination = event;
                this.loadCollaborators();
            },
            onFilter() {
                if (Object.keys(this.filterModal.selectedFilters).length > 0) {
                    this.hasFilters = true;
                }
                this.pagination.filters = this.filters;
                this.loadCollaborators();
            },
            formatDepartments(departments) {
                let departmentsNames = departments.map((d) => {
                    return d.name;
                });
                return departmentsNames.toString();
            },
            filterInit() {
                this.pagination = {
                    page: 1,
                    rows: this.pageRows
                };
                this.filters = {
                    global: {
                        value: null,
                        matchMode: FilterMatchMode.CONTAINS
                    }
                };
            }
        }
    });
</script>

<template>
    <Card>
        <template #title>
            <span class="font-light text-700 text-xl">Colaboradores</span>
        </template>
        <template #content>
            <Toolbar class="h-6rem">
                <template v-slot:start>
                    <div class="lg:mt-0 sm:mt-2 flex flex-row" style="gap: 0.5rem">
                        <Button
                            icon="fas fa-plus"
                            class="p-button-rounded p-button-success p-button-outlined p-button-raised"
                            @click="showCollaboratorModal('create')"
                            :disabled="loading"
                            title="Adicionar novo colaborador" />
                        <Button
                            icon="fas fa-trash"
                            class="p-button-rounded p-button-danger p-button-outlined p-button-raised"
                            @click="collaboratorsRemove()"
                            :disabled="collaborators.selection.length == 0"
                            title="Excluir colaborador(es) selecionado(s)" />
                    </div>
                </template>
                <template v-slot:end>
                    <div class="lg:mt-0 sm:mt-2 flex flex-row" style="gap: 0.5rem">
                        <Button
                            icon="fas fa-redo-alt"
                            class="p-button-rounded p-button-info p-button-outlined p-button-raised"
                            @click="loadCollaborators()"
                            :disabled="loading"
                            title="Atualizar lista" />
                        <div class="relative">
                            <Button
                                icon="fas fa-filter"
                                class="p-button p-button-rounded p-button-info p-button-outlined p-button-raised"
                                @click="showFilterModal()"
                                :disabled="loading"
                                title="Escolher filtros" />
                            <Button
                                v-if="hasFilters"
                                icon="fas fa-broom"
                                class="p-button-rounded p-button-raised clear-filter"
                                title="Limpar filtros"
                                @click="clearFilters()"
                                :disabled="loading" />
                        </div>
                    </div>
                </template>
            </Toolbar>
        </template>
        <template #footer>
            <DataTable
                ref="dt"
                :value="collaborators.records"
                dataKey="ID"
                :paginator="true"
                :rows="10"
                v-model:filters="filters"
                :loading="loading"
                :totalRecords="collaborators.totalRecords"
                @page="onPage($event)"
                @filter="onFilter($event)"
                @sort="onSort($event)"
                lazy="true"
                v-model:selection="collaborators.selection"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rowsPerPageOptions="[10, 20, 50, 100, 300]"
                currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} entradas"
                responsiveLayout="scroll"
                class="p-datatable-sm">
                <template #header />
                <Column selectionMode="multiple" headerStyle="width:.2%; min-width:1rem;" />
                <Column field="ID" header="ID" :sortable="true" headerStyle="width:.30%; min-width:2rem;">
                    <template #body="slotProps">
                        <span class="p-column-title">ID</span>
                        <span>{{ slotProps.data?.ID }}</span>
                    </template>
                </Column>
                <Column field="employerRegistrationNumber" header="Matricula" :sortable="false" headerStyle="width:0.2%; min-width:2rem;">
                    <template #body="slotProps">
                        <span class="p-column-title">Matricula</span>
                        <span>{{ slotProps.data?.employerRegistrationNumber }}</span>
                    </template>
                </Column>
                <Column field="name" header="Nome" :sortable="false" headerStyle="width:5%; min-width:2rem;">
                    <template #body="slotProps">
                        <span class="p-column-title">Nome</span>
                        <span>{{ slotProps.data?.person?.name }}</span>
                    </template>
                </Column>
                <Column field="company" header="Empresa" :sortable="false" headerStyle="width:3%; min-width:2rem;">
                    <template #body="slotProps">
                        <span class="p-column-title">Empresa</span>
                        <span>{{ slotProps.data?.company?.name }}</span>
                    </template>
                </Column>
                <Column field="departments" header="Departamentos" :sortable="false" headerStyle="width:3%; min-width:2rem;">
                    <template #body="slotProps">
                        <span class="p-column-title">Departamentos</span>
                        <span>{{ formatDepartments(slotProps.data?.departments) }}</span>
                    </template>
                </Column>
                <Column field="status" header="Status" :sortable="false" headerStyle="width:3%; min-width:2rem;">
                    <template #body="slotProps">
                        <span class="p-column-title">Status</span>
                        <span>{{ collaboratorStatus(slotProps.data?.events) }}</span>
                    </template>
                </Column>
                <Column headerStyle="width: 2%; min-width:5rem;">
                    <template #body="slotProps">
                        <div class="flex flex-column">
                            <Button
                                icon="fas fa-pencil"
                                class="p-button-rounded p-button-sm p-button-warning p-button-outlined p-button-raised mr-2 action-button"
                                title="Editar pessoa"
                                @click="showCollaboratorModal('update', slotProps.data)" />
                        </div>
                    </template>
                </Column>
            </DataTable>
        </template>
    </Card>
    <Dialog
        v-model:visible="collaboratorModal.show"
        :header="!collaboratorModal.progress ? collaboratorModal.title : 'Aguarde...'"
        :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        :style="{ width: '30vw' }"
        :maximizable="false"
        :closable="!collaboratorModal.progress"
        @hide="collaboratorModalResetState()">
        <CollaboratorModal
            v-if="!collaboratorModal.progress"
            :collaborator="collaboratorModal.collaborator"
            :action="collaboratorModal.action"
            :save="collaboratorModal.save"
            @valid="valid($event)"
            @progress="collaboratorModal.progress = $event"
            @close="collaboratorModal.show = false" />
        <ProgressModal v-if="collaboratorModal.progress" />
        <template #footer>
            <div class="flex flex-row justify-content-end" v-if="!collaboratorModal.progress">
                <Button
                    label="Salvar"
                    iconPos="left"
                    icon="fas fa-save"
                    autofocus
                    :disabled="!collaboratorModal.valid"
                    @click="collaboratorModal.save = true"
                    class="w-10rem" />
                <Button
                    autofocus
                    label="Cancelar"
                    iconPos="left"
                    icon="pi pi-times"
                    class="p-button-outlined p-button-danger w-10rem"
                    @click="collaboratorModal.show = false" />
            </div>
        </template>
    </Dialog>
    <Dialog
        v-model:visible="filterModal.show"
        header="Filtro avançado"
        :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        :style="{ width: '30vw' }"
        :maximizable="false">
        <FilterModal @filterSelection="makeFilter($event)" :showFilters="filterModal.showFilters" :currentFilters="filterModal.selectedFilters" />
        <template #footer>
            <div class="flex flex-row justify-content-between">
                <Button label="Cancelar" iconPos="right" icon="pi pi-times" autofocus @click="closeFilterModal()" class="w-10rem" />
                <Button label="Pesquisar" iconPos="right" icon="pi pi-search" autofocus @click="filterSearch()" class="w-10rem" />
            </div>
        </template>
    </Dialog>
</template>
