/**
 * A simple EventBus implementation for custom event handling.
 */
const EventBus = {
    /**
     * Registers an event listener for a specific event.
     *
     * @param {string} event - The name of the event.
     * @param {Function} callback - The function to call when the event is triggered.
     */
    on(event, callback) {
        document.addEventListener(event, (e) => callback(e.detail));
    },

    /**
     * Dispatches a custom event with optional data.
     *
     * @param {string} event - The name of the event.
     * @param {*} data - The data to pass along with the event.
     */
    dispatch(event, data) {
        document.dispatchEvent(new CustomEvent(event, { detail: data }));
    },

    /**
     * Removes an event listener for a specific event.
     *
     * @param {string} event - The name of the event.
     * @param {Function} callback - The function that was used to listen for the event.
     */
    remove(event, callback) {
        document.removeEventListener(event, callback);
    },
};

export default EventBus;
