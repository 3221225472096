<script>
    import { defineComponent } from '@vue/runtime-core';
    import DocumentRouter from '@/api/routes/Document';
    import { FilterMatchMode } from 'primevue/api';
    import PDFViewer from '../../Utils/PDFViewer.vue';
    import FilterModal from '../../Utils/Filter.vue';
    import EditDocumentModal from './EditDocumentModal.vue';
    import ProgressModal from '../../Utils/Progress.vue';
    import moment from 'moment';
    export default defineComponent({
        components: {
            PDFViewer,
            FilterModal,
            EditDocumentModal,
            ProgressModal
        },
        emits: ['progress'],
        data() {
            return {
                dataViewEmpty: '',
                loading: false,
                pagination: {},
                pageRows: 10,
                hasFilters: false,
                filters: {
                    name: {
                        value: '',
                        matchMode: 'contains'
                    }
                },
                fileExport: {
                    progress: false
                },
                editDocumentModal: {
                    show: false,
                    document: null,
                    finish: 0
                },
                filterModal: {
                    show: false,
                    showFilters: ['people', 'departments', 'envelopeEventsTypes', 'envelopeTypes', 'companies', 'createdAt', 'general'],
                    selectedFilters: {}
                },
                documents: {
                    layout: 'list',
                    records: [],
                    totalRecords: null,
                    selectedDocuments: []
                },
                documentModal: {
                    show: false,
                    password: null,
                    rotate: 1,
                    zoom: 500,
                    base64: null,
                    ID: null,
                    content: null
                },
                sort: {
                    sortKey: null,
                    sortOrder: null,
                    sortField: null,
                    sortOptions: [{ label: '', value: '' }]
                },
                DocumentApi: null
            };
        },
        created() {
            this.DocumentApi = new DocumentRouter();
            this.FilterInit();
        },
        async mounted() {
            this.pageRows = this.$refs.dt.rows;
            this.LoadDocuments();
        },
        methods: {
            LoadDocuments() {
                this.dataViewEmpty = 'Carregando lista de documentos...';
                this.documents.records = 0;
                this.documents.totalRecords = 0;
                this.loading = true;
                setTimeout(() => {
                    this.DocumentApi.Search(JSON.stringify(this.pagination))
                        .then((resp) => {
                            this.documents.records = resp.documents;
                            this.documents.totalRecords = resp.totalRecords;
                        })
                        .finally(() => {
                            if (this.documents.totalRecords == 0) {
                                this.dataViewEmpty = 'Nenhum documento foi encontrado';
                            }
                            this.loading = false;
                        });
                }, Math.random() * 1000 + 250);
            },
            ConfirmExport() {
                this.$confirm.require({
                    header: 'Remover proteção',
                    message: 'Deseja remover a senha dos documentos protegidos?',
                    acceptClass: 'p-button-danger',
                    accept: async () => {
                        await this.Export(true);
                    },
                    reject: async () => {
                        await this.Export(false);
                    }
                });
            },
            async Export(removeProtection) {
                this.fileExport.progress = true;
                await this.DocumentApi.Export(JSON.stringify(this.pagination), removeProtection).then((resp) => {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Sucesso',
                        detail: 'Compressão realizada com sucesso, efetuando download...',
                        life: 3000
                    });
                    this.fileExport.progress = false;

                    this.DocumentApi.Download(resp.fileHash);
                });
            },
            GetDocumentFormat(slot) {
                let fileExt = slot.content.split('.').pop();
                return fileExt;
            },
            FormatDate(date) {
                return moment(date).format('DD/MM/YYYY');
            },
            GetPeople(slot) {
                let pnames = slot?.people.map((p) => {
                    return p.name;
                });
                const names = pnames[0].split(' ');
                if (names.length > 3) {
                    let abbreviatedName = '';

                    if (names.length <= 2) {
                        return name;
                    }

                    abbreviatedName += names[0] + ' ';

                    for (let i = 1; i < names.length - 1; i++) {
                        abbreviatedName += names[i][0].toUpperCase() + '.' + ' ';
                    }

                    abbreviatedName += names[names.length - 1];

                    return abbreviatedName;
                }
                return pnames.join(',');
            },
            async ViewDocument(slot) {
                this.documentModal.ID = slot.ID;
                if (slot.protected) {
                    this.documentModal.password = slot.password;
                }
                await this.GetBase64(slot.content);
                this.documentModal.show = true;
            },
            async GetBase64(fileContent) {
                let payload = { file: fileContent };
                await this.DocumentApi.View('base64', payload).then((resp) => (this.documentModal.base64 = resp?.base64File));
            },
            DocumentLocker(slot) {
                let iClass,
                    iTitle = null,
                    iStyle = null;

                switch (slot.protected) {
                    case true:
                        iClass = 'fas fa-lock fa-xl text-green-600';
                        iTitle = 'Documento protegido por senha';
                        break;
                    case false:
                        iClass = 'fas fa-lock-open fa-xl';
                        iStyle = 'color: var(--red-600);';
                        iTitle = 'Documento desprotegido';
                        break;
                }
                return `<i title="${iTitle}" class="${iClass}" style="${iStyle}" />`;
            },
            ResetDocumentModalState() {
                this.documentModal = {
                    show: false,
                    password: null,
                    rotate: 1,
                    zoom: 500,
                    base64: null,
                    content: null
                };
            },
            SetZoom(type) {
                let minZoom = 100;
                let maxZoom = 1200;
                if (type == 'IN' && this.documentModal.zoom != maxZoom) {
                    return (this.documentModal.zoom += 100);
                } else if (type == 'OUT' && this.documentModal.zoom != minZoom) {
                    return (this.documentModal.zoom -= 100);
                }
            },
            EditDocument(document) {
                this.editDocumentModal.document = { ...document };
                this.editDocumentModal.show = true;
            },
            DeleteDocuments() {
                this.$confirm.require({
                    header: 'Remover documentos',
                    message: 'Tem certeza que deseja remover os documentos selecionados?',
                    acceptClass: 'p-button-danger',
                    accept: () => {
                        this.DocumentApi.Remove(this.documents.selectedDocuments)
                            .then(() => {
                                this.$toast.add({
                                    severity: 'success',
                                    summary: 'Sucesso',
                                    detail: 'Sua solicitação foi processada com sucesso',
                                    life: 3000
                                });
                            })
                            .finally(this.LoadDocuments());
                    }
                });
            },
            OnSortChange(event) {
                const value = event.value.value;
                this.pagination.sortField = `eet.${value}`;
                this.OnFilter();
            },
            OnPage(event) {
                this.pagination = event;
                this.OnFilter();
            },
            OnFilter() {
                if (Object.keys(this.filterModal.selectedFilters).length > 0) {
                    this.hasFilters = true;
                }
                this.pagination.filters = this.filters;
                this.LoadDocuments();
            },
            async OnDownload(ID) {
                try {
                    await this.DocumentApi.DocumentDownload(ID);
                } catch (err) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro',
                        detail: 'Ocorreu um erro ao tentar baixar o documento, consulte o console para mais detalhes.',
                        life: 3000
                    });
                    console.error(err);
                }
            },
            CloseFilterModal() {
                this.filterModal.show = false;
            },
            ShowFilterModal() {
                this.filterModal.show = true;
            },
            MakeFilter(filter) {
                this.filterModal.selectedFilters = Object.assign({}, this.filterModal.selectedFilters, filter);
                this.filters.global = Object.assign({}, this.filters.global, filter);
            },
            OnEditDocumentModalFinished(event) {
                if (event) {
                    this.editDocumentModal.show = false;
                    this.LoadDocuments();
                }
            },
            ClearFilters() {
                this.filterModal.selectedFilters = [];
                this.hasFilters = false;
                this.FilterInit();
                this.OnFilter();
            },
            FilterSearch() {
                this.filterModal.show = false;
                this.OnFilter();
            },
            FilterInit() {
                this.pagination = {
                    page: 1,
                    rows: this.pageRows
                };
                this.filters = {
                    global: {
                        value: null,
                        matchMode: FilterMatchMode.CONTAINS
                    }
                };
            }
        }
    });
</script>
<template>
    <Card>
        <template #title>
            <span class="font-light text-700 text-xl">Gerenciador de documentos</span>
        </template>
        <template #content>
            <DataView
                ref="dt"
                dataKey="ID"
                lazy="true"
                v-model:filters="filters"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} entradas"
                responsiveLayout="scroll"
                :rowsPerPageOptions="[10, 20, 50, 100, 300]"
                :value="documents.records"
                :layout="documents.layout"
                :paginator="true"
                :rows="10"
                :loading="loading"
                :totalRecords="documents.totalRecords"
                :sortOrder="sort.sortOrder"
                :sortField="sort.sortField"
                @page="OnPage($event)"
                @filter="OnFilter($event)"
                @sort="onSort($event)">
                <template #header>
                    <div>
                        <Toolbar>
                            <template v-slot:start>
                                <div class="flex flex-row flex-wrap" style="gap: 0.5rem">
                                    <Button
                                        icon="fas fa-trash"
                                        class="p-button-rounded p-button-danger p-button-outlined p-button-raised"
                                        @click="DeleteDocuments()"
                                        :disabled="loading || documents.selectedDocuments.length == 0"
                                        title="Remover documentos" />
                                    <Button
                                        icon="fas fa-redo-alt"
                                        class="p-button-rounded p-button-info p-button-outlined p-button-raised"
                                        @click="LoadDocuments()"
                                        :disabled="loading"
                                        title="Atualizar lista" />
                                    <div class="relative">
                                        <Button
                                            icon="fas fa-filter"
                                            class="p-button p-button-rounded p-button-info p-button-outlined p-button-raised"
                                            @click="ShowFilterModal()"
                                            :disabled="loading"
                                            title="Escolher filtros" />
                                        <Button
                                            v-if="hasFilters"
                                            icon="fas fa-broom"
                                            class="p-button-rounded p-button-raised clear-filter"
                                            title="Limpar filtros"
                                            @click="ClearFilters()"
                                            :disabled="loading" />
                                    </div>
                                    <Button
                                        icon="fas fa-file-export"
                                        class="p-button-rounded p-button-info p-button-outlined p-button-raised"
                                        @click="ConfirmExport()"
                                        :disabled="loading"
                                        title="Exportar documentos" />
                                </div>
                            </template>
                            <template #end>
                                <div class="col-12" style="text-align: right">
                                    <DataViewLayoutOptions v-model="documents.layout" />
                                </div>
                            </template>
                        </Toolbar>
                    </div>
                </template>

                <template #list="slotProps">
                    <div class="col-12">
                        <div class="item-list-item">
                            <Checkbox class="mr-3" :value="slotProps.data.ID" v-model="documents.selectedDocuments" />
                            <div class="relative">
                                <span class="badge-notification-list">
                                    <div v-html="DocumentLocker(slotProps.data)" />
                                </span>
                                <img
                                    :src="'images/envelopes/' + GetDocumentFormat(slotProps.data) + '.png'"
                                    onerror="javascript:this.src='images/envelopes/doc.png'"
                                    alt="document"
                                    style="width: 64px; height: 64px" />
                            </div>
                            <div class="item-list-detail">
                                <div class="item-name">
                                    {{ GetPeople(slotProps.data) }}
                                </div>
                                <div class="item-description">
                                    Criado em:
                                    {{ FormatDate(slotProps.data?.createdAt) }}
                                </div>
                                <div class="flex flex-row" style="gap: 0.5rem" v-if="slotProps.data?.referenceDate?.length > 0">
                                    <div title="Data de referência do documento">
                                        <i class="fas fa-calendar mr-1" />
                                        <span class="item-category">{{ slotProps.data?.referenceDate }}</span>
                                    </div>
                                    <div title="Documento raiz" v-if="slotProps.data.anchor">
                                        <i class="fas fa-anchor text-900" />
                                    </div>
                                </div>
                            </div>
                            <div class="item-list-action justify-content-between">
                                <div class="flex flex-column w-full">
                                    <Button class="p-button-sm p-button-info p-button-outlined p-button-raised" icon="fas fa-eye" label="Visualizar" @click="ViewDocument(slotProps.data)" />
                                    <Button
                                        class="p-button-sm p-button-warning p-button-outlined p-button-raised"
                                        icon="fas fa-pencil"
                                        label="Editar"
                                        @click="EditDocument(slotProps.data)"
                                        :disabled="slotProps.data.anchor" />
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template #grid="slotProps">
                    <div class="col-12 md:col-4">
                        <div class="item-grid-item card">
                            <div class="item-grid-item-top">
                                <Checkbox :value="slotProps.data.ID" v-model="documents.selectedDocuments" />
                                <div class="flex flex-row mb-1" style="gap: 0.2rem" v-if="slotProps.data?.referenceDate?.length > 0">
                                    <div style="margin-top: 0.9px">
                                        <i class="fas fa-calendar" />
                                    </div>
                                    <span class="item-category">{{ slotProps.data?.referenceDate }}</span>
                                </div>
                            </div>
                            <div class="item-grid-item-content">
                                <img
                                    :src="'images/envelopes/' + GetDocumentFormat(slotProps.data) + '.png'"
                                    :alt="slotProps.data?.name"
                                    onerror="javascript:this.src='images/envelopes/doc.png'"
                                    style="width: 64px; height: 64px" />
                                <div class="item-name text-xl">
                                    {{ GetPeople(slotProps.data) }}
                                </div>
                                <div class="item-description">
                                    Criado em:
                                    {{ FormatDate(slotProps.data?.createdAt) }}
                                </div>
                            </div>
                            <div class="flex item-grid-item-bottom justify-content-between">
                                <div class="flex" style="gap: 0.5rem">
                                    <Button class="p-button-sm p-button-info p-button-outlined p-button-raised" icon="fas fa-eye" @click="ViewDocument(slotProps.data)" />
                                    <Button
                                        class="p-button-sm p-button-warning p-button-outlined p-button-raised"
                                        icon="fas fa-pencil"
                                        @click="EditDocument(slotProps.data)"
                                        :disabled="slotProps.data.anchor" />
                                </div>
                                <div class="flex flex-row justify-content-around" style="gap: 0.4rem">
                                    <div v-html="DocumentLocker(slotProps.data)" />
                                    <div title="Documento raiz" v-if="slotProps.data.anchor">
                                        <i class="fas fa-anchor fa-xl text-900" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template #empty>
                    <div class="empty-dataview" style="gap: 0.7rem">
                        <div v-if="loading">
                            <i class="pi pi-spin fas fa-spinner fa-xl" />
                        </div>
                        <span class="text-900 font-medim text-lg">
                            {{ dataViewEmpty }}
                        </span>
                    </div>
                </template>
            </DataView>
        </template>
    </Card>
    <Dialog v-model:visible="editDocumentModal.show" header="Editar documento" :modal="true" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '30vw' }" :maximizable="true">
        <EditDocumentModal :document="editDocumentModal.document" :finish="editDocumentModal.finish" @finished="OnEditDocumentModalFinished" />

        <template #footer>
            <div class="flex flex-row justify-content-between">
                <Button label="Cancelar" iconPos="right" icon="pi pi-times" autofocus @click="editDocumentModal.show = false" class="w-12rem" />
                <Button label="Finalizar" iconPos="right" icon="pi pi-check" autofocus @click="editDocumentModal.finish++" class="w-12rem" />
            </div>
        </template>
    </Dialog>
    <Dialog v-model:visible="documentModal.show" header="Visualizar documento" :modal="true" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :maximizable="true" @hide="ResetDocumentModalState()">
        <PDFViewer :pdf="documentModal.base64" :password="documentModal.password" :zoom="documentModal.zoom" :rotate="documentModal.rotate" />

        <template #footer>
            <div class="flex mt-4 justify-content-between w-full">
                <div class="flex">
                    <Button icon="fa-solid fa-magnifying-glass-plus ml-2 mr-2" @click="SetZoom('IN')" title="Aumentar o zoom" autofocus />
                    <Button icon="fa-solid fa-magnifying-glass-minus ml-2 mr-2" @click="SetZoom('OUT')" title="Reduzir o zoom" autofocus />
                    <Button icon="fa-solid fa-rotate-right ml-2 mr-2" @click="documentModal.rotate++" title="Girar 90º" autofocus />
                    <Button icon="fa-solid fa-download ml-2 mr-2" title="Baixar documento" @click="OnDownload(documentModal.ID)" autofocus />
                </div>
                <div class="flex flex-row sm:flex flex-wrap justify-content-end" style="gap: 0.5rem">
                    <Button autofocus label="Fechar" icon="fa-solid fa-times" class="p-button-info w-8rem" @click="this.documentModal.show = false" />
                </div>
            </div>
        </template>
    </Dialog>
    <Dialog v-model:visible="filterModal.show" header="Filtro avançado" :modal="true" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '30vw' }" :maximizable="false">
        <FilterModal @filterSelection="MakeFilter($event)" :showFilters="filterModal.showFilters" :currentFilters="filterModal.selectedFilters" />
        <template #footer>
            <div class="flex flex-row justify-content-between">
                <Button label="Cancelar" iconPos="right" icon="pi pi-times" autofocus @click="CloseFilterModal()" class="w-12rem" />
                <Button label="Pesquisar" iconPos="right" icon="pi pi-search" autofocus @click="FilterSearch()" class="w-12rem" />
            </div>
        </template>
    </Dialog>
    <Dialog
        v-model:visible="fileExport.progress"
        :header="'Comprimindo arquivos...'"
        :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        :style="{ width: '30vw' }"
        :maximizable="false"
        :closable="fileExport.progress">
        <ProgressModal />
    </Dialog>
</template>

<style lang="scss" scoped>
    .card {
        padding: 2rem;
        border-radius: 4px;
        margin-bottom: 2rem;
    }
    .p-dropdown {
        width: 14rem;
        font-weight: normal;
    }

    .item-name {
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: 2px;
    }

    .item-description {
        margin: 0 0 1rem 0;
    }

    .item-category-icon {
        vertical-align: middle;
        margin-right: 0.5rem;
    }

    .item-category {
        font-weight: 600;
        vertical-align: middle;
    }

    ::v-deep(.item-list-item) {
        display: flex;
        align-items: center;
        padding: 1rem;
        padding-left: 1%;
        width: 100%;

        img {
            width: 50px;
            margin-right: 1rem;
        }

        .item-list-detail {
            flex: 1 1 0;
        }

        .p-rating {
            margin: 0 0 0.5rem 0;
        }

        .item-list-action {
            display: flex;
            flex-direction: column;
        }

        .p-button {
            margin-bottom: 0.5rem;
        }
    }

    ::v-deep(.item-grid-item) {
        margin: 0.5rem;
        border: 1px solid var(--surface-border);

        .item-grid-item-top,
        .item-grid-item-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        img {
            margin: 2rem 0;
        }

        .item-grid-item-content {
            text-align: center;
        }
    }

    @media screen and (max-width: 576px) {
        .item-list-item {
            flex-direction: column;
            align-items: center;

            img {
                margin: 2rem 0;
            }

            .item-list-detail {
                text-align: center;
            }

            .item-list-action {
                display: flex;
                flex-direction: column;
            }

            .item-list-action {
                margin-top: 2rem;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
            }
        }
    }

    .badge-notification-list {
        left: 0px !important;
        bottom: 50px !important;
        position: absolute !important;
        color: white;
    }
</style>
