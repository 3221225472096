import { createRouter, createWebHashHistory } from 'vue-router';
import { defineAsyncComponent } from 'vue';
import Guard from '@/services/Guard';
import LoadApplicationData from '@/services/ApplicationData';
import Token from '@/services/Token';

import App from '@/App.vue';
import Dashboard from '@/components/Dashboard.vue';
import Login from '@/components/Auth/Login.vue';
import ForgotPassword from '@/components/Auth/ForgotPassword.vue';
import ChangePassword from '@/components/Auth/ChangePassword.vue';
import NotFound from '@/components/Pages/NotFound.vue';
import AccessDenied from '@/components/Pages/Access.vue';
import Error from '@/components/Pages/Error.vue';
import Loading from '@/components/Pages/Loading.vue';
import Maintenance from '@/components/Pages/Maintenance.vue';
import QuickValidation from '@/components/Pages/QuickValidation.vue';

import DocumentRouters from '@/components/Documents/document.router';
import EnvelopeRouters from '@/components/Envelopes/envelope.router';
import AdminRouters from '@/components/Admin/admin.router';
import EmployeeRouters from '@/components/Employee/employee.router';
import AssessmentRouters from '@/components/Assessments/assessments.router';

// Helper to define async components
const asyncComponent = (loader) =>
    defineAsyncComponent({
        loader,
        delay: 5000,
        errorComponent: Error,
        loadingComponent: Loading,
        timeout: 5000
    });

// Reusable middleware checker
async function CheckAccessMiddleware({ to, next, ignore }) {
    const routerAccess = await CheckRouterPermission(to.path);
    const alertPages = ['/pages/loading', '/pages/error', '/pages/access'];
    const publicPages = ['/auth/login', '/auth/password/forgot', '/change_password'];

    const shouldRedirect =
        !alertPages.includes(to.path) && !publicPages.includes(to.path) && !routerAccess.hasPermission && !ignore.includes(to.path);

    shouldRedirect ? next('/pages/access') : next();
}

// Permission checker based on user menus
async function CheckRouterPermission(router) {
    try {
        const data = await LoadApplicationData();
        const items = data.userInfo.menus.flatMap((menu) => [...menu.items, ...menu.items.flatMap((subItem) => subItem.items || [])]);

        const hasPermission = items.some((item) => item?.action === router || item?.to === router);
        const allowedMenus = items.map((item) => item?.action || item?.to);

        return { hasPermission, allowedMenus };
    } catch {
        location.reload();
    }
}

// Route definitions
const routes = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '',
            component: App,
            children: [
                { path: '', name: 'dashboard', component: Dashboard, meta: { middleware: CheckAccessMiddleware } },
                {
                    path: 'documents',
                    name: 'documents',
                    component: asyncComponent(() => import('@/components/Documents/Index')),
                    children: DocumentRouters,
                    meta: { middleware: CheckAccessMiddleware, ignore: ['/documents/upload/content', '/documents/upload/recipients'] }
                },
                {
                    path: 'employee',
                    name: 'employee',
                    component: asyncComponent(() => import('@/components/Employee/Index')),
                    children: EmployeeRouters,
                    meta: { middleware: CheckAccessMiddleware }
                },
                {
                    path: 'envelopes',
                    name: 'envelopes',
                    component: asyncComponent(() => import('@/components/Envelopes/Index')),
                    children: EnvelopeRouters,
                    meta: { middleware: CheckAccessMiddleware }
                },
                {
                    path: 'admin',
                    name: 'administration',
                    component: asyncComponent(() => import('@/components/Admin/Index')),
                    children: AdminRouters,
                    meta: { middleware: CheckAccessMiddleware }
                },
                {
                    path: 'assessments',
                    name: 'assessments',
                    component: asyncComponent(() => import('@/components/Assessments/Index')),
                    children: AssessmentRouters,
                    meta: { middleware: CheckAccessMiddleware }
                }
            ]
        },
        { path: '/:pathMatch(.*)*', name: 'notfound', component: NotFound },
        { path: '/pages/access', name: 'accessDenied', component: AccessDenied },
        { path: '/pages/error', name: 'error', component: Error },
        { path: '/pages/loading', name: 'loading', component: Loading },
        { path: '/pages/maintenance', name: 'maintenance', component: Maintenance },
        { path: '/quick/pending-envelopes', name: 'quick-validation', component: QuickValidation },
        { path: '/auth/login', name: 'login', component: Login },
        { path: '/auth/password/forgot', name: 'forgot', component: ForgotPassword },
        { path: '/change_password', name: 'change_password', component: ChangePassword }
    ]
});

// Route guard
routes.beforeEach((to, from, next) => {
    const publicPages = ['/auth/login', '/auth/password/forgot', '/change_password'];
    const token = Token.getAuthorizationToken();
    const authRequired = !publicPages.includes(to.path);

    if (token) {
        const middleware = to.meta.middleware || [];
        const ignorePaths = to.meta.ignore || [];

        if (to.fullPath !== '/') {
            try {
                const context = { to, from, next, ignore: ignorePaths };
                return Array.isArray(middleware) ? middleware(context) : middleware(context);
            } catch {
                next();
            }
        }
        return next();
    }

    authRequired && !Guard(token) ? next(publicPages[0]) : next();
});

export default routes;
