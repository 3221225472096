<script>
import { FilterMatchMode } from 'primevue/api';
import { defineComponent } from 'vue';
import FilterModal from '../../Utils/Filter.vue';
import ProgressModal from '../../Utils/Progress.vue';
import CompanyModal from './CompanyModal.vue';
import CompanyRouter from '@/api/routes/Company';
export default defineComponent({
    components: {
        FilterModal,
        ProgressModal,
        CompanyModal
    },
    data() {
        return {
            loading: false,
            pagination: {},
            pageRows: 10,
            filterModal: {
                show: false,
                showFilters: ['people', 'general'],
                selectedFilters: {}
            },
            companies: {
                records: [],
                totalRecords: 0,
                selection: []
            },
            hasFilters: false,
            companyModal: {
                show: false,
                save: false,
                valid: false,
                progress: false,
                action: 'create',
                title: null,
                company: {}
            },
            filters: {
                name: {
                    value: '',
                    matchMode: 'contains'
                }
            },
            CompanyApi: null
        };
    },
    created() {
        this.CompanyApi = new CompanyRouter();
        this.FilterInit();
    },
    mounted() {
        this.pageRows = this.$refs.dt.rows;
        this.LoadCompanies();
    },
    methods: {
        ShowCompanyModal(action, slot) {
            this.companyModal.action = action;
            this.companyModal.company = action == 'update' ? { ...slot } : null;
            this.companyModal.title = `${action == 'create' ? 'Cadastrar' : 'Atualizar'} empresa`;
            this.companyModal.show = true;
        },
        Valid(event) {
            event ? (this.companyModal.valid = true) : (this.companyModal.valid = false);
        },
        CompanyModalResetState() {
            this.companyModal.show = false;
            this.companyModal.valid = false;
            this.companyModal.save = false;
            this.companyModal.company = {};
            this.LoadCompanies();
        },
        CompaniesRemove() {
            this.$confirm.require({
                header: 'Excluir',
                message: 'Ao realizar a exclusão não será possível desfazer a operação, deseja continuar?',
                icon: 'far fa-question-circle',
                acceptLabel: 'Sim',
                rejectLabel: 'Não',
                acceptClass: 'p-button-danger',
                accept: () => {
                    this.CompanyApi.Remove(
                        this.companies.selection.map((c) => {
                            return c.ID;
                        })
                    )
                        .then(() => {
                            this.$toast.add({
                                severity: 'success',
                                summary: 'Sucesso',
                                detail: 'Operação realizada com sucesso',
                                life: 3000
                            });
                        })
                        .finally(this.LoadCompanies());
                }
            });
        },
        LoadCompanies() {
            this.loading = true;
            setTimeout(() => {
                this.CompanyApi.Search(JSON.stringify(this.pagination))
                    .then((resp) => {
                        this.companies.records = resp.companies;
                        this.companies.totalRecords = resp.totalRecords;
                    })
                    .finally((this.loading = false));
            }, Math.random() * 1000 + 250);
        },
        CloseFilterModal() {
            this.filterModal.show = false;
        },
        ShowFilterModal() {
            this.filterModal.show = true;
        },
        MakeFilter(filter) {
            this.filterModal.selectedFilters = Object.assign({}, this.filterModal.selectedFilters, filter);
            this.filters.global = Object.assign({}, this.filters.global, filter);
        },
        ClearFilters() {
            this.filterModal.selectedFilters = [];
            this.FilterInit();
            this.hasFilters = false;
            this.OnFilter();
        },
        FilterSearch() {
            this.filterModal.show = false;
            this.OnFilter();
        },
        OnPage(event) {
            this.pagination = event;
            this.LoadCompanies();
        },
        OnFilter() {
            if (Object.keys(this.filterModal.selectedFilters).length > 0) {
                this.hasFilters = true;
            }
            this.pagination.filters = this.filters;
            this.LoadCompanies();
        },
        FilterInit() {
            this.pagination = {
                page: 1,
                rows: this.pageRows
            };
            this.filters = {
                global: {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS
                }
            };
        }
    }
});
</script>

<template>
    <Card>
        <template #title>
            <span class="font-light text-700 text-xl">Companhias</span>
        </template>
        <template #content>
            <Toolbar class="h-6rem">
                <template v-slot:start>
                    <div class="lg:mt-0 sm:mt-2 flex flex-row" style="gap: 0.5rem">
                        <Button icon="fas fa-plus" class="p-button-rounded p-button-success p-button-outlined p-button-raised" @click="ShowCompanyModal('create')" :disabled="loading" title="Adicionar nova empresa" />
                        <Button icon="fas fa-trash" class="p-button-rounded p-button-danger p-button-outlined p-button-raised" @click="CompaniesRemove()" :disabled="companies.selection.length == 0" title="Excluir empresa(s) selecionada(s)" />
                    </div>
                </template>
                <template v-slot:end>
                    <div class="lg:mt-0 sm:mt-2 flex flex-row" style="gap: 0.5rem">
                        <Button icon="fas fa-redo-alt" class="p-button-rounded p-button-info p-button-outlined p-button-raised" @click="LoadCompanies()" :disabled="loading" title="Atualizar lista" />
                        <div class="relative">
                            <Button icon="fas fa-filter" class="p-button p-button-rounded p-button-info p-button-outlined p-button-raised" @click="ShowFilterModal()" :disabled="loading" title="Escolher filtros" />
                            <Button v-if="hasFilters" icon="fas fa-broom" class="p-button-rounded p-button-raised clear-filter" title="Limpar filtros" @click="ClearFilters()" :disabled="loading" />
                        </div>
                    </div>
                </template>
            </Toolbar>
        </template>
        <template #footer>
            <DataTable
                ref="dt"
                :value="companies.records"
                dataKey="ID"
                :paginator="true"
                :rows="10"
                v-model:filters="filters"
                :loading="loading"
                :totalRecords="companies.totalRecords"
                @page="OnPage($event)"
                @filter="OnFilter($event)"
                @sort="onSort($event)"
                lazy="true"
                v-model:selection="companies.selection"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rowsPerPageOptions="[10, 20, 50, 100, 300]"
                currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} entradas"
                responsiveLayout="scroll"
                class="p-datatable-sm"
            >
                <template #header />
                <Column selectionMode="multiple" headerStyle="width:.2%; min-width:1rem;" />
                <Column field="ID" header="ID" :sortable="false" headerStyle="width:.30%; min-width:2rem;">
                    <template #body="slotProps">
                        <span class="p-column-title">ID</span>
                        <span>{{ slotProps.data?.ID }}</span>
                    </template>
                </Column>
                <Column field="name" header="Nome" :sortable="false" headerStyle="width:5%; min-width:2rem;">
                    <template #body="slotProps">
                        <span class="p-column-title">Nome</span>
                        <span>{{ slotProps.data?.name }}</span>
                    </template>
                </Column>
                <Column field="txID" header="CNPJ" :sortable="false" headerStyle="width:3%; min-width:2rem;">
                    <template #body="slotProps">
                        <span class="p-column-title">CNPJ</span>
                        <span>{{ slotProps.data?.txID }}</span>
                    </template>
                </Column>
                <Column field="ceo" header="Diretor" :sortable="false" headerStyle="width:3%; min-width:2rem;">
                    <template #body="slotProps">
                        <span class="p-column-title">Diretor</span>
                        <span>{{ slotProps.data?.person?.name }}</span>
                    </template>
                </Column>
                <Column headerStyle="width: 2%; min-width:5rem;">
                    <template #body="slotProps">
                        <div class="flex flex-column">
                            <Button icon="fas fa-pencil" class="p-button-rounded p-button-sm p-button-warning p-button-outlined p-button-raised mr-2 action-button" title="Editar pessoa" @click="ShowCompanyModal('update', slotProps.data)" />
                        </div>
                    </template>
                </Column>
            </DataTable>
        </template>
    </Card>
    <Dialog v-model:visible="companyModal.show" :header="!companyModal.progress ? companyModal.title : 'Aguarde...'" :modal="true" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '30vw' }" :maximizable="false" :closable="!companyModal.progress" @hide="CompanyModalResetState()">
        <CompanyModal v-if="!companyModal.progress" :company="companyModal.company" :action="companyModal.action" :save="companyModal.save" @valid="Valid($event)" @progress="companyModal.progress = $event" @close="companyModal.show = false" />
        <ProgressModal v-if="companyModal.progress" />
        <template #footer>
            <div class="flex flex-row justify-content-between" v-if="!companyModal.progress">
                <Button label="Cancelar" iconPos="right" icon="pi pi-times" autofocus @click="companyModal.show = false" class="w-12rem" />
                <Button label="Salvar" iconPos="right" icon="pi pi-check" autofocus :disabled="!companyModal.valid" @click="companyModal.save = true" class="w-12rem" />
            </div>
        </template>
    </Dialog>
    <Dialog v-model:visible="filterModal.show" header="Filtro avançado" :modal="true" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '30vw' }" :maximizable="false">
        <FilterModal @filterSelection="MakeFilter($event)" :showFilters="filterModal.showFilters" :currentFilters="filterModal.selectedFilters" />
        <template #footer>
            <div class="flex flex-row justify-content-between">
                <Button label="Cancelar" iconPos="right" icon="pi pi-times" autofocus @click="CloseFilterModal()" class="w-12rem" />
                <Button label="Pesquisar" iconPos="right" icon="pi pi-search" autofocus @click="FilterSearch()" class="w-12rem" />
            </div>
        </template>
    </Dialog>
</template>
