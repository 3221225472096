import AxiosInstance from '@/services/AxiosInstance';

const roleEndpoint = '/roles';

/**
 * Class to interact with the Role API
 */
export default class RoleRouter {
    /**
     * Searches for roles based on the provided filter parameters.
     *
     * @async
     * @param {Object} filterParams - The search criteria for roles.
     * @returns {Promise<Object>} - A promise that resolves to the search results.
     * @throws {Error} - Throws an error if the search fails.
     */
    async Search(filterParams) {
        try {
            const response = await AxiosInstance.get(`${roleEndpoint}?search=${filterParams}`);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to search roles: ${error.message}`);
        }
    }

    /**
     * Retrieves all roles.
     *
     * @async
     * @returns {Promise<Object>} - A promise that resolves to the list of all roles.
     * @throws {Error} - Throws an error if retrieval fails.
     */
    async FindAll() {
        try {
            const response = await AxiosInstance.get(roleEndpoint);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to retrieve roles: ${error.message}`);
        }
    }

    /**
     * Creates a new role with the provided data.
     *
     * @async
     * @param {Object} payload - The data for the new role.
     * @returns {Promise<Object>} - A promise that resolves to the created role data.
     * @throws {Error} - Throws an error if creation fails.
     */
    async Create(payload) {
        try {
            const response = await AxiosInstance.post(roleEndpoint, payload);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to create role: ${error.message}`);
        }
    }

    /**
     * Updates an existing role with the provided data.
     *
     * @async
     * @param {Object} payload - The updated data for the role.
     * @param {number} id - The unique identifier of the role.
     * @returns {Promise<Object>} - A promise that resolves to the updated role data.
     * @throws {Error} - Throws an error if the update fails.
     */
    async Update(payload, id) {
        try {
            const response = await AxiosInstance.put(`${roleEndpoint}/${id}`, payload);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to update role: ${error.message}`);
        }
    }

    /**
     * Removes one or more roles based on the provided data.
     *
     * @async
     * @param {Object} payload - The data identifying the roles to remove.
     * @returns {Promise<Object>} - A promise that resolves when the removal is complete.
     * @throws {Error} - Throws an error if removal fails.
     */
    async Remove(payload) {
        try {
            const response = await AxiosInstance.delete(roleEndpoint, { data: payload });
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to remove role(s): ${error.message}`);
        }
    }
}
