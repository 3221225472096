<script>
    import { defineComponent } from 'vue';
    import CollaboratorRouter from '@/api/routes/Collaborator';
    import DepartmentRouter from '@/api/routes/Department';
    import InputTextOptions from '../../Utils/InputOptions.vue';

    export default defineComponent({
        components: { InputTextOptions },
        props: ['action', 'department', 'save'],
        emits: ['valid', 'close', 'progress', 'disablePersisteButton'],
        data() {
            return {
                collaborators: {
                    records: [],
                    selectedCollaborator: {
                        ID: null,
                        name: ''
                    },
                    loading: false
                },
                departmentForm: {},
                validManager: false,
                showSetManagerModal: false,
                collaboratorApi: new CollaboratorRouter(),
                departmentApi: new DepartmentRouter()
            };
        },
        mounted() {
            this.initializeForm();
        },
        methods: {
            initializeForm() {
                if (this.action === 'update' && this.department) {
                    this.departmentForm = { ...this.department };
                    this.collaborators.selectedCollaborator = {
                        ID: this.department.manager?.ID || null,
                        name: this.department.manager?.person?.name || ''
                    };
                }
            },
            validateForm() {
                const isValid = this.departmentForm.name?.length > 0;
                this.$emit('valid', isValid);
            },
            async saveDepartment() {
                this.$emit('progress', true);
                const payload = {
                    name: this.departmentForm.name,
                    managerID: this.collaborators.selectedCollaborator?.ID || null
                };

                try {
                    if (this.action === 'create') {
                        await this.departmentApi.Create(payload);
                    } else {
                        await this.departmentApi.Update(payload, this.departmentForm.ID);
                    }

                    this.$toast.add({
                        severity: 'success',
                        summary: 'Sucesso',
                        detail: 'Operação realizada com sucesso',
                        life: 3000
                    });
                } catch (error) {
                    console.error(error);
                } finally {
                    this.$emit('progress', false);
                    this.$emit('close');
                }
            },
            setDepartmentManager(event) {
                this.collaborators.selectedCollaborator = {
                    ID: event?.ID || null,
                    name: event?.person?.name || ''
                };
                this.validManager = !!event?.ID;
                this.validateForm();
            },
            clearDepartmentManagerSelection() {
                this.collaborators.selectedCollaborator = { ID: null, name: '' };
                this.validManager = false;
                this.validateForm();
            }
        },
        watch: {
            save: 'saveDepartment'
        }
    });
</script>

<template>
    <div class="p-input-filled p-button-filled">
        <div class="flex flex-column field">
            <label for="name">Nome:</label>
            <InputText
                id="name"
                v-model="departmentForm.name"
                placeholder="Nome do departamento"
                class="w-full border-transparent"
                @input="validateForm" />
        </div>
        <div class="flex flex-column field">
            <label for="manager">Gerente/Supervisor:</label>
            <input-text-options
                @selection="setDepartmentManager"
                @cleared="clearDepartmentManagerSelection"
                @disablePersisteButton="$emit('disablePersisteButton')"
                :inputCurrentSelectionValue="collaborators.selectedCollaborator?.name"
                :currentSelection="collaborators.selectedCollaborator"
                :multiselect="false"
                :showColumns="['id', 'name', 'tx_id']"
                :searchAPI="collaboratorApi"
                :apiPropertyName="'collaborators'"
                :showSearch="true"
                :showClear="collaborators.selectedCollaborator?.ID != null"
                :enableConfirmationButton="validManager"
                :lazyLoadDialogMaximizable="true"
                :lazyLoadTextHeader="'Definir gerente de departamento'" />
        </div>
    </div> 
</template>
