import AxiosInstance from '@/services/AxiosInstance';

const totp = '/totp';
const manager = '/manager';
const totpManagerEndpoint = `${totp}${manager}`;

/**
 * Class to interact with the TOTP Manager API
 */
export default class PersonRouter {
    /**
     * Configures TOTP for the user.
     *
     * @async
     * @returns {Promise<Object>} - A promise that resolves to the configuration data.
     * @throws {Error} - Throws an error if configuration fails.
     */
    async Configure() {
        try {
            const response = await AxiosInstance.post(`${totpManagerEndpoint}/configure`);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to configure TOTP: ${error.message}`);
        }
    }

    /**
     * Checks if TOTP is enabled for the user.
     *
     * @async
     * @returns {Promise<Object>} - A promise that resolves to the enabled status data.
     * @throws {Error} - Throws an error if retrieval fails.
     */
    async Enabled() {
        try {
            const response = await AxiosInstance.get(`${totpManagerEndpoint}/enabled`);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to check if TOTP is enabled: ${error.message}`);
        }
    }

    /**
     * Validates the TOTP token for the user.
     *
     * @async
     * @param {Object} payload - The validation data (TOTP token).
     * @returns {Promise<Object>} - A promise that resolves to the validation status.
     * @throws {Error} - Throws an error if validation fails.
     */
    async Validate(payload) {
        try {
            const response = await AxiosInstance.post(`${totpManagerEndpoint}/validate`, payload);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to validate TOTP: ${error.message}`);
        }
    }

    /**
     * Authenticates the user with TOTP.
     *
     * @async
     * @param {Object} payload - The authentication data (TOTP token).
     * @returns {Promise<Object>} - A promise that resolves to the authentication status.
     * @throws {Error} - Throws an error if authentication fails.
     */
    async Authenticate(payload) {
        try {
            const response = await AxiosInstance.post(`${totp}/auth`, payload);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to authenticate with TOTP: ${error.message}`);
        }
    }
}
