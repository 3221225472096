<script>
import DashboardRouter from '@/api/routes/Dashboard';
import LoadApplicationData from '@/services/ApplicationData';
import { AccessType } from '../services/StorageKeys';
import Lottie from '../components/Utils/Lottie.vue';
import Loading from '@/layout/lotties/loading.json';

export default {
    components: {
        Lottie
    },
    data() {
        return {
            loading: {
                envelopesWidget: false,
                punchesInfoWidget: false
            },
            loadingLottie: {
                animation: Loading,
                height: 50,
                width: 50,
                speed: 1,
                loop: true
            },
            widgets: {
                envelopesCount: [],
                punchesInfo: [],
                punchPendencyChart: {
                    chartData: {
                        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                        datasets: [
                            {
                                label: 'My First dataset',
                                backgroundColor: '#42A5F5',
                                data: [65, 59, 80, 81, 56, 55, 40]
                            },
                            {
                                label: 'My Second dataset',
                                backgroundColor: '#FFA726',
                                data: [28, 48, 40, 19, 86, 27, 90]
                            }
                        ]
                    }
                }
            },
            home: { icon: 'pi pi-home', to: '/' },
            items: [
                { label: 'Inicio', to: this.$router.currentRoute._value },
                { label: 'Dashboard', to: this.$router.currentRoute._value }
            ],
            DashboardApi: null,
            DocumentApi: null
        };
    },
    created() {
        this.DashboardApi = new DashboardRouter();
    },
    async mounted() {
        const accessType = localStorage.getItem(AccessType);

        if (accessType !== 'DEFAULT_ACCESS') {
            this.$store.dispatch('auth/logout');
            this.$router.push('/auth/login');
            return;
        }

        let initialPage = await this.GetDefaultRouterPage();
        if (initialPage === '/') {
            await this.loadDashboardContent();
        } else {
            this.$router.push(initialPage);
        }
    },
    methods: {
        async GetDefaultRouterPage() {
            try {
                let initialPage = await LoadApplicationData().then((data) => {
                    return data.userInfo.roles.indexPage;
                });
                return initialPage;
            } catch (err) {
                await this.loadDashboardContent();
            }
        },
        async loadDashboardContent() {
            this.getPunchesWidget();
            this.getEnvelopesWidget();
        },
        async getPunchesWidget() {
            try {
                this.loading.punchesInfoWidget = true;
                const { punchesInfo } = await this.DashboardApi.PunchesInfo();
                this.widgets.punchesInfo = punchesInfo;
            } catch (e) {
                console.error(e);
            } finally {
                this.loading.punchesInfoWidget = false;
            }
        },
        async getEnvelopesWidget() {
            try {
                this.loading.envelopesWidget = true;
                const { envelopesCount } = await this.DashboardApi.EnvelopesCount();
                this.widgets.envelopesCount = envelopesCount;
            } catch (e) {
                console.error(e);
            } finally {
                this.loading.envelopesWidget = false;
            }
        }
    }
};
</script>

<template>
    <Breadcrumb :home="home" :model="items" />
    <Divider />
    <Card>
        <template #title>
            <div class="flex flex-row justify-content-between">
                <span class="font-light text-700 text-xl">Envelopes</span>
                <div class="flex flex-row align-items-center text-bluegray-500 text-sm" style="gap: 5px">
                    <i class="pi pi-info-circle" />
                    <span class="font-light">Atualizado recentemente</span>
                </div>
            </div>
            <Divider />
        </template>
        <template #content>
            <div class="flex justify-content-center">
                <Splitter class="w-full" style="height: 110px" layout="horizontal" v-if="!loading.envelopesWidget">
                    <SplitterPanel :size="30" :minSize="30" v-for="item in widgets.envelopesCount" :key="item">
                        <div class="p-4">
                            <div class="flex justify-content-between">
                                <div>
                                    <span class="block text-800 text-lg mb-3">{{ item.eventName }}</span>
                                    <div class="text-900 text-2xl font-bold">
                                        {{ item.count }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SplitterPanel>
                </Splitter>
                <Splitter class="w-full" style="height: 110px" layout="horizontal" v-else>
                    <SplitterPanel :size="30" :minSize="30">
                        <div class="flex justify-content-center align-items-center h-full">
                            <Lottie :lottieProp="this.loadingLottie" />
                        </div>
                    </SplitterPanel>
                </Splitter>
            </div>
        </template>
    </Card>
    <Divider />
    <Card>
        <template #title>
            <div class="flex flex-row justify-content-between">
                <span class="font-light text-700 text-xl">Pendências / Ajustes pontos (últimos 30 dias)</span>
                <div class="flex flex-row align-items-center text-bluegray-500 text-sm" style="gap: 5px">
                    <i class="pi pi-info-circle" />
                    <span class="font-light">Atualizado nas ultimas 24 horas</span>
                </div>
            </div>
        </template>
        <template #content>
            <div class="flex justify-content-center">
                <Splitter class="w-full" style="height: 110px" layout="horizontal" v-if="!loading.punchesInfoWidget">
                    <SplitterPanel :size="30" :minSize="30" v-for="item in widgets.punchesInfo" :key="item">
                        <div class="p-4">
                            <div class="flex justify-content-between">
                                <div>
                                    <span class="block text-800 text-lg mb-3">{{ item.label }}</span>
                                    <div class="text-900 text-2xl font-bold">
                                        {{ item.value }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SplitterPanel>
                </Splitter>
                <Splitter class="w-full" style="height: 110px" layout="horizontal" v-else>
                    <SplitterPanel :size="30" :minSize="30">
                        <div class="flex justify-content-center align-items-center h-full">
                            <Lottie :lottieProp="this.loadingLottie" />
                        </div>
                    </SplitterPanel>
                </Splitter>
            </div>
        </template>
    </Card>
    <Divider />
    <!-- <Card>
        <template #title>
            <div class="flex flex-row justify-content-between">
                <span class="font-light text-700 text-xl">Pendências X Ajustes Mensal</span>
                <div class="flex flex-row align-items-center text-bluegray-500 text-sm" style="gap: 5px">
                    <i class="pi pi-info-circle" />
                    <span class="font-light">Atualizado nas ultimas 24 horas</span>
                </div>
            </div>
        </template>
        <template #content>
            <div class="flex">
                <Chart type="bar" class="w-full" :data="widgets.punchPendencyChart.chartData" :options="widgets.punchPendencyChart.chartOptions" />
            </div>
        </template>
    </Card> -->
</template>
