import Groups from './Metrics/Groups';
import Types from './Metrics/Types';
import Options from './Metrics/Options';
import MetricTemplates from './Metrics/Templates';
import Templates from './Templates.vue';
import Assessments from './Assessments.vue';

const router = [
    {
        path: '/assessments',
        name: 'assessments',
        children: [
            {
                path: '',
                name: 'assessments-details',
                component: Assessments
            },
            {
                path: 'templates',
                name: 'templates',
                component: Templates
            },
            {
                path: 'metrics',
                name: 'metrics',
                children: [
                    {
                        path: 'templates',
                        name: 'assessment-metric-template',
                        component: MetricTemplates
                    },
                    {
                        path: 'groups',
                        name: 'assessment-metric-groups',
                        component: Groups
                    },
                    {
                        path: 'types',
                        name: 'assessment-metric-types',
                        component: Types
                    },
                    {
                        path: 'options',
                        name: 'assessment-metric-options',
                        component: Options
                    }
                ]
            }
        ]
    }
];

export default router;
