<script>
    import { defineComponent } from 'vue';
    import { FilterMatchMode } from 'primevue/api';
    import PDFViewer from '../../Utils/PDFViewer.vue';
    import EnvelopesRouter from '@/api/routes/Envelope';
    import PasswordModal from '../../Utils/PasswordModal.vue';
    import DocumentRouter from '@/api/routes/Document';
    import EnvelopeComments from '../../Utils/EnvelopeComments.vue';
    import EnvelopeEventTypeRouter from '@/api/routes/EnvelopeEventType';
    import EnvelopeEventRouter from '@/api/routes/EnvelopeEvent';
    import EnvelopeTimeline from '../../Utils/EnvelopeTimeline.vue';
    import moment from 'moment';
    import Lottie from '../../Utils/Lottie.vue';
    import Visualized from '../../../layout/lotties/visualized.json';
    import Pending from '../../../layout/lotties/warning.json';
    export default defineComponent({
        components: {
            PDFViewer,
            PasswordModal,
            EnvelopeComments,
            EnvelopeTimeline,
            Lottie
        },
        data() {
            return {
                loading: false,
                pagination: {},
                pageRows: 10,
                filters: {
                    name: {
                        value: '',
                        matchMode: 'contains'
                    }
                },
                dataViewMsg: null,
                headerEvent: '',
                documentModal: {
                    show: false,
                    valid: false,
                    action: null,
                    password: null,
                    rotate: 1,
                    zoom: 500,
                    base64: null,
                    content: null,
                    wrongPassword: false,
                    observations: null,
                    envelopeEvent: null,
                    documents: [],
                    ID: null,
                    envelope: {}
                },
                documents: {
                    layout: 'list',
                    records: [],
                    totalRecords: null
                },
                envelopeTimeline: {
                    envelope: null,
                    show: false
                },
                envelopeEventTypes: [],
                sort: {
                    sortKey: null,
                    sortOrder: null,
                    sortField: null,
                    sortOptions: [
                        { label: 'Aguardando assinatura', value: 'pending' },
                        { label: 'Assinados', value: 'signed' },
                        { label: 'Contestados', value: 'refused' }
                    ]
                },
                lottieSuccess: {
                    animation: Visualized,
                    height: 40,
                    width: 40,
                    speed: 0.7,
                    loop: false
                },
                lottiePending: {
                    animation: Pending,
                    height: 40,
                    width: 40,
                    speed: 0.7,
                    loop: false
                },
                home: { icon: 'pi pi-home', to: '/' },
                items: [{ label: 'Documentos', to: this.$router.currentRoute._value }],
                EnvelopeApi: null,
                DocumentApi: null,
                EnvelopeEventTypesApi: null,
                EnvelopeEventsApi: null
            };
        },
        created() {
            this.EnvelopeApi = new EnvelopesRouter();
            this.DocumentApi = new DocumentRouter();
            this.EnvelopeEventTypesApi = new EnvelopeEventTypeRouter();
            this.EnvelopeEventsApi = new EnvelopeEventRouter();
            this.FilterInit();
        },
        async mounted() {
            await this.LoadAllEnvelopeEventsTypes();

            this.pageRows = this.$refs.dt.rows;
            this.getEventTypeQuery();
        },
        watch: {
            $route() {
                this.getEventTypeQuery();
            }
        },
        methods: {
            async getEventTypeQuery() {
                const { envelopeEventTypes } = this;
                const pendingEnvelopeEventTypes = envelopeEventTypes.filter((ee) => ['cancelled', 'refused', 'signed'].includes(ee.slug)).map((ee) => ee.ID);

                const { params } = this.$router.currentRoute._value;
                const eventTypes = {
                    pending: {
                        headerEvent: 'pendentes',
                        globalFilter: {
                            envelopeEventsTypes: pendingEnvelopeEventTypes,
                            matchMode: FilterMatchMode.NOT_CONTAINS
                        }
                    },
                    all: {
                        headerEvent: '',
                        globalFilter: {
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }
                    }
                };

                const selectedEvent = eventTypes[params?.event] || eventTypes.all;

                this.headerEvent = selectedEvent.headerEvent;
                this.filters.global = selectedEvent.globalFilter;

                if (params?.event === 'pending' || params?.event === 'all') {
                    this.OnFilter();
                }
            },

            async LoadUserDocuments() {
                this.loading = true;
                this.documents.records = [];
                let cancelledEvent = this.envelopeEventTypes.find((eet) => eet.slug === 'cancelled').ID;
                this.dataViewMsg = 'Verificando se você possui documentos...';
                setTimeout(() => {
                    this.EnvelopeApi.LoggedInList(JSON.stringify(this.pagination))
                        .then((resp) => {
                            this.documents.records = resp.envelopes;
                            this.documents.totalRecords = resp.totalRecords;
                        })
                        .finally(() => {
                            this.documents.records = this.documents.records.filter((envelope) => !envelope.envelopeEvents.find((event) => event.envelopeEventTypeID === cancelledEvent));
                            this.dataViewMsg = 'No momento você não possui documentos';
                            this.loading = false;
                        });
                }, Math.random() * 1000 + 250);
            },
            FormatDate(date) {
                return moment(date).format('DD/MM/YYYY');
            },
            OnSortChange(event) {
                const value = event.value.value;
                this.pagination.sortField = `eet.${value}`;
                this.OnFilter();
            },
            OnPage(event) {
                this.pagination = event;
                this.LoadUserDocuments();
            },
            OnFilter() {
                this.pagination.filters = this.filters;
                this.LoadUserDocuments();
            },
            async ViewDocument(slot) {
                this.documentModal.ID = slot?.documents[0].ID
                this.documentModal.envelope.ID = slot.ID;
                let hasPassword = slot?.documents.filter((d) => d.protected === true).length > 0;
                this.documentModal.documents = slot?.documents.map((d) => {
                    return {
                        content: d.content
                    };
                });
                if (hasPassword) {
                    this.documentModal.action = 'password';
                    this.documentModal.show = true;
                } else {
                    await this.GetBase64(this.documentModal.documents);
                    this.documentModal.action = 'view';
                    this.documentModal.show = true;
                }
            },
            async OnDocumentRendered() {
                let eventTypeId = await this.EnvelopeEventTypesApi.FindBySlug('visualized').then((data) => {
                    return data.envelopeEventType.ID;
                });

                let document = this.documents.records?.find((d) => d.ID === this.documentModal.envelope.ID);
                let hasVisualizedEvent = document.envelopeEvents?.filter((ee) => ee.envelopeEventTypeID === eventTypeId);
                if (hasVisualizedEvent?.length > 0) {
                    return;
                }
                let payload = {
                    envelopeId: this.documentModal.envelope.ID,
                    envelopeEventTypeId: eventTypeId
                };

                this.EnvelopeEventsApi.Create(payload);
            },
            OnWrongPassword() {
                this.documentModal.action = 'password';
                this.documentModal.wrongPassword = true;
                this.documentModal.valid = false;
            },
            async OnPasswordConfirmPressed() {
                if (!this.documentModal.wrongPassword) {
                    await this.GetBase64(this.documentModal.documents);
                }
                this.documentModal.action = 'view';
            },
            SetZoom(type) {
                let minZoom = 100;
                let maxZoom = 1200;
                if (type == 'IN' && this.documentModal.zoom != maxZoom) {
                    return (this.documentModal.zoom += 100);
                } else if (type == 'OUT' && this.documentModal.zoom != minZoom) {
                    return (this.documentModal.zoom -= 100);
                }
            },
            async GetBase64(documents) {
                for (var i = 0; i < documents.length; i++) {
                    let payload = { file: documents[i].content };
                    await this.DocumentApi.View('base64', payload).then((resp) => (this.documentModal.base64 = resp?.base64File));
                }
            },
            ValidPassword(event) {
                event ? (this.documentModal.valid = true) : (this.documentModal.valid = false);
            },
            OnGetEnvelopeObservations(event) {
                this.documentModal.action = 'observations';
                this.documentModal.envelopeEvent = event;
            },
            async LoadAllEnvelopeEventsTypes() {
                this.envelopeEventTypes = await this.EnvelopeEventTypesApi.FindAll().then((data) => {
                    return data.envelopeEventTypes;
                });
            },
            GetBadgeIcon(slot) {
                try {
                    slot?.sort(function (a, b) {
                        return new Date(b.createdAt) - new Date(a.createdAt);
                    });

                    let mostRecentEvent = slot[0];
                    let eet = this.envelopeEventTypes.find((e) => e.ID === mostRecentEvent.envelopeEventTypeID);

                    if (eet.slug == 'created' || eet.slug == 'visualized') {
                        return this.lottiePending;
                    } else if (eet.slug == 'signed') {
                        return this.lottieSuccess;
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            async CreateEnvelopeEvent() {
                let eventTypeSlug = this.documentModal.envelopeEvent;
                let eventTypeId = await this.EnvelopeEventTypesApi.FindBySlug(eventTypeSlug).then((data) => {
                    return data.envelopeEventType.ID;
                });

                let payload = {
                    envelopeId: this.documentModal.envelope.ID,
                    envelopeEventTypeId: eventTypeId,
                    observations: this.documentModal.observations
                };

                this.$confirm.require({
                    header: 'Confirmação',
                    message: 'Confirmar operação ?',
                    icon: 'far fa-question-circle',
                    acceptLabel: 'Sim',
                    rejectLabel: 'Não',
                    accept: () => {
                        this.EnvelopeEventsApi.Create(payload)
                            .then(() => {
                                this.$toast.add({
                                    severity: 'success',
                                    summary: 'Sucesso',
                                    detail: 'Sua solicitação foi realizada com sucesso',
                                    life: 3000
                                });
                            })
                            .finally(() => {
                                this.documentModal.show = false;
                                this.LoadUserDocuments();
                            });
                    },
                    reject: () => {
                        this.documentModal.show = false;
                    }
                });
            },
            async OnDownload(ID) {
                try {
                    await this.DocumentApi.DocumentDownload(ID, true);
                } catch (err) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro',
                        detail: 'Ocorreu um erro ao tentar baixar o documento, consulte o console para mais detalhes.',
                        life: 3000
                    });
                    console.error(err);
                }
            },
            ResetDocumentModalState() {
                this.documentModal = {
                    show: false,
                    valid: false,
                    action: null,
                    password: null,
                    rotate: 1,
                    zoom: 500,
                    base64: null,
                    document: [],
                    envelope: {},
                    wrongPassword: false,
                    observations: null
                };
            },
            ShowEnvelopeTimeline(slot) {
                this.envelopeTimeline.show = true;
                this.envelopeTimeline.envelope = { ...slot };
            },
            ResetTimelineModalState() {
                this.envelopeTimeline.envelope = null;
            },
            GetDocumentFormat(slot) {
                let fileExt = slot[0].content.split('.').pop();
                return fileExt;
            },
            FilterInit() {
                this.pagination = {
                    page: 1,
                    rows: this.pageRows
                };
                this.filters = {
                    global: {
                        value: null,
                        matchMode: FilterMatchMode.CONTAINS
                    }
                };
            }
        },
        computed: {
            EnvelopeActionsButtons() {
                let envelope = this.documents.records.find((d) => d.ID === this.documentModal.envelope.ID);

                envelope.envelopeEvents.sort(function (a, b) {
                    return new Date(b.createdAt) - new Date(a.createdAt);
                });

                let mostRecentEvent = envelope.envelopeEvents[0];

                let eet = this.envelopeEventTypes.find((e) => e.ID === mostRecentEvent.envelopeEventTypeID);

                return eet.slug === 'refused' || eet.slug === 'signed';
            },
            DocumentModalTitle() {
                return this.documentModal.action == 'password' ? 'Documento protegido' : this.documentModal.action == 'view' ? 'Visualizar documento' : 'Observações';
            }
        }
    });
</script>
<template>
    <Breadcrumb :home="home" :model="items" />
    <Divider />
    <Card>
        <template #title>
            <span class="font-light text-700 text-xl">Meus documentos {{ headerEvent }}</span>
        </template>
        <template #content>
            <DataView
                ref="dt"
                dataKey="ID"
                lazy="true"
                v-model:filters="filters"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} entradas"
                responsiveLayout="scroll"
                :rowsPerPageOptions="[10, 20, 50, 100, 300]"
                :value="documents.records"
                :layout="documents.layout"
                :paginator="true"
                :rows="10"
                :loading="loading"
                :totalRecords="documents.totalRecords"
                :sortOrder="sort.sortOrder"
                :sortField="sort.sortField"
                @page="OnPage($event)"
                @filter="OnFilter($event)"
                @sort="onSort($event)">
                <template #header>
                    <Toolbar>
                        <template v-slot:start>
                            <div class="lg:mt-0 sm:mt-2 flex flex-row" style="gap: 0.5rem">
                                <Button
                                    icon="fas fa-redo-alt"
                                    class="p-button-rounded p-button-info p-button-outlined p-button-raised"
                                    @click="LoadUserDocuments()"
                                    :disabled="loading"
                                    title="Atualizar lista" />
                            </div>
                        </template>
                    </Toolbar>
                </template>

                <template #list="slotProps">
                    <div class="col-12">
                        <div class="item-list-item">
                            <div class="relative">
                                <span class="badge-notification-list">
                                    <lottie :lottieProp="GetBadgeIcon(slotProps.data.envelopeEvents)" />
                                </span>
                                <img
                                    :src="'images/envelopes/' + GetDocumentFormat(slotProps.data?.documents) + '.png'"
                                    onerror="javascript:this.src='images/envelopes/doc.png'"
                                    alt="document"
                                    style="width: 64px; height: 64px" />
                            </div>
                            <div class="item-list-detail">
                                <div class="item-name">
                                    {{ slotProps.data?.title || slotProps.data?.description }}
                                </div>
                                <div class="item-description">
                                    <span class="item-category">Tipo: {{ slotProps.data?.envelopeType.name }}</span>
                                </div>
                                <div title="Data de referência do documento" v-if="slotProps.data?.documents[0]?.referenceDate">
                                    <i class="fas fa-calendar mr-1" />
                                    <span class="item-category">{{ slotProps.data?.documents[0].referenceDate }}</span>
                                </div>
                            </div>
                            <div class="item-list-action">
                                <div class="flex flex-column w-full" style="gap: 0.5rem">
                                    <Button class="p-button-sm p-button-info p-button-outlined p-button-raised" icon="fas fa-eye" label="Visualizar" @click="ViewDocument(slotProps.data)" />
                                    <Button class="p-button-sm p-button-warning p-button-outlined p-button-raised" icon="fas fa-stream" label="Eventos" @click="ShowEnvelopeTimeline(slotProps.data)" />
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template #empty>
                    <div class="empty-dataview" style="gap: 0.7rem">
                        <div v-if="loading">
                            <i class="pi pi-spin pi-spinner text-xl" />
                        </div>
                        <span class="text-900 font-medim text-lg text-center">
                            {{ dataViewMsg }}
                        </span>
                    </div>
                </template>
            </DataView>
        </template>
    </Card>
    <Dialog
        v-model:visible="documentModal.show"
        :header="DocumentModalTitle"
        :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        :maximizable="documentModal.action == 'password' ? false : true"
        @hide="ResetDocumentModalState()">
        <PasswordModal v-if="documentModal.action == 'password'" @password="documentModal.password = $event" @valid="ValidPassword($event)" :wrongPassword="documentModal.wrongPassword" />

        <PDFViewer
            v-if="documentModal.action == 'view'"
            :pdf="documentModal.base64"
            :password="documentModal.password"
            :zoom="documentModal.zoom"
            :rotate="documentModal.rotate"
            @rendered="OnDocumentRendered()"
            @wrongPassword="OnWrongPassword()" />

        <EnvelopeComments v-if="documentModal.action == 'observations'" @observations="documentModal.observations = $event" />

        <template #footer>
            <div class="flex flex-row justify-content-end" v-if="documentModal.action == 'password'">
                <Button label="Cancelar" iconPos="right" icon="fas fa-times" autofocus @click="documentModal.show = false" class="w-auto" />
                <Button :disabled="!documentModal.valid" label="Continuar" iconPos="right" icon="fas fa-arrow-right" autofocus @click="OnPasswordConfirmPressed()" class="w-auto" />
            </div>
            <div class="flex mt-4 justify-content-between w-full" v-if="documentModal.action == 'view'">
                <div class="flex">
                    <Button icon="fa-solid fa-magnifying-glass-plus ml-2 mr-2" @click="SetZoom('IN')" title="Aumentar o zoom" autofocus />
                    <Button icon="fa-solid fa-magnifying-glass-minus ml-2 mr-2" @click="SetZoom('OUT')" title="Reduzir o zoom" autofocus />
                    <Button icon="fa-solid fa-rotate-right ml-2 mr-2" @click="documentModal.rotate++" title="Girar 90º" autofocus />
                    <Button icon="fa-solid fa-download ml-2 mr-2" title="Baixar documento" @click="OnDownload(documentModal.ID)" autofocus />
                </div>
                <div class="flex flex-row sm:flex flex-wrap justify-content-end" style="gap: 0.5rem">
                    <Button autofocus label="Recusar" icon="fa-solid fa-times" class="p-button-danger w-8rem" :disabled="EnvelopeActionsButtons" @click="OnGetEnvelopeObservations('refused')" />

                    <Button autofocus label="Aceitar" icon="fa-solid fa-check" class="p-button-success w-8rem" :disabled="EnvelopeActionsButtons" @click="OnGetEnvelopeObservations('signed')" />
                </div>
            </div>
            <div class="flex flex-row justify-content-end" v-if="documentModal.action == 'observations'">
                <Button label="Cancelar" iconPos="right" icon="fas fa-times" autofocus @click="documentModal.show = false" class="w-auto" />
                <Button :disabled="!documentModal.valid" label="Continuar" iconPos="right" icon="fas fa-arrow-right" autofocus @click="CreateEnvelopeEvent()" class="w-auto" />
            </div>
        </template>
    </Dialog>

    <Dialog
        v-model:visible="envelopeTimeline.show"
        header="Eventos do envelope"
        :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        style="width: 30vw"
        :maximizable="false"
        @hide="ResetTimelineModalState()">
        <EnvelopeTimeline :envelope="envelopeTimeline.envelope" :envelopeEventsTypesList="envelopeEventTypes" />
        <template #footer>
            <div class="flex flex-row justify-content-end">
                <Button label="Ok" iconPos="right" autofocus @click="envelopeTimeline.show = false" class="w-auto" />
            </div>
        </template>
    </Dialog>
</template>

<style lang="scss" scoped>
    .card {
        padding: 2rem;
        border-radius: 4px;
        margin-bottom: 2rem;
    }
    .p-dropdown {
        width: 14rem;
        font-weight: normal;
    }

    .item-name {
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: 2px;
    }

    .item-description {
        margin: 0 0 1rem 0;
    }

    .item-category-icon {
        vertical-align: middle;
        margin-right: 0.5rem;
    }

    .item-category {
        font-weight: 600;
        vertical-align: middle;
    }

    ::v-deep(.item-list-item) {
        display: flex;
        align-items: center;
        padding: 1rem;
        padding-left: 1%;
        width: 100%;

        img {
            width: 50px;
            margin-right: 1rem;
        }

        .item-list-detail {
            flex: 1 1 0;
        }

        .p-rating {
            margin: 0 0 0.5rem 0;
        }

        .item-list-action {
            display: flex;
            flex-direction: column;
        }

        .p-button {
            margin-bottom: 0.5rem;
        }
    }

    ::v-deep(.item-grid-item) {
        margin: 0.5rem;
        border: 1px solid var(--surface-border);

        .item-grid-item-top,
        .item-grid-item-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        img {
            margin: 2rem 0;
        }

        .item-grid-item-content {
            text-align: center;
        }
    }

    @media screen and (max-width: 576px) {
        .item-list-item {
            flex-direction: column;
            align-items: center;

            img {
                margin: 2rem 0;
            }

            .item-list-detail {
                text-align: center;
            }

            .item-list-action {
                display: flex;
                flex-direction: column;
            }

            .item-list-action {
                margin-top: 2rem;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
            }
        }
    }

    @media screen and (max-width: 1080px) {
        .badge-notification-grid {
            left: 420px !important;
            bottom: 135px !important;
            position: absolute !important;
            color: white;
        }
    }
    .badge-notification-list {
        left: -5px !important;
        bottom: 43px !important;
        position: absolute !important;
        color: white;
    }
    .badge-notification-grid {
        left: 280px !important;
        bottom: 135px !important;
        position: absolute !important;
        color: white;
    }
</style>
