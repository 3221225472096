<script>
    import { defineComponent } from 'vue';
    export default defineComponent({
        computed: {
            currentYear() {
                return new Date().getFullYear();
            }
        }
    });
</script>
<template>
    <div class="flex flex-column layout-footer justify-content-center text-center mt-4">
        <span class="text-md text-700 font-bold">{{ `Copyright © ${currentYear} | IR Tecnologia - Todos os direitos reservados.` }}</span>
        <span class="text-sm text-500 font-bold mb-2 mt-1">(S.O.G.E) - Sistemas Operacionais, Gerências e Estratégicos</span>
    </div>
</template>
