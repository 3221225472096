import AxiosInstance from '@/services/AxiosInstance';

const collaboratorWorkScheduleEndpoint = '/collaborator-work-schedule';

/**
 * Class to interact with the Collaborator Work Schedule API
 */
export default class CollaboratorWorkScheduleRouter {
    /**
     * Retrieves all collaborator work schedules.
     *
     * @async
     * @returns {Promise<Object>} - A promise that resolves to the list of all work schedules.
     * @throws {Error} - Throws an error if retrieval fails.
     */
    async FindAll() {
        try {
            const response = await AxiosInstance.get(collaboratorWorkScheduleEndpoint);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to retrieve collaborator work schedules: ${error.message}`);
        }
    }
}
