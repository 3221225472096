import AxiosInstance from '@/services/AxiosInstance';

const assessmentMetricGroupsEndpoint = 'assessment-metric-groups';

/**
 * Class responsible for interacting with the Assessment Metric Groups API.
 */
export default class AssessmentMetricGroupRouter {
    /**
     * Searches for assessment metric groups based on the provided search criteria.
     *
     * @async
     * @param {Object} payload - The search criteria for assessment metric groups.
     * @returns {Promise<Object>} - A promise that resolves to the search results.
     * @throws {Error} - Throws an error if the search request fails.
     */
    async Search(payload) {
        try {
            const response = await AxiosInstance.post(`${assessmentMetricGroupsEndpoint}/search`, payload);
            return response.data?.data;
        } catch (error) {
            throw new Error(`Failed to search assessment metric groups: ${error.message}`);
        }
    }

    /**
     * Creates a new assessment metric group.
     *
     * @async
     * @param {Object} payload - The data for the new assessment metric group.
     * @returns {Promise<Object>} - A promise that resolves to the created assessment metric group.
     * @throws {Error} - Throws an error if the creation request fails.
     */
    async Create(payload) {
        try {
            const response = await AxiosInstance.post(`${assessmentMetricGroupsEndpoint}`, payload);
            return response.data;
        } catch (error) {
            throw new Error(`Failed to create assessment metric group: ${error.message}`);
        }
    }

    /**
     * Updates an existing assessment metric group by its ID.
     *
     * @async
     * @param {number} ID - The unique identifier of the assessment metric group.
     * @param {Object} payload - The updated data for the assessment metric group.
     * @returns {Promise<Object>} - A promise that resolves to the updated assessment metric group.
     * @throws {Error} - Throws an error if the update request fails.
     */
    async Update(ID, payload) {
        try {
            const response = await AxiosInstance.put(`${assessmentMetricGroupsEndpoint}/${ID}`, payload);
            return response.data;
        } catch (error) {
            throw new Error(`Failed to update assessment metric group with ID ${ID}: ${error.message}`);
        }
    }

    /**
     * Removes one or more assessment metric groups based on their IDs.
     *
     * @async
     * @param {Array<string>} ids - The list of unique identifiers for the assessment metric groups to remove.
     * @returns {Promise<void>} - A promise that resolves when the deletion is complete.
     * @throws {Error} - Throws an error if the deletion request fails.
     */
    async Remove(ids) {
        try {
            await AxiosInstance.delete(assessmentMetricGroupsEndpoint, { data: ids });
        } catch (error) {
            throw new Error(`Failed to remove assessment metric groups: ${error.message}`);
        }
    }
}
