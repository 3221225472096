import { createStore } from 'vuex';
import { auth } from './auth.module';

/**
 * Vuex store configuration.
 * @namespace store
 */
const store = createStore({
    /**
     * Modules used in the Vuex store.
     * @property {Object} modules - Vuex modules.
     */
    modules: {
        auth, // Handles authentication logic
    }
});

export default store;
