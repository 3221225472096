<script>
    import { defineComponent } from 'vue';
    import DocumentRouter from '@/api/routes/Document';
    import { debounce } from 'lodash';
    import { FilterMatchMode } from 'primevue/api';
    import StepControls from './StepControls.vue';
    import PDFViewer from '../../../Utils/PDFViewer.vue';
    import Envelope from './PayrollEnvelope.vue';
    import Sending from './Sending.vue';
    import Sent from './Sent.vue';
    import ErrorModal from './Error.vue';
    import FilterModal from '@/components/Utils/Filter.vue';
    import moment from 'moment';
    export default defineComponent({
        props: ['formData'],
        emits: ['complete'],
        components: {
            StepControls,
            PDFViewer,
            Envelope,
            Sending,
            Sent,
            ErrorModal,
            FilterModal
        },
        data() {
            return {
                DocumentApi: null,
                serverProcessing: true,
                documentID: null,
                loading: true,
                serverProcessResponse: {},
                sending: false,
                sent: false,
                slugIdentifier: null,
                stTimeInterval: null,
                searchDebounce: null,
                pagination: {},
                pageRows: 10,
                documents: [],
                selectedDocuments: [],
                totalRecords: 0,
                envelope: { title: null, description: null },
                filters: { name: { value: '', matchMode: 'contains' } },
                filterModal: {
                    show: false,
                    showFilters: ['people', 'general'],
                    selectedFilters: {}
                },
                hasFilters: false,
                showContentModal: false,
                valid: false,
                showSendModal: false,
                showErrorsModal: false,
                form: { formData: {} },
                pdf: {
                    rotate: 1,
                    zoom: 500
                },
                sendNow: false
            };
        },
        created() {
            this.DocumentApi = new DocumentRouter();
            this.searchDebounce = debounce(this.OnFilter, 800);
            this.FilterInit();
        },
        mounted() {
            this.pageRows = this.$refs?.dt?.rows;
            this.PayrollProcess();
        },
        methods: {
            DigestStatus(item) {
                let status = {
                    totalPages: item?.totalPages,
                    totalProcessed: item?.totalProcessed,
                    errors: item?.errors,
                    remaining: item?.remaining,
                    elapsed: item?.elapsed,
                    errorDescription: item?.errorDescription
                };
                return status;
            },
            PayrollProcess() {
                let payload = this.GetSendPayload();
                this.DocumentApi.PayrollProcess(payload)
                    .then((resp) => {
                        if (resp?.identifier) {
                            this.slugIdentifier = resp.identifier;
                            this.UpdatePayrollProcess();
                        } else {
                            console.error('Falha ao obter o identificador de status');

                            this.$toast.add({
                                severity: 'error',
                                summary: 'Falha',
                                detail: 'O identificador de status não foi recebido',
                                life: 3000
                            });
                            this.$router.push('/pages/error');
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                        this.$router.push('/pages/error');
                    });
            },
            UpdatePayrollProcess() {
                this.stTimeInterval = setInterval(async () => {
                    try {
                        const resp = await this.DocumentApi.PayrollProcessStatus(this.slugIdentifier);
                        this.serverProcessing = true;
                        this.serverProcessResponse = this.DigestStatus(resp.progress);

                        if (resp.progress.finished) {
                            this.serverProcessResponse.totalProcessed = this.serverProcessResponse.totalPages;
                            clearInterval(this.stTimeInterval);
                            this.serverProcessing = false;
                            this.NotifyProcessCompletion();
                            this.LoadDocuments();
                        }
                    } finally {
                        if (!this.serverProcessing) {
                            clearInterval(this.stTimeInterval);
                        }
                    }
                }, 1000);
            },
            NotifyProcessCompletion() {
                const errorsCount = this.serverProcessResponse.errors;

                const msg = {
                    severity: errorsCount > 0 ? 'error' : 'success',
                    summary: errorsCount > 0 ? 'Falhas durante o processamento' : 'Processamento concluído',
                    detail:
                        errorsCount > 0
                            ? `Ocorreram ${errorsCount} erros durante o processamento dos arquivos. Consulte a lista de erros para obter mais detalhes.`
                            : 'Todos os documentos foram processados e identificados com sucesso',
                    life: 5000
                };

                this.$toast.add(msg);
            },
            LoadDocuments() {
                this.loading = true;
                this.documents = [];
                this.selectedDocuments = [];
                setTimeout(() => {
                    this.DocumentApi.Find(JSON.stringify(this.pagination), this.slugIdentifier)
                        .then((data) => {
                            this.documents = data.documents;
                            this.totalRecords = data.totalRecords;
                        })
                        .catch((err) => {
                            console.error(err);
                            this.$router.push('/pages/error');
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }, Math.random() * 1000 + 250);
            },
            async OnViewContent(slot) {
                this.documentID = slot.ID
                if (slot.documentType.name == 'file') {
                    let payload = { file: slot?.content };
                    await this.DocumentApi.View('base64', payload).then((resp) => (this.pdf.base64 = resp?.base64File));
                    this.pdf.password = slot?.password;
                    this.showContentModal = true;
                }
            },
            SetSendParams(slot, many) {
                if (!many) {
                    this.selectedDocuments = [];
                    this.selectedDocuments.push({ ...slot });
                }
                this.showSendModal = true;
            },
            async OnDownload() {
                try {
                    await this.DocumentApi.SingleDocumentDownload();
                } catch (err) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro',
                        detail: 'Ocorreu um erro ao tentar baixar o documento, consulte o console para mais detalhes.',
                        life: 3000
                    });
                    console.error(err);
                }
            },
            OnFilter() {
                if (Object.keys(this.filterModal.selectedFilters).length > 0) {
                    this.hasFilters = true;
                }
                this.pagination.filters = this.filters;
                this.LoadDocuments();
            },
            OnPage(event) {
                this.pagination = event;
                this.LoadDocuments();
            },
            OnSort(event) {
                this.pagination = event;
                this.LoadDocuments();
            },
            OnShowProcessErrors() {
                this.showErrorsModal = true;
            },
            FilterInit() {
                this.pagination = {
                    page: 1,
                    rows: this.pageRows
                };
                this.filters = {
                    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
                };
            },
            SetZoom(type) {
                let minZoom = 100;
                let maxZoom = 1200;
                if (type == 'IN' && this.pdf.zoom != maxZoom) {
                    return (this.pdf.zoom += 100);
                } else if (type == 'OUT' && this.pdf.zoom != minZoom) {
                    return (this.pdf.zoom -= 100);
                }
            },
            GetSendPayload() {
                return {
                    split: true,
                    extract: true,
                    protect: this.formData?.documentContent.properties.optionals.protect,
                    personIDs: [this.formData?.documentContent.properties.owner],
                    referenceDate: this.formData?.documentContent?.properties?.reference ? moment(this.formData.documentContent.properties.reference).format('MM/YY') : null,
                    payrollDocumentModelId: this.formData?.documentContent?.properties?.model,
                    documentTypeId: 1,
                    content: this.formData?.documentContent.content
                };
            },
            OnFinish() {
                this.$parent.$emit('complete', this.slugIdentifier);
            },
            OnConfirmRemove(slot, many) {
                let message = many ? 'Tem certeza que deseja remover os itens selecionados?' : `Tem certeza que deseja remover: ${slot.people[0].name} da lista de envio?`;
                this.$confirm.require({
                    message: message,
                    header: 'Remover destinatário',
                    icon: 'far fa-question-circle',
                    acceptLabel: 'Sim',
                    rejectLabel: 'Não',
                    reject: () => {
                        return;
                    },
                    accept: () => {
                        this.RemoveFromListOnly(slot, many, true);
                    }
                });
            },
            RemoveFromListOnly(slot, many, delFromServer) {
                let items = [];
                this.loading = true;
                many
                    ? (items = slot.map((item) => {
                          return item.ID;
                      }))
                    : items.push(slot.ID);
                if (delFromServer) {
                    this.DocumentApi.Remove(items).finally(() => this.LoadDocuments());
                } else {
                    this.LoadDocuments();
                }
                this.loading = false;
            },
            ResetSendModalState() {
                this.showSendModal = false;
                this.valid = false;
                this.sending = false;
                this.sent = false;
                this.sendNow = false;
            },
            onSendingPayroll() {
                this.sending = true;
            },
            OnSendPayrollFinished() {
                this.RemoveFromListOnly(this.selectedDocuments, true, false);
                this.sending = false;
                this.sent = true;
            },
            Valid(valid) {
                this.valid = valid;
            },
            SendModalTitle() {
                return !this.sending && !this.sent ? 'Enviar documento' : this.sending && !this.sent ? 'Enviando...' : 'Envelope criado com sucesso';
            },
            CloseFilterModal() {
                this.filterModal.show = false;
            },
            ShowFilterModal() {
                this.filterModal.show = true;
            },
            MakeFilter(filter) {
                this.filterModal.selectedFilters = Object.assign({}, this.filterModal.selectedFilters, filter);
                this.filters.global = Object.assign({}, this.filters.global, filter);
            },
            ClearFilters() {
                this.filterModal.selectedFilters = [];
                this.FilterInit();
                this.hasFilters = false;
                this.OnFilter();
            },
            FilterSearch() {
                this.filterModal.show = false;
                this.OnFilter();
            }
        }
    });
</script>
<template>
    <div class="flex justify-content-center">
        <Splitter class="w-full" style="height: 110px" layout="horizontal">
            <SplitterPanel :size="30" :minSize="30">
                <div class="p-3">
                    <div class="flex flex-column justify-content-between">
                        <span class="block text-800 text-lg mb-3">Status</span>
                        <div class="text-900 font-medium text-xl">
                            <div class="flex flex-row align-items-center justify-content-center" style="gap: 5px">
                                <i v-if="serverProcessing" class="pi pi-spin pi-spinner text-2xl" />
                                <span class="text-900 text-xl font-bold">{{ serverProcessing ? 'Processando...' : 'Concluído' }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </SplitterPanel>
            <SplitterPanel :size="30" :minSize="30">
                <div class="p-3">
                    <div class="flex flex-column justify-content-between">
                        <span class="block text-800 text-lg mb-3">Processados</span>
                        <div class="text-900 font-medium text-xl">
                            <div class="flex flex-row align-items-center justify-content-center" style="gap: 5px">
                                <span class="text-900 text-2xl font-bold">{{ serverProcessResponse?.totalProcessed ? serverProcessResponse?.totalProcessed : '0' }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </SplitterPanel>
            <SplitterPanel :size="30" :minSize="30">
                <div class="p-3">
                    <div class="flex flex-column justify-content-between">
                        <span class="block text-800 text-lg mb-3">Falhas</span>
                        <div class="text-900 font-medium text-xl">
                            <div class="flex flex-row align-items-center justify-content-center" style="gap: 5px">
                                <span class="text-900 text-2xl font-bold">{{ serverProcessResponse?.errors ? serverProcessResponse?.errors : '0' }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </SplitterPanel>
            <SplitterPanel :size="30" :minSize="30">
                <div class="p-3">
                    <div class="flex flex-column justify-content-between">
                        <span class="block text-800 text-lg mb-3">Total de páginas</span>
                        <div class="text-900 font-medium text-xl">
                            <div class="flex flex-row align-items-center justify-content-center" style="gap: 5px">
                                <span class="text-900 text-2xl font-bold">{{ serverProcessResponse?.totalPages ? serverProcessResponse?.totalPages : '0' }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </SplitterPanel>
            <SplitterPanel :size="30" :minSize="30">
                <div class="p-3">
                    <div class="flex flex-column justify-content-between">
                        <span class="block text-800 text-lg mb-3">Tempo decorrido</span>
                        <div class="text-900 font-medium text-xl">
                            <div class="flex flex-row align-items-center justify-content-center" style="gap: 5px">
                                <span class="text-900 text-2xl font-bold">{{ serverProcessResponse?.elapsed ? serverProcessResponse?.elapsed : '0h0m0s' }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </SplitterPanel>
        </Splitter>
    </div>
    <Divider v-if="!serverProcessing" />
    <DataTable
        v-if="!serverProcessing"
        ref="dt"
        :value="documents"
        dataKey="ID"
        :paginator="true"
        :rows="10"
        v-model:filters="filters"
        :loading="loading"
        :totalRecords="totalRecords"
        @page="OnPage($event)"
        @filter="OnFilter($event)"
        @sort="OnSort($event)"
        lazy="true"
        v-model:selection="selectedDocuments"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 20, 50, 100, 300]"
        currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} entradas"
        responsiveLayout="scroll"
        class="p-datatable-sm">
        <template #header>
            <span class="font-light text-700 text-xl mb-2">Documentos identificados</span>
            <Toolbar class="mt-2">
                <template v-slot:start>
                    <div class="flex my-2" style="gap: 0.5rem">
                        <Button
                            title="Enviar selecionados da página atual"
                            icon="fas fa-paper-plane"
                            class="p-button-rounded p-button-info p-button-outlined p-button-raised"
                            @click="showSendModal = true"
                            :disabled="selectedDocuments.length == 0" />
                        <Button
                            icon="fas fa-trash"
                            title="Remover selecionados da página atual"
                            class="p-button-rounded p-button-danger p-button-outlined p-button-raised"
                            @click="OnConfirmRemove(selectedDocuments, true)"
                            :disabled="selectedDocuments.length == 0" />
                    </div>
                </template>
                <template v-slot:end>
                    <div class="lg:mt-0 sm:mt-2 flex flex-row" style="gap: 0.5rem">
                        <Button icon="fas fa-redo-alt" class="p-button-rounded p-button-info p-button-outlined p-button-raised" @click="LoadDocuments()" :disabled="loading" title="Atualizar lista" />
                        <div class="relative">
                            <Button
                                icon="fas fa-filter"
                                class="p-button p-button-rounded p-button-info p-button-outlined p-button-raised"
                                @click="ShowFilterModal()"
                                :disabled="loading"
                                title="Escolher filtros" />
                            <Button v-if="hasFilters" icon="fas fa-broom" class="p-button-rounded p-button-raised clear-filter" title="Limpar filtros" @click="ClearFilters()" :disabled="loading" />
                        </div>
                        <Button
                            icon="fas fa-exclamation"
                            title="Falhas durante o processamento"
                            class="p-button-rounded p-button-danger p-button-outlined p-button-raised"
                            @click="OnShowProcessErrors()"
                            :disabled="serverProcessing || serverProcessResponse.errors == undefined" />
                    </div>
                </template>
            </Toolbar>
        </template>

        <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
        <Column field="registration" header="Matrícula" :sortable="false" headerStyle="width:8%; min-width:2rem;">
            <template #body="slotProps">
                <span class="p-column-title">Matrícula</span>
                <span>{{ slotProps.data?.people[0]?.collaborator?.employerRegistrationNumber }}</span>
            </template>
        </Column>
        <Column field="collaborator" header="Colaborador" :sortable="false" headerStyle="width:40%; min-width:12rem;">
            <template #body="slotProps">
                <span class="p-column-title">Colaborador</span>
                <span>{{ slotProps.data?.people[0]?.name }}</span>
            </template>
        </Column>
        <Column field="content" header="Conteúdo" :sortable="false" headerStyle="width:40%; min-width:12rem;">
            <template #body="slotProps">
                <span class="p-column-title">Conteúdo</span>
                <Button
                    :icon="slotProps.data?.documentType.name == 'file' ? 'fas fa-file' : 'fas fa-file-alt'"
                    class="p-button-rounded p-button-sm p-button-info p-button-outlined ml-3 action-button"
                    title="Ver conteúdo"
                    @click="OnViewContent(slotProps.data)" />
            </template>
        </Column>
        <Column headerStyle="min-width:8rem;">
            <template #body="slotProps">
                <Button
                    icon="fas fa-paper-plane"
                    class="p-button-rounded p-button-sm p-button-info p-button-outlined mr-2 action-button"
                    title="Enviar manualmente"
                    :disabled="!slotProps.data?.people[0]?.collaborator?.employerRegistrationNumber?.length"
                    @click="SetSendParams(slotProps.data, false)" />
                <Button
                    icon="fas fa-trash"
                    class="p-button-rounded p-button-sm p-button-danger p-button-outlined mr-2 action-button"
                    title="Remover da lista de envio"
                    @click="OnConfirmRemove(slotProps.data, false)" />
            </template>
        </Column>
    </DataTable>
    <div class="flex justify-content-end align-items-center mt-5 text-bluegray-500" style="gap: 5px" v-if="loading">
        <i class="pi pi-info-circle" />
        <span class="flex text-sm">Caso você saia desta página, os arquivos que estão sendo processados em segundo plano continuarão disponíveis no menu "Arquivos".</span>
    </div>
    <StepControls
        class="mt-3"
        :form="form"
        :disableNextButton="true"
        :hideBackButton="true"
        :hideNextButton="true"
        :disableBackButton="true"
        :showFinishButton="!loading"
        @finishClicked="OnFinish()"
        v-if="!loading" />
    <Dialog
        v-model:visible="showContentModal"
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        :maximizable="true"
        :modal="true"
        :header="'Pre-visualizar documento'"
        @hide="showContentModal = false"
        class="p-fluid">
        <PDFViewer :pdf="pdf.base64" :password="pdf.password" :zoom="pdf.zoom" :rotate="pdf.rotate"></PDFViewer>
        <template #footer>
            <div class="flex mt-4 justify-content-between">
                <div>
                    <Button icon="fa-solid fa-magnifying-glass-plus ml-2 mr-2" @click="SetZoom('IN')" title="Aumentar o zoom" autofocus />
                    <Button icon="fa-solid fa-magnifying-glass-minus ml-2 mr-2" @click="SetZoom('OUT')" title="Reduzir o zoom" autofocus />
                    <Button icon="fa-solid fa-rotate-right ml-2 mr-2" @click="pdf.rotate++" title="Girar 90º" autofocus />
                    <Button icon="fa-solid fa-download ml-2 mr-2" @click="OnDownload(documentID)" title="Baixar documento" autofocus />
                </div>
                <div>
                    <Button label="Ok" icon="fa-solid fa-check" autofocus @click="showContentModal = false" />
                </div>
            </div>
        </template>
    </Dialog>
    <Dialog
        v-model:visible="showSendModal"
        :style="{ width: '500px' }"
        :maximizable="sending || sent ? false : true"
        :closable="sending ? false : true"
        :modal="true"
        :header="SendModalTitle()"
        @hide="ResetSendModalState()"
        :closeOnEscape="sending ? false : true"
        class="p-fluid">
        <Envelope
            :send-recipients="selectedDocuments"
            :form-data="formData"
            :send="sendNow"
            @valid="Valid(true)"
            @invalid="Valid(false)"
            @sent="OnSendPayrollFinished()"
            @sending="onSendingPayroll()"
            v-if="!sending && !sent" />
        <Sending v-if="sending"></Sending>
        <Sent v-if="sent"></Sent>
        <template #footer v-if="!sending">
            <div class="flex mt-2 justify-content-end">
                <div>
                    <Button label="Voltar" icon="fas fa-angle-left" @click="showSendModal = false" autofocus v-if="!sent" />
                    <Button label="Enviar" icon="fa-solid fa-paper-plane" @click="sendNow = true" :disabled="!valid" v-if="!sent" autofocus />
                    <Button label="Finalizar" icon="fa-solid fa-check" iconPos="left" @click="showSendModal = false" autofocus v-if="sent" />
                </div>
            </div>
        </template>
    </Dialog>
    <Dialog
        v-model:visible="showErrorsModal"
        header="Falhas durante o processamento"
        :style="{ width: '40vw' }"
        :maximizable="false"
        :closable="true"
        :modal="true"
        :closeOnEscape="true"
        class="p-fluid">
        <ErrorModal :errors="serverProcessResponse.errorDescription" />
    </Dialog>
    <Dialog v-model:visible="filterModal.show" header="Filtro avançado" :modal="true" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '30vw' }" :maximizable="false">
        <FilterModal @filterSelection="MakeFilter($event)" :showFilters="filterModal.showFilters" :currentFilters="filterModal.selectedFilters" />
        <template #footer>
            <div class="flex flex-row justify-content-between">
                <Button label="Cancelar" iconPos="right" icon="pi pi-times" autofocus @click="CloseFilterModal()" class="w-12rem" />
                <Button label="Pesquisar" iconPos="right" icon="pi pi-search" autofocus @click="FilterSearch()" class="w-12rem" />
            </div>
        </template>
    </Dialog>
</template>
