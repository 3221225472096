import AxiosInstance from '@/services/AxiosInstance';

const envelopeEventsEndpoint = '/envelope-events';

/**
 * Class to interact with the Envelope Events API
 */
export default class EnvelopeEventRouter {
    /**
     * Searches for envelope events based on the provided filter parameters.
     *
     * @async
     * @param {Object} filterParams - The search criteria for envelope events.
     * @returns {Promise<Object>} - A promise that resolves to the search results.
     * @throws {Error} - Throws an error if the search fails.
     */
    async Search(filterParams) {
        try {
            const response = await AxiosInstance.get(`${envelopeEventsEndpoint}?search=${filterParams}`);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to search envelope events: ${error.message}`);
        }
    }

    /**
     * Retrieves all envelope events.
     *
     * @async
     * @returns {Promise<Object>} - A promise that resolves to the list of all envelope events.
     * @throws {Error} - Throws an error if retrieval fails.
     */
    async FindAll() {
        try {
            const response = await AxiosInstance.get(envelopeEventsEndpoint);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to retrieve envelope events: ${error.message}`);
        }
    }

    /**
     * Creates a new envelope event with the provided data.
     *
     * @async
     * @param {Object} payload - The data for the new envelope event.
     * @returns {Promise<Object>} - A promise that resolves to the created envelope event data.
     * @throws {Error} - Throws an error if creation fails.
     */
    async Create(payload) {
        try {
            const response = await AxiosInstance.post(envelopeEventsEndpoint, payload);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to create envelope event: ${error.message}`);
        }
    }
}
