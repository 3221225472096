import { UserInfo } from './StorageKeys';
import AuthRouter from '@/api/routes/Auth';

const AuthApi = new AuthRouter();

/**
 * Loads application data either from the API or from localStorage if available.
 *
 * @async
 * @function LoadApplicationData
 * @returns {Promise<Object>} Resolves with the user's application data including menus, logged-in username, and user info.
 */
export default async function LoadApplicationData() {
    const storedUserInfo = localStorage.getItem(UserInfo);

    if (!storedUserInfo) {
        const response = await AuthApi.AccountInfo();
        const userInfo = response.data.userInfo;

        // Extract and structure user settings
        const settings = {
            user: {
                username: userInfo?.username,
                telegramNotification: userInfo?.telegramNotification,
                person: {
                    name: userInfo?.person?.name,
                    email: userInfo?.person?.email,
                    phone: userInfo?.person?.phone,
                    birthDate: userInfo?.person?.birthDate
                },
                roles: {
                    role: userInfo.role.slug,
                    indexPage: userInfo.role.indexPage.action
                },
                menus: userInfo.role.menus
            }
        };

        localStorage.setItem(UserInfo, JSON.stringify(settings));

        return {
            menus: userInfo.role.menus,
            loggedInUsername: userInfo.username,
            userInfo
        };
    } else {
        const appCache = JSON.parse(storedUserInfo);

        return {
            loggedInUsername: appCache.user.username,
            userInfo: appCache.user
        };
    }
}
