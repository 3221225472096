import AxiosInstance from '@/services/AxiosInstance';

const envelopeEndpoint = '/envelopes';

/**
 * Class to interact with the Envelope API
 */
export default class EnvelopeRouter {
    /**
     * Searches for envelopes based on the provided filters.
     *
     * @async
     * @param {Object} filters - The search criteria for envelopes.
     * @returns {Promise<Object>} - A promise that resolves to the search results.
     * @throws {Error} - Throws an error if the search fails.
     */
    async Search(filters) {
        try {
            const response = await AxiosInstance.get(`${envelopeEndpoint}?search=${filters}`);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to search envelopes: ${error.message}`);
        }
    }

    /**
     * Finds envelopes by event.
     *
     * @async
     * @param {string} event - The event identifier for the search.
     * @returns {Promise<Object>} - A promise that resolves to the envelopes data.
     * @throws {Error} - Throws an error if the retrieval fails.
     */
    async FindByEvent(event) {
        try {
            const response = await AxiosInstance.get(`${envelopeEndpoint}?event=${event}`);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to find envelopes by event: ${error.message}`);
        }
    }

    /**
     * Retrieves a list of envelopes for the logged-in user based on filters.
     *
     * @async
     * @param {Object} filters - The search criteria for envelopes.
     * @returns {Promise<Object>} - A promise that resolves to the list of envelopes.
     * @throws {Error} - Throws an error if the retrieval fails.
     */
    async LoggedInList(filters) {
        try {
            const response = await AxiosInstance.get(`${envelopeEndpoint}/list?search=${filters}`);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to retrieve logged-in user's envelopes: ${error.message}`);
        }
    }

    /**
     * Creates a new envelope.
     *
     * @async
     * @param {Object} payload - The data for the new envelope.
     * @returns {Promise<Object>} - A promise that resolves to the created envelope data.
     * @throws {Error} - Throws an error if creation fails.
     */
    async Create(payload) {
        try {
            const response = await AxiosInstance.post(envelopeEndpoint, payload);
            return response?.data;
        } catch (error) {
            throw new Error(`Failed to create envelope: ${error.message}`);
        }
    }

    /**
     * Removes an envelope by its ID.
     *
     * @async
     * @param {number} id - The unique identifier of the envelope to remove.
     * @returns {Promise<Object>} - A promise that resolves when the envelope is removed.
     * @throws {Error} - Throws an error if removal fails.
     */
    async Remove(id) {
        try {
            const response = await AxiosInstance.delete(`${envelopeEndpoint}/${id}`);
            return response?.data;
        } catch (error) {
            throw new Error(`Failed to remove envelope: ${error.message}`);
        }
    }

    /**
     * Cancels an envelope with the provided data.
     *
     * @async
     * @param {Object} payload - The data for canceling the envelope.
     * @returns {Promise<Object>} - A promise that resolves when the envelope is canceled.
     * @throws {Error} - Throws an error if cancellation fails.
     */
    async Cancel(payload) {
        try {
            const response = await AxiosInstance.post(`${envelopeEndpoint}/cancel`, payload);
            return response?.data;
        } catch (error) {
            throw new Error(`Failed to cancel envelope: ${error.message}`);
        }
    }

    /**
     * Resends an envelope with the provided data.
     *
     * @async
     * @param {Object} payload - The data for resending the envelope.
     * @returns {Promise<Object>} - A promise that resolves when the envelope is resent.
     * @throws {Error} - Throws an error if resend fails.
     */
    async Resend(payload) {
        try {
            const response = await AxiosInstance.post(`${envelopeEndpoint}/resend`, payload);
            return response?.data;
        } catch (error) {
            throw new Error(`Failed to resend envelope: ${error.message}`);
        }
    }
}
