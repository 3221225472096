import AxiosInstance from '@/services/AxiosInstance';

const envelopeEventTypesEndpoint = '/envelope-event-types';

/**
 * Class to interact with the Envelope Event Types API
 */
export default class EnvelopeEventTypeRouter {
    /**
     * Retrieves all envelope event types.
     *
     * @async
     * @returns {Promise<Object>} - A promise that resolves to the list of all envelope event types.
     * @throws {Error} - Throws an error if retrieval fails.
     */
    async FindAll() {
        try {
            const response = await AxiosInstance.get(envelopeEventTypesEndpoint);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to retrieve envelope event types: ${error.message}`);
        }
    }

    /**
     * Retrieves an envelope event type by its ID.
     *
     * @async
     * @param {number} id - The unique identifier of the envelope event type.
     * @returns {Promise<Object>} - A promise that resolves to the envelope event type data.
     * @throws {Error} - Throws an error if retrieval fails.
     */
    async FindById(id) {
        try {
            const response = await AxiosInstance.get(`${envelopeEventTypesEndpoint}/id/${id}`);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to retrieve envelope event type by ID: ${error.message}`);
        }
    }

    /**
     * Retrieves an envelope event type by its slug.
     *
     * @async
     * @param {string} slug - The slug identifier of the envelope event type.
     * @returns {Promise<Object>} - A promise that resolves to the envelope event type data.
     * @throws {Error} - Throws an error if retrieval fails.
     */
    async FindBySlug(slug) {
        try {
            const response = await AxiosInstance.get(`${envelopeEventTypesEndpoint}/slug/${slug}`);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to retrieve envelope event type by slug: ${error.message}`);
        }
    }
}
