<script>
    import { FilterMatchMode } from 'primevue/api';
    import { defineComponent } from 'vue';
    import FilterModal from '../../Utils/Filter.vue';
    import ProgressModal from '../../Utils/Progress.vue';
    import PermissionModal from './PermissionModal.vue';
    import PermissionRouter from '@/api/routes/Permission';
    export default defineComponent({
        components: {
            FilterModal,
            ProgressModal,
            PermissionModal
        },
        data() {
            return {
                loading: false,
                pagination: {},
                pageRows: 10,
                filterModal: {
                    show: false,
                    showFilters: ['general'],
                    selectedFilters: {}
                },
                permissions: {
                    records: [],
                    totalRecords: 0,
                    selection: []
                },
                hasFilters: false,
                permissionModal: {
                    show: false,
                    save: false,
                    valid: false,
                    progress: false,
                    action: 'create',
                    title: null,
                    permission: {}
                },
                filters: {
                    name: {
                        value: '',
                        matchMode: 'contains'
                    }
                },
                PermissionApi: null
            };
        },
        created() {
            this.PermissionApi = new PermissionRouter();
            this.FilterInit();
        },
        mounted() {
            this.pageRows = this.$refs.dt.rows;
            this.LoadPermissions();
        },
        methods: {
            ShowPermissionModal(action, slot) {
                this.permissionModal.action = action;
                this.permissionModal.permission = action == 'update' ? { ...slot } : null;
                this.permissionModal.title = `${action == 'create' ? 'Cadastrar' : 'Atualizar'} permissão`;
                this.permissionModal.show = true;
            },
            Valid(event) {
                event ? (this.permissionModal.valid = true) : (this.permissionModal.valid = false);
            },
            PermissionModalResetState() {
                this.permissionModal.show = false;
                this.permissionModal.valid = false;
                this.permissionModal.save = false;
                this.permissionModal.permission = {};
                this.LoadPermissions();
            },
            PermissionsRemove() {
                this.$confirm.require({
                    header: 'Excluir',
                    message: 'Ao realizar a exclusão não será possível desfazer a operação, deseja continuar?',
                    icon: 'far fa-question-circle',
                    acceptLabel: 'Sim',
                    rejectLabel: 'Não',
                    acceptClass: 'p-button-danger',
                    accept: () => {
                        this.PermissionApi.Remove(
                            this.permissions.selection.map((p) => {
                                return p.ID;
                            })
                        )
                            .then(() => {
                                this.$toast.add({
                                    severity: 'success',
                                    summary: 'Sucesso',
                                    detail: 'Operação realizada com sucesso',
                                    life: 3000
                                });
                            })
                            .finally(this.LoadPermissions());
                    }
                });
            },
            LoadPermissions() {
                this.loading = true;
                setTimeout(() => {
                    this.PermissionApi.Search(JSON.stringify(this.pagination))
                        .then((resp) => {
                            this.permissions.records = resp.permissions;
                            this.permissions.totalRecords = resp.totalRecords;
                        })
                        .finally((this.loading = false));
                }, Math.random() * 1000 + 250);
            },
            CloseFilterModal() {
                this.filterModal.show = false;
            },
            ShowFilterModal() {
                this.filterModal.show = true;
            },
            MakeFilter(filter) {
                this.filterModal.selectedFilters = Object.assign({}, this.filterModal.selectedFilters, filter);
                this.filters.global = Object.assign({}, this.filters.global, filter);
            },
            ClearFilters() {
                this.filterModal.selectedFilters = [];
                this.FilterInit();
                this.hasFilters = false;
                this.OnFilter();
            },
            FilterSearch() {
                this.filterModal.show = false;
                this.OnFilter();
            },
            OnPage(event) {
                this.pagination = event;
                this.LoadPermissions();
            },
            OnFilter() {
                if (Object.keys(this.filterModal.selectedFilters).length > 0) {
                    this.hasFilters = true;
                }
                this.pagination.filters = this.filters;
                this.LoadPermissions();
            },
            FilterInit() {
                this.pagination = {
                    page: 1,
                    rows: this.pageRows
                };
                this.filters = {
                    global: {
                        value: null,
                        matchMode: FilterMatchMode.CONTAINS
                    }
                };
            }
        }
    });
</script>

<template>
    <Card>
        <template #title>
            <span class="font-light text-700 text-xl">Permissões</span>
        </template>
        <template #content>
            <Toolbar class="h-6rem">
                <template v-slot:start>
                    <div class="lg:mt-0 sm:mt-2 flex flex-row" style="gap: 0.5rem">
                        <Button
                            icon="fas fa-plus"
                            class="p-button-rounded p-button-success p-button-outlined p-button-raised"
                            @click="ShowPermissionModal('create')"
                            :disabled="loading"
                            title="Adicionar nova permissão" />
                        <Button
                            icon="fas fa-trash"
                            class="p-button-rounded p-button-danger p-button-outlined p-button-raised"
                            @click="PermissionsRemove()"
                            :disabled="permissions.selection.length == 0"
                            title="Excluir permissão selecionada" />
                    </div>
                </template>
                <template v-slot:end>
                    <div class="lg:mt-0 sm:mt-2 flex flex-row" style="gap: 0.5rem">
                        <Button
                            icon="fas fa-redo-alt"
                            class="p-button-rounded p-button-info p-button-outlined p-button-raised"
                            @click="LoadPermissions()"
                            :disabled="loading"
                            title="Atualizar lista" />
                        <div class="relative">
                            <Button
                                icon="fas fa-filter"
                                class="p-button p-button-rounded p-button-info p-button-outlined p-button-raised"
                                @click="ShowFilterModal()"
                                :disabled="loading"
                                title="Escolher filtros" />
                            <Button
                                v-if="hasFilters"
                                icon="fas fa-broom"
                                class="p-button-rounded p-button-raised clear-filter"
                                title="Limpar filtros"
                                @click="ClearFilters()"
                                :disabled="loading" />
                        </div>
                    </div>
                </template>
            </Toolbar>
        </template>
        <template #footer>
            <DataTable
                lazy
                ref="dt"
                dataKey="ID"
                :value="permissions.records"
                :paginator="true"
                :rows="10"
                :loading="loading"
                :totalRecords="permissions.totalRecords"
                :rowsPerPageOptions="[10, 20, 50, 100, 300]"
                @page="OnPage($event)"
                @filter="OnFilter($event)"
                @sort="onSort($event)"
                v-model:filters="filters"
                v-model:selection="permissions.selection"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} entradas"
                responsiveLayout="scroll"
                class="p-datatable-sm">
                <template #header />
                <Column selectionMode="multiple" headerStyle="width:.2%; min-width:1rem;" />
                <Column field="ID" header="ID" :sortable="false" headerStyle="width:.30%; min-width:2rem;">
                    <template #body="slotProps">
                        <span class="p-column-title">ID</span>
                        <span>{{ slotProps.data?.ID }}</span>
                    </template>
                </Column>
                <Column field="name" header="Nome" :sortable="false" headerStyle="width:5%; min-width:2rem;">
                    <template #body="slotProps">
                        <span class="p-column-title">Nome</span>
                        <span>{{ slotProps.data?.name }}</span>
                    </template>
                </Column>
                <Column field="method" header="Método" :sortable="false" headerStyle="width:1%; min-width:2rem;">
                    <template #body="slotProps">
                        <span class="p-column-title">Método</span>
                        <span>{{ slotProps.data?.method }}</span>
                    </template>
                </Column>
                <Column field="endpoint" header="Endpoint" :sortable="false" headerStyle="width:3%; min-width:2rem;">
                    <template #body="slotProps">
                        <span class="p-column-title">Endpoint</span>
                        <span>{{ slotProps.data?.path }}</span>
                    </template>
                </Column>
                <Column headerStyle="width: 2%; min-width:5rem;">
                    <template #body="slotProps">
                        <Button
                            icon="fas fa-pencil"
                            class="p-button-rounded p-button-sm p-button-warning p-button-outlined p-button-raised mr-2 action-button"
                            title="Editar pessoa"
                            @click="ShowPermissionModal('update', slotProps.data)" />
                    </template>
                </Column>
            </DataTable>
        </template>
    </Card>
    <Dialog
        v-model:visible="permissionModal.show"
        :header="!permissionModal.progress ? permissionModal.title : 'Aguarde...'"
        :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        :style="{ width: '30vw' }"
        :maximizable="false"
        :closable="!permissionModal.progress"
        @hide="PermissionModalResetState()">
        <permissionModal
            v-if="!permissionModal.progress"
            :permission="permissionModal.permission"
            :action="permissionModal.action"
            :save="permissionModal.save"
            @valid="Valid($event)"
            @progress="permissionModal.progress = $event"
            @close="permissionModal.show = false" />
        <ProgressModal v-if="permissionModal.progress" />
        <template #footer>
            <div class="flex flex-row justify-content-between" v-if="!permissionModal.progress">
                <Button label="Cancelar" iconPos="right" icon="pi pi-times" autofocus @click="permissionModal.show = false" class="w-12rem" />
                <Button
                    label="Salvar"
                    iconPos="right"
                    icon="pi pi-check"
                    autofocus
                    :disabled="!permissionModal.valid"
                    @click="permissionModal.save = true"
                    class="w-12rem" />
            </div>
        </template>
    </Dialog>
    <Dialog
        v-model:visible="filterModal.show"
        header="Filtro avançado"
        :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        :style="{ width: '30vw' }"
        :maximizable="false">
        <FilterModal @filterSelection="MakeFilter($event)" :showFilters="filterModal.showFilters" :currentFilters="filterModal.selectedFilters" />
        <template #footer>
            <div class="flex flex-row justify-content-between">
                <Button label="Cancelar" iconPos="right" icon="pi pi-times" autofocus @click="CloseFilterModal()" class="w-12rem" />
                <Button label="Pesquisar" iconPos="right" icon="pi pi-search" autofocus @click="FilterSearch()" class="w-12rem" />
            </div>
        </template>
    </Dialog>
</template>
