import AxiosInstance from '@/services/AxiosInstance';

const loginEndpoint = '/auth/login';
const logoutEndpoint = '/auth/logout';
const refreshEndpoint = '/auth/refresh';
const authenticatedUserInfo = '/auth/info';
const forgotPasswordEndpoint = '/auth/password/forgot';
const resetPasswordEndpoint = '/auth/password/reset';
const validateResetToken = '/auth/password/validate-rtk';

/**
 * Class to interact with the Authentication API
 */
export default class AuthRouter {
    /**
     * Logs in the user with the provided credentials.
     *
     * @async
     * @param {Object} payload - The login credentials (email, password).
     * @returns {Promise<Object>} - A promise that resolves to the authenticated user data.
     * @throws {Error} - Throws an error if the login fails.
     */
    async Login(payload) {
        try {
            const response = await AxiosInstance.post(loginEndpoint, payload);
            return response?.data;
        } catch (error) {
            throw new Error(`Failed to log in: ${error.message}`);
        }
    }

    /**
     * Checks if the provided reset token is valid.
     *
     * @async
     * @param {string} token - The password reset token.
     * @returns {Promise<Object>} - A promise that resolves to the token validation status.
     * @throws {Error} - Throws an error if token validation fails.
     */
    async CheckResetToken(token) {
        try {
            const response = await AxiosInstance.get(`${validateResetToken}/${token}`);
            return response?.data;
        } catch (error) {
            throw new Error(`Failed to validate reset token: ${error.message}`);
        }
    }

    /**
     * Sends a password reset email to the user.
     *
     * @async
     * @param {Object} payload - The email address for the password reset request.
     * @returns {Promise<Object>} - A promise that resolves to the status of the reset email.
     * @throws {Error} - Throws an error if the request fails.
     */
    async Forgot(payload) {
        try {
            const response = await AxiosInstance.post(forgotPasswordEndpoint, payload);
            return response?.data;
        } catch (error) {
            throw new Error(`Failed to send forgot password email: ${error.message}`);
        }
    }

    /**
     * Resets the user's password.
     *
     * @async
     * @param {Object} payload - The new password and token for resetting the password.
     * @returns {Promise<Object>} - A promise that resolves to the status of the password reset.
     * @throws {Error} - Throws an error if the reset fails.
     */
    async Reset(payload) {
        try {
            const response = await AxiosInstance.post(resetPasswordEndpoint, payload);
            return response?.data;
        } catch (error) {
            throw new Error(`Failed to reset password: ${error.message}`);
        }
    }

    /**
     * Logs out the user.
     *
     * @async
     * @returns {Promise<Object>} - A promise that resolves when the user is logged out.
     * @throws {Error} - Throws an error if logout fails.
     */
    async Logout() {
        try {
            const response = await AxiosInstance.get(logoutEndpoint);
            return response?.data;
        } catch (error) {
            throw new Error(`Failed to log out: ${error.message}`);
        }
    }

    /**
     * Refreshes the authentication token.
     *
     * @async
     * @param {Object} payload - The refresh token.
     * @returns {Promise<Object>} - A promise that resolves to the new authentication data.
     * @throws {Error} - Throws an error if refreshing the token fails.
     */
    async Refresh(payload) {
        try {
            const response = await AxiosInstance.post(refreshEndpoint, payload);
            return response?.data;
        } catch (error) {
            throw new Error(`Failed to refresh token: ${error.message}`);
        }
    }

    /**
     * Retrieves information about the authenticated user.
     *
     * @async
     * @returns {Promise<Object>} - A promise that resolves to the authenticated user's data.
     * @throws {Error} - Throws an error if fetching user info fails.
     */
    async AccountInfo() {
        try {
            const response = await AxiosInstance.get(authenticatedUserInfo);
            return response?.data;
        } catch (error) {
            throw new Error(`Failed to fetch authenticated user info: ${error.message}`);
        }
    }
}
