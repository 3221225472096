import AxiosInstance from '@/services/AxiosInstance';

const companiesEndpoint = '/companies';

/**
 * Class to interact with the Company API
 */
export default class CompanyRouter {
    /**
     * Searches for companies based on the provided filter parameters.
     *
     * @async
     * @param {Object} filterParams - The search criteria for companies.
     * @returns {Promise<Object>} - A promise that resolves to the search results.
     * @throws {Error} - Throws an error if the search fails.
     */
    async Search(filterParams) {
        try {
            const response = await AxiosInstance.get(`${companiesEndpoint}?search=${filterParams}`);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to search companies: ${error.message}`);
        }
    }

    /**
     * Retrieves all companies.
     *
     * @async
     * @returns {Promise<Object>} - A promise that resolves to the list of all companies.
     * @throws {Error} - Throws an error if retrieval fails.
     */
    async FindAll() {
        try {
            const response = await AxiosInstance.get(companiesEndpoint);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to retrieve companies: ${error.message}`);
        }
    }

    /**
     * Creates a new company with the provided data.
     *
     * @async
     * @param {Object} payload - The data for the new company.
     * @returns {Promise<Object>} - A promise that resolves to the created company data.
     * @throws {Error} - Throws an error if creation fails.
     */
    async Create(payload) {
        try {
            const response = await AxiosInstance.post(companiesEndpoint, payload);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to create company: ${error.message}`);
        }
    }

    /**
     * Updates an existing company with the provided data.
     *
     * @async
     * @param {Object} payload - The updated data for the company.
     * @param {number} id - The unique identifier of the company.
     * @returns {Promise<Object>} - A promise that resolves to the updated company data.
     * @throws {Error} - Throws an error if the update fails.
     */
    async Update(payload, id) {
        try {
            const response = await AxiosInstance.put(`${companiesEndpoint}/${id}`, payload);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to update company: ${error.message}`);
        }
    }

    /**
     * Removes one or more companies based on the provided payload.
     *
     * @async
     * @param {Object} payload - The data identifying the companies to remove.
     * @returns {Promise<Object>} - A promise that resolves when the removal is complete.
     * @throws {Error} - Throws an error if removal fails.
     */
    async Remove(payload) {
        try {
            const response = await AxiosInstance.delete(companiesEndpoint, { data: payload });
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to remove companies: ${error.message}`);
        }
    }
}
