import Token from './Token';

/**
 * Guard function to verify the validity of the user's authorization token.
 * 
 * This function checks if an authorization token is present and whether it has expired.
 * If the token has expired, it triggers a logout action and returns `false`.
 * If the token is valid, it returns `true`.
 * 
 * @function Guard
 * @returns {boolean} `true` if the token is valid, `false` if not or if an error occurs.
 */
export default function Guard(token) {
    try {        
        if (token) {
            const jwt = Token.jwtExpiration(token);
            return !(jwt.exp < Date.now() / 1000) 
        } 

        return false;
        
    } catch (error) {
        console.error('Error in Guard:', error);
        return false;  
    }
}
