<script>
    import { defineComponent } from 'vue';
    import { FilterMatchMode } from 'primevue/api';
    import AssessmentMetricRouter from '../../api/routes/AssessmentMetric';
    import AssessmentRouter from '../../api/routes/Assessment';
    import ProgressTemplate from '../Utils/Progress';
    import FilterTemplate from '../Utils/Filter';
    import InputOptions from '../Utils/InputOptions';

    /**
     * @module AssessmentTemplateComponent
     * @description Vue component responsible for managing assessment templates, including fetching, filtering
     * creating, updating and deleting assessment templates
     */
    export default defineComponent({
        components: {
            ProgressTemplate,
            InputOptions,
            FilterTemplate
        },
        data() {
            return {
                hasFilters: false,
                pageRows: 10,
                pagination: {},
                filters: {},
                filterModal: {
                    display: false,
                    displayFilters: ['general'],
                    selectedFilters: {}
                },
                apis: {
                    assessmentMetric: new AssessmentMetricRouter(),
                    assessment: new AssessmentRouter()
                },
                templates: {
                    loading: false,
                    records: [],
                    selection: [],
                    totalRecords: 0
                },
                assessmentTemplateModal: {
                    display: false,
                    slotData: null,
                    progress: false,
                    currentAction: 0,
                    actions: {
                        CREATE: 0,
                        UPDATE: 1
                    }
                },
                assessmentMetricShowColumns: [
                    { field: 'ID', header: 'ID', sortable: false },
                    { field: 'title', header: 'Titulo', sortable: false },
                    { field: 'description', header: 'Descrição', sortable: false }
                ],
                assessmentMetricAdditionalFilters: {
                    templateOnly: true
                },
                form: this.initForm()
            };
        },
        created() {
            this.initializeFilters();
        },
        async mounted() {
            this.pageRows = this.$refs.dt.rows;
            this.loadAssessmentTemplates();
        },
        methods: {
            /**
             * @function initForm
             * @description Initialize default form data
             * @returns {Object} - The form data initialized
             */
            initForm() {
                return {
                    title: '',
                    description: '',
                    active: true,
                    assessmentMetricTemplates: null
                };
            },
            /**
             * @function loadAssessmentTemplate
             * @description Fetched and updates assessment template data from the API
             * @returns {Promise<void>} Resolves once the assessment templates have been successfully loaded
             */
            async loadAssessmentTemplates() {
                this.templates.loading = true;
                try {
                    const jsonData = JSON.stringify(this.pagination);
                    const { assessments, totalRecords } = await this.apis.assessment.Search(jsonData);
                    this.templates.records = assessments || [];
                    this.templates.totalRecords = totalRecords || 0;
                } catch (err) {
                    console.error('Error loading assessment templates: ', err);
                } finally {
                    this.templates.loading = false;
                }
            },
            /**
             * @function displayAssessmentTemplateModal
             * @description Opens the modal for creating or updating a assessment template
             * @param {number} currentAction - Indicates whether the action is 'create' or 'update'
             * @param {Object} [slotData = null] - Optional data used to populate the form for editing
             */
            displayAssessmentTemplateModal(currentAction, slotData = null) {
                this.assessmentTemplateModal.currentAction = currentAction;

                this.form = this.initForm();
                if (currentAction === this.assessmentTemplateModal.actions.UPDATE && slotData !== null) {
                    this.form = { ...slotData };
                }

                this.assessmentTemplateModal.display = true;
            },
            /**
             * @function confirmDeleteAssessmentTemplates
             * @description Opens a confirmation dialog to delete the selected assessment templates
             */
            confirmDeleteAssessmentTemplates() {
                if (this.templates.selection?.length == 0) {
                    this.$toast.add({
                        severity: 'warning',
                        summary: 'Atenção',
                        detail: 'Você precisa selecionar ao menos 1 item para remoção'
                    });
                    return;
                }

                this.$confirm.require({
                    message: `Você esta prestes a excluir ${this.templates.selection?.length} registros. Deseja continuar?`,
                    header: 'Confirmar exclusão',
                    icon: 'far fa-question-circle',
                    acceptLabel: 'Sim',
                    rejectLabel: 'Não',
                    accept: async () => {
                        const idsToRemove = this.templates.selection?.map((template) => template.ID);
                        this.deleteItems(idsToRemove);
                    }
                });
            },
            /**
             * @function deleteItems
             * @description Delete assessment templates by their IDs
             * @param {Array<number>} ids - Array of assessment templates IDs to delete
             */
            async deleteItems(ids) {
                if (!ids || ids?.length === 0) return;

                try {
                    await this.apis.assessment.Remove(ids);
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Sucesso',
                        detail: 'Item(s) removido(s) com sucesso'
                    });
                } catch (err) {
                    console.error(err);
                } finally {
                    this.loadAssessmentTemplates();
                }
            },
            /**
             * @function saveForm
             * @description Saves the assessment template data (create or update)
             * @returns {Promise<Object>} Resolves once the save operation is completed
             */
            async saveForm() {
                this.assessmentTemplateModal.progress = true;
                try {
                    const { ID, payload, update } = this.getPayload();
                    const response = update ? await this.apis.assessment.UpdateTemplate(ID, payload) : await this.apis.assessment.CreateTemplate(payload);

                    this.$toast.add({
                        severity: 'success',
                        summary: 'Sucesso',
                        detail: 'Operação realizada com sucesso',
                        life: 3000
                    });

                    return Promise.resolve(response);
                } catch (err) {
                    console.error(err);
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro',
                        detail: 'Não foi possível concluir a operação.',
                        life: 5000
                    });
                    return Promise.reject(err);
                } finally {
                    this.assessmentTemplateModal.display = false;
                    this.loadAssessmentTemplates();
                }
            },
            /**
             * @function getPayload
             * @description Prepares the payload for creating or updating a assessment template
             * @returns {Object}
             */
            getPayload() {
                // eslint-disable-next-line no-unused-vars
                const { ID, assessmentMetrics, createdAt, updatedAt, isTemplate, ...rest } = this.form;
                const payload = {
                    ...rest,
                    assessmentMetricTemplatesIDs: assessmentMetrics?.map((item) => item.ID)
                };

                const update = this.assessmentTemplateModal.currentAction === this.assessmentTemplateModal.actions.UPDATE;
                return { ID, payload, update };
            },
            /**
             * @function onFilter
             * @description Applies selected filters and reloads the assessment templates data
             */
            onFilter() {
                if (Object.keys(this.filterModal.selectedFilters).length > 0) {
                    this.hasFilters = true;
                }
                this.pagination.filters = this.filters;
                this.loadAssessmentTemplates();
            },
            /**
             * @function onPage
             * @description Handles pagination events
             * @param {Object} - Event object containing pagination data.
             */
            onPage(event) {
                this.pagination = event;
                this.pagination['filters']['templateOnly'] = true;
                this.loadAssessmentTemplates();
            },
            /**
             * @function initializeFilters
             * @description Sets up the initial filter and pagination configuration
             */
            initializeFilters() {
                this.filters = {
                    templateOnly: true,
                    global: {
                        value: '',
                        matchMode: FilterMatchMode.CONTAINS
                    }
                };

                this.pagination = {
                    page: 1,
                    rows: this.pageRows,
                    filters: this.filters
                };
            },
            /**
             * @function clearAllFilters
             * @description Clear all filters
             */
            clearAllFilters() {
                this.filterModal.selectedFilters = {};
                this.initializeFilters();
                this.hasFilters = false;
                this.onFilter();
            },
            /**
             * @function makeFilter
             * @description Mount the filter from filter object
             * @param {Object} filter - The filter object to mount
             */
            makeFilter(filter) {
                this.filterModal.selectedFilters = {
                    ...this.filterModal.selectedFilters,
                    ...filter
                };

                this.filters.global = {
                    ...this.filters.global,
                    ...filter
                };
            },
            /**
             * @function showFilterModal
             * @description Display the filter modal
             */
            showFilterModal() {
                this.filterModal.display = false;
            },
            /**
             * @function filterSearch
             * @description Closes the filter modal and call onFilter function
             */
            filterSearch() {
                this.filterModal.display = false;
                this.onFilter();
            },
            /**
             * @function resetTemplateModalState
             * @description Resets the modal state and clears the form data
             */
            resetTemplateModalState() {
                this.assessmentTemplateModal.display = false;
                this.assessmentTemplateModal.progress = false;
                this.form = this.initForm();
            },
            /**
             * Controls input actions fot the form (set, clear or remove)
             * @param {string} entity - The form entity to modify
             * @param {string} action - The action to perform (set, clear or remove)
             * @param {object|null} [event=null] - The event data to apply to the form entity
             */
            inputController(entity, action, event = null) {
                if (!this.form[entity]) {
                    this.form[entity] = Array.isArray(event) ? [] : {};
                }

                switch (action) {
                    case 'set': {
                        if (!event || typeof event !== 'object') {
                            console.error(`Invalid event data for setting entry: ${entity}`);
                        }

                        this.form[entity] = event;
                        break;
                    }
                    case 'clear': {
                        if (typeof this.form[entity] === 'object') {
                            this.form[entity] = null;
                        } else {
                            console.error(`Cannot clear entity ${entity} with type ${typeof this.form[entity]}`);
                        }
                        break;
                    }
                    case 'remove': {
                        if (!Array.isArray(this.form[entity])) {
                            console.error(`Cannot remove item from non-array entity ${entity}`);
                            return;
                        }

                        if (event && event.ID) {
                            this.form[entity] = this.form[entity].filter((item) => item.ID !== event.ID);
                        } else {
                            console.error('Event must contain an ID for removal');
                        }
                        break;
                    }
                    default: {
                        console.error(`Invalid action: ${action}`);
                    }
                }
            }
        },
        computed: {
            /**
             * @function assessmentModalTitle
             * @description Computes the title for the assessment template modal based on the current action
             * @returns {string}
             */
            assessmentModalTitle() {
                const mainDlgTitle = 'modelo de avaliação';
                if (!this.assessmentTemplateModal.progress) {
                    return this.assessmentTemplateModal.currentAction === this.assessmentTemplateModal.actions.CREATE ? `Novo ${mainDlgTitle}` : `Editar ${mainDlgTitle}`;
                }

                return 'Salvando informações...';
            },
            /**
             * @function validateForm
             * @description Computes to validate form data input
             * @returns {bool}
             */
            validateForm() {
                const titleIsValid = Boolean(this.form?.title.trim().length > 0);
                return titleIsValid;
            }
        }
    });
</script>
<template>
    <Card>
        <!-- Title Section -->
        <template #title>
            <span class="font-light text-700 text-xl">Modelos Avaliativos</span>
        </template>

        <!-- Toolbar Section -->
        <template #content>
            <Toolbar class="h-6rem">
                <!-- Start Buttons (Add, Delete) -->
                <template #start>
                    <div class="lg:mt-0 sm:mt-2 flex flex-row" style="gap: 5px">
                        <Button
                            icon="fas fa-plus"
                            class="p-button-rounded p-button-success p-button-outlined p-button-raised"
                            title="Adicionar novo modelo de avaliação"
                            @click="displayAssessmentTemplateModal(assessmentTemplateModal.actions.CREATE)"
                            :disabled="loading" />
                        <Button
                            icon="fas fa-trash"
                            title="Excluir um ou mais itens"
                            class="p-button-rounded p-button-danger p-button-outlined p-button-raised"
                            @click="confirmDeleteAssessmentTemplates"
                            :disabled="!templates.selection.length" />
                    </div>
                </template>

                <!-- End Buttons (Refresh, Filter) -->
                <template #end>
                    <div class="lg:mt-0 sm:mt-2 flex flex-row" style="gap: 5px">
                        <Button
                            icon="fas fa-redo-alt"
                            class="p-button-rounded p-button-info p-button-outlined p-button-raised"
                            title="Atualizar lista"
                            @click="loadAssessmentTemplates"
                            :disabled="templates.loading" />
                        <div class="relative">
                            <Button
                                icon="fas fa-filter"
                                class="p-button p-button-rounded p-button-info p-button-outlined p-button-raised"
                                @click="filterModal.display = true"
                                :disabled="templates.loading"
                                title="Escolher filtros" />
                            <Button
                                v-if="hasFilters"
                                icon="fas fa-broom"
                                class="p-button-rounded p-button-raised clear-filter"
                                title="Limpar filtros"
                                @click="clearAllFilters"
                                :disabled="templates.loading" />
                        </div>
                    </div>
                </template>
            </Toolbar>
        </template>
        <!-- Data Table Section -->
        <template #footer>
            <DataTable
                lazy
                ref="dt"
                dataKey="ID"
                class="p-datatable-sm"
                responsiveLayout="scroll"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} registros"
                v-model:selection="templates.selection"
                v-model:filters="filters"
                @filter="onFilter"
                @page="onPage"
                :value="templates.records"
                :paginator="true"
                :loading="templates.loading"
                :totalRecords="templates.totalRecords"
                :rows="10"
                :rowsPerPageOptions="[10, 25, 50]">
                <Column selectionMode="multiple" headerStyle="width:.2%; min-width:1rem;" />
                <Column field="ID" header="ID" :sortable="false" headerStyle="width:.40%; min-width:2rem;" />
                <Column field="title" header="Titulo" :sortable="false" headerStyle="width:6%; min-width:2rem;" />
                <Column field="description" header="Descrição" :sortable="false" headerStyle="width:8%; min-width:2rem;" />
                <Column header="Métricas associadas" :sortable="false" headerStyle="width:2%; min-width:1rem;">
                    <template #body="slotProps">
                        <div class="flex justify-content-center">
                            <span class="text-lg font-bold">{{ slotProps.data?.assessmentMetrics?.length || 0 }}</span>
                        </div>
                    </template>
                </Column>
                <Column field="active" header="Estado" :sortable="false" headerStyle="width:2%; min-width:1rem;">
                    <template #body="slotProps">
                        <div class="flex align-items-start justify-content-start">
                            <Tag severity="success" v-if="slotProps.data.active">
                                <div class="flex align-items-center text-md" style="gap: 6px">
                                    <i class="fas fa-check" />
                                    <span>Ativo</span>
                                </div>
                            </Tag>
                            <Tag style="background-color: silver" v-else>
                                <div class="flex align-items-center text-md" style="gap: 6px">
                                    <i class="fas fa-times" />
                                    <span>Inativo</span>
                                </div>
                            </Tag>
                        </div>
                    </template>
                </Column>
                <Column headerStyle="width: 5%; min-width:5rem;">
                    <template #body="slotProps">
                        <Button
                            icon="fas fa-pencil"
                            class="p-button-outlined p-button-rounded p-button-sm p-button-warning p-button-raised action-button"
                            title="Editar modelo"
                            @click="displayAssessmentTemplateModal(assessmentTemplateModal.actions.UPDATE, slotProps.data)" />
                    </template>
                </Column>
            </DataTable>
        </template>
    </Card>
    <Dialog v-model:visible="filterModal.display" header="Filtro global" :modal="true" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '30vw' }" :maximizable="false">
        <FilterTemplate @filterSelection="makeFilter($event)" :showFilters="filterModal.displayFilters" :currentFilters="filterModal.selectedFilters" />
        <template #footer>
            <div class="flex flex-row justify-content-end">
                <Button label="Pesquisar" iconPos="left" icon="pi pi-search" autofocus @click="filterSearch()" class="w-10rem" />
                <Button label="Cancelar" iconPos="left" icon="pi pi-times" autofocus @click="filterModal.display = false" class="p-button-outlined p-button-danger w-10rem" />
            </div>
        </template>
    </Dialog>
    <Dialog
        v-model:visible="assessmentTemplateModal.display"
        :header="assessmentModalTitle"
        :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        :style="{ width: '35vw' }"
        :maximizable="true"
        :closable="!assessmentTemplateModal.progress"
        @hide="resetTemplateModalState">
        <ProgressTemplate v-if="assessmentTemplateModal.progress" />
        <div class="p-input-filled p-button-filled" v-if="!assessmentTemplateModal.progress">
            <div class="flex flex-column field">
                <label for="description">
                    <span class="text-danger">*</span>
                    Métricas:
                </label>
                <input-options
                    name="assessmentMetrics"
                    @selection="inputController('assessmentMetrics', 'set', $event)"
                    @unSelection="inputController('assessmentMetrics', 'remove', $event)"
                    @cleared="inputController('assessmentMetrics', 'clear')"
                    :additionalFilters="assessmentMetricAdditionalFilters"
                    :currentSelection="form.assessmentMetrics"
                    :multiselect="true"
                    :multiselectDisplayFieldName="'title'"
                    :showColumns="assessmentMetricShowColumns"
                    :searchAPI="apis.assessmentMetric"
                    :apiPropertyName="'assessmentMetrics'"
                    :inputPlaceholder="'Selecione os modelos de métricas da avaliação'"
                    :showSearch="true"
                    :showClear="form.assessmentMetrics != null"
                    :enableConfirmationButton="form.assessmentMetrics != null"
                    :lazyLoadDialogMaximizable="true"
                    :lazyLoadTextHeader="'Selecione os modelos de métricas da avaliação'" />
            </div>
            <div class="flex flex-column field">
                <label for="title">
                    <span class="text-danger">*</span>
                    Título:
                </label>

                <InputText id="title" v-model="form.title" placeholder="Titulo do modelo (Obrigatório)" class="w-full border-transparent" @input="validateForm" />
            </div>
            <div class="flex flex-column field">
                <label for="description">Descrição:</label>
                <InputText id="description" v-model="form.description" placeholder="Descrição do modelo de métrica" class="w-full border-transparent" />
            </div>
            <div class="text-lg w-full">
                <div class="flex flex-row justify-content-between align-items-start field">
                    <label for="metricStatus">Ativo/Inativo:</label>
                    <InputSwitch name="metricStatus" v-model="form.active" />
                </div>
            </div>
        </div>
        <template #footer>
            <div class="flex flex-row justify-content-end" v-if="!assessmentTemplateModal.progress">
                <Button label="Salvar" iconPos="left" icon="fas fa-save" autofocus class="w-10rem" :disabled="!validateForm" @click="saveForm()" />
                <Button autofocus label="Cancelar" iconPos="left" icon="pi pi-times" class="p-button-outlined p-button-danger w-10rem" @click="assessmentTemplateModal.display = false" />
            </div>
        </template>
    </Dialog>
</template>
