import AxiosInstance from '@/services/AxiosInstance';

const assessmentMetricTypesEndpoint = 'assessment-metric-types';

/**
 * Router class to interact with the Assessment Metric Types API.
 */
export default class AssessmentMetricTypeRouter {

    /**
     * Searches for assessment metric types based on the provided payload.
     *
     * @async
     * @param {Object} payload - The search criteria for assessment metric types.
     * @returns {Promise<Object>} - A promise that resolves to the search results.
     * @throws {Error} - Throws an error if the search request fails.
     */
    async Search(payload) {
        try {
            const response = await AxiosInstance.post(`${assessmentMetricTypesEndpoint}/search`, payload);
            return response.data?.data;
        } catch (error) {
            throw new Error(`Failed to search assessment metric types: ${error.message}`);
        }
    }

    /**
     * Creates a new assessment metric type.
     *
     * @async
     * @param {Object} payload - The data for the new assessment metric type.
     * @returns {Promise<Object>} - A promise that resolves to the created assessment metric type.
     * @throws {Error} - Throws an error if the creation request fails.
     */
    async Create(payload) {
        try {
            const response = await AxiosInstance.post(`${assessmentMetricTypesEndpoint}`, payload);
            return response.data;
        } catch (error) {
            throw new Error(`Failed to create assessment metric type: ${error.message}`);
        }
    }

    /**
     * Updates an existing assessment metric type by its ID.
     *
     * @async
     * @param {number} ID - The unique identifier of the assessment metric type.
     * @param {Object} payload - The updated data for the assessment metric type.
     * @returns {Promise<Object>} - A promise that resolves to the updated assessment metric type.
     * @throws {Error} - Throws an error if the update request fails.
     */
    async Update(ID, payload) {
        try {
            const response = await AxiosInstance.put(`${assessmentMetricTypesEndpoint}/${ID}`, payload);
            return response.data;
        } catch (error) {
            throw new Error(`Failed to update assessment metric type with ID ${ID}: ${error.message}`);
        }
    }

    /**
     * Removes one or more assessment metric types based on their IDs.
     *
     * @async
     * @param {Array<string>} ids - The list of unique identifiers for the assessment metric types to remove.
     * @returns {Promise<void>} - A promise that resolves when the deletion is complete.
     * @throws {Error} - Throws an error if the deletion request fails.
     */
    async Remove(ids) {
        try {
            await AxiosInstance.delete(assessmentMetricTypesEndpoint, { data: ids });
        } catch (error) {
            throw new Error(`Failed to remove assessment metric types: ${error.message}`);
        }
    }
}
