import axios from 'axios';
import https from 'https';

const BACKEND_HOST = process.env.VUE_APP_BACKEND_HOST;
const SSL_VERIFY = process.env.VUE_APP_SSL_VERIFY === 'true';  // Ensure SSL_VERIFY is a boolean

// HTTPS agent for handling SSL verification based on environment variable
const agent = new https.Agent({
    rejectUnauthorized: SSL_VERIFY,
});

// Base API URL
const API = `${BACKEND_HOST}/api/v1`;

/**
 * Axios instance configured with custom settings for interacting with the backend API.
 * 
 * @constant {AxiosInstance} AxiosInstance
 * @property {string} baseURL - Base URL for the API.
 * @property {Object} headers - Default headers for each request.
 * @property {https.Agent} httpAgent - Agent for HTTPS requests, with SSL verification configurable.
 * @property {string} responseType - Expected response type (set to 'json').
 */
const AxiosInstance = axios.create({
    baseURL: API,
    headers: {
        'Content-Type': 'application/json',
    },
    responseType: 'json',
    httpAgent: agent,  // Custom HTTPS agent for SSL verification handling
});

export default AxiosInstance;
