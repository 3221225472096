<script>
import { defineComponent } from "vue";
import moment from 'moment'
export default defineComponent({
  props: ["envelope", "envelopeEventsTypesList"],
  data() {
    return {
      events: [],
    };
  },
  async mounted() {
    await this.LoadTimeline()
  },
  methods: {
    async LoadTimeline() {
        this.envelope.envelopeEvents.map(m => {
            this.events.push({
                status: this.envelopeEventsTypesList.find(eet => eet.ID === m.envelopeEventTypeID).description,
                date: moment(m.createdAt).format("DD/MM/YYYY HH:MM:ss")
            })
        })
    }
  },
});
</script>

<template>
  <div class="mt-4 mb-2">
    <Timeline :value="events">
      <template #opposite="slotProps">
        <small class="text-700">{{ slotProps.item.date }}</small>
      </template>
      <template #content="slotProps">
        {{ slotProps.item.status }}
      </template>
    </Timeline>
  </div>
</template>
