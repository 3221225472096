import { AuthToken, RefreshToken, UserInfo } from './StorageKeys';

/**
 * Token management class for handling authorization and refresh tokens in localStorage.
 */
class Token {
    /**
     * Retrieves the refresh token from localStorage.
     * 
     * @returns {string|null} The refresh token or `null` if not found.
     */
    getRefreshToken() {
        return localStorage.getItem(RefreshToken);
    }

    /**
     * Retrieves the authorization token from localStorage.
     * 
     * @returns {string|null} The authorization token or `null` if not found.
     */
    getAuthorizationToken() {
        return localStorage.getItem(AuthToken);
    }

    /**
     * Stores the authorization token in localStorage.
     * 
     * @param {string} token - The authorization token to be stored.
     */
    setAuthorizationToken(token) {
        localStorage.setItem(AuthToken, token);
    }

    /**
     * Stores the refresh token in localStorage.
     * 
     * @param {string} refreshToken - The refresh token to be stored.
     */
    setRefreshToken(refreshToken) {
        localStorage.setItem(RefreshToken, refreshToken);
    }

    /**
     * Clears all token-related data (authorization token, refresh token, and user info) from localStorage.
     */
    clearLocalStorage() {
        localStorage.removeItem(AuthToken);
        localStorage.removeItem(RefreshToken);
        localStorage.removeItem(UserInfo);
    }

    /**
     * Decodes the JWT token and extracts the payload (i.e., token expiration and other details).
     * 
     * @param {string} token - The JWT token to decode.
     * @returns {Object} The decoded JWT payload.
     * @throws Will throw an error if the token is malformed or cannot be decoded.
     */
    jwtExpiration(token) {
        try {
            // Decode base64 encoded JWT payload
            const base64Payload = token.split('.')[1];
            const base64 = base64Payload.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(Buffer.from(base64, 'base64').toString('ascii').split('').map(c => {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            return JSON.parse(jsonPayload);
        } catch (error) {
            console.error('Failed to decode JWT token:', error);
            throw new Error('Invalid token format');
        }
    }
}

export default new Token();
