import AxiosInstance from '@/services/AxiosInstance';

const peopleEndpoint = '/people';

/**
 * Class to interact with the People API
 */
export default class PersonRouter {
    /**
     * Searches for people based on the provided filter parameters.
     *
     * @async
     * @param {Object} filterParams - The search criteria for people.
     * @returns {Promise<Object>} - A promise that resolves to the search results.
     * @throws {Error} - Throws an error if the search fails.
     */
    async Search(filterParams) {
        try {
            const response = await AxiosInstance.get(`${peopleEndpoint}?search=${filterParams}`);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to search people: ${error.message}`);
        }
    }

    /**
     * Retrieves all people.
     *
     * @async
     * @returns {Promise<Object>} - A promise that resolves to the list of all people.
     * @throws {Error} - Throws an error if retrieval fails.
     */
    async FindAll() {
        try {
            const response = await AxiosInstance.get(peopleEndpoint);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to retrieve people: ${error.message}`);
        }
    }

    /**
     * Creates a new person with the provided data.
     *
     * @async
     * @param {Object} payload - The data for the new person.
     * @returns {Promise<Object>} - A promise that resolves to the created person data.
     * @throws {Error} - Throws an error if creation fails.
     */
    async Create(payload) {
        try {
            const response = await AxiosInstance.post(peopleEndpoint, payload);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to create person: ${error.message}`);
        }
    }

    /**
     * Updates an existing person with the provided data.
     *
     * @async
     * @param {Object} payload - The updated data for the person.
     * @param {number} id - The unique identifier of the person.
     * @returns {Promise<Object>} - A promise that resolves to the updated person data.
     * @throws {Error} - Throws an error if the update fails.
     */
    async Update(payload, id) {
        try {
            const response = await AxiosInstance.put(`${peopleEndpoint}/${id}`, payload);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to update person: ${error.message}`);
        }
    }

    /**
     * Removes one or more people based on the provided data.
     *
     * @async
     * @param {Object} payload - The data identifying the people to remove.
     * @returns {Promise<Object>} - A promise that resolves when the removal is complete.
     * @throws {Error} - Throws an error if removal fails.
     */
    async Remove(payload) {
        try {
            const response = await AxiosInstance.delete(peopleEndpoint, { data: payload });
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to remove person(s): ${error.message}`);
        }
    }
}
