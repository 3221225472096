<script>
import { defineComponent } from 'vue';
import AppEventBus from '@/layout/composables/AppEventBus';
import Lottie from '../Utils/Lottie';
import Success from './../../layout/lotties/success2.json';
import Pending from './../../layout/lotties/pending.json';

import AuthRouter from '@/api/routes/Auth';

export default defineComponent({
    name: 'ChangePassword',
    inject: ['AxiosInstance'],
    components: {
        Lottie
    },
    data() {
        return {
            loading: false,
            resetSuccess: false,
            resetError: false,
            resetToken: null,
            passwordRequirementsRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#()\-/\\+<>&^~:;=%*?&])[A-Za-z\d@$!#()\-/\\+<>&^~:;=%*?&]{12,}$/,
            atLeastOneLowercase: /.*[a-z].*/,
            atLeastOneUppercase: /.*[A-Z].*/,
            atLeastOneDigit: /.*[\d].*/,
            atLeastOneSpecialChar: /.*[@$!#()\-/\\+<>&^~:;=%*?&].*/,
            minLength: /^(?=.*).{12,}$/,
            userChangePassword: {
                validPassword: false,
                newPassword: '',
                passwordConfirmation: '',
                suggestedPassword: '',
                logoutAllSessions: true
            },
            lottieOk: {
                animation: Success,
                height: 200,
                width: 350,
                speed: 0.6,
                loop: false
            },
            lottieError: {
                animation: Pending,
                height: 200,
                width: 350,
                speed: 0.6,
                loop: false
            },
            AuthApi: null
        };
    },
    created() {
        this.AuthApi = new AuthRouter();
    },
    mounted() {
        this.$emitter.on('error', this.EmitterError);
        this.SetLightTheme();
        this.ValidateGrantToken();
    },
    unmounted() {
        this.$emitter.off('error', this.EmitterError);
    },
    computed: {
        logoUrl() {
            return `images/logo.svg`;
        },
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        }
    },
    methods: {
        SetLightTheme() {
            AppEventBus.emit('theme-change', {
                theme: 'lara-light-blue',
                dark: false
            });
        },
        GetGrantUrlParameters() {
            return this.$router.currentRoute._value?.query?.rtk;
        },
        async ValidateGrantToken() {
            const grantToken = this.GetGrantUrlParameters();
            if (!grantToken) {
                this.RedirectToLogin();
                return;
            }

            try {
                await this.AuthApi.CheckResetToken(grantToken);
            } catch (e) {
                this.RedirectToLogin();
            }
        },
        SendResetPassword() {
            this.loading = true;
            setTimeout(async () => {
                try {
                    const payload = {
                        newPassword: this.userChangePassword.newPassword,
                        grantCode: this.GetGrantUrlParameters()
                    };

                    await this.AuthApi.Reset(payload);

                    this.resetSuccess = true;
                    this.loading = false;
                } catch (error) {
                    console.error(error);
                    this.resetError = true;
                    this.loading = false;
                }
            }, 1500);
        },
        RedirectToLogin() {
            this.$router.push('/auth/login');
        },
        GeneratePassword() {
            const length = 12;
            const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+';
            let password = '';

            while (!this.IsValidPassword(password)) {
                password = '';
                for (let i = 0; i < length; ++i) {
                    const randomIndex = Math.floor(Math.random() * charset.length);
                    password += charset[randomIndex];
                }
            }

            this.userChangePassword.suggestedPassword = password;
            this.userChangePassword.newPassword = password;

            this.ValidateChangePassword();
        },
        IsValidPassword(password) {
            return this.passwordRequirementsRegex.test(password);
        },
        ValidateChangePassword() {
            let valid = this.IsValidPassword(this.userChangePassword.newPassword) && this.userChangePassword.newPassword == this.userChangePassword.passwordConfirmation;
            valid ? (this.userChangePassword.validPassword = true) : (this.userChangePassword.validPassword = false);
        },
        EmitterError(event) {
            this.$toast.add(event);
        }
    }
});
</script>

<template>
    <div class="no-scroll-container">
        <div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden pg-background px-3">
            <div class="flex flex-column card py-6 px-4 shadow-3 justify-content-between responsive">
                <div class="surface-card flex flex-column px-2 justify-content-between">
                    <div class="flex flex-column justify-content-between mb-2">
                        <div class="text-left">
                            <img :src="logoUrl" alt="logo" height="40" />
                        </div>
                        <label class="font-medium text-bluegray-700 text-sm mt-1">Gestão de pessoas | Alterar senha</label>
                    </div>
                    <Divider />
                    <div>
                        <!-- form-recover-password -->
                        <div v-if="!resetSuccess && !resetError">
                            <div class="mb-3">
                                <div class="flex flex-column p-fluid w-full" style="gap: 5px">
                                    <label for="new_password" class="block text-700">Nova senha:</label>
                                    <span class="p-input-icon-left w-full">
                                        <i class="pi pi-key" />
                                        <Password id="new_password" class="p-inputtext-sm h-3rem mt-1" v-model="userChangePassword.newPassword" :feedback="true" promptLabel=" " :strongRegex="passwordRequirementsRegex" placeholder="Insira a nova senha" @input="ValidateChangePassword" toggleMask>
                                            <template #header>
                                                <h6>Siga os padrões abaixo para criar uma senha segura</h6>
                                            </template>
                                            <template #footer>
                                                <Divider />
                                                <p class="mt-2">Requisitos de senha</p>
                                                <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                                                    <li :class="{ 'text-green-600': atLeastOneLowercase.test(userChangePassword.newPassword), 'text-500': !atLeastOneLowercase.test(userChangePassword.newPassword) }">Pelo menos uma letra minúscula</li>
                                                    <li :class="{ 'text-green-600': atLeastOneUppercase.test(userChangePassword.newPassword), 'text-500': !atLeastOneUppercase.test(userChangePassword.newPassword) }">Pelo menos uma letra maiúscula</li>
                                                    <li :class="{ 'text-green-600': atLeastOneSpecialChar.test(userChangePassword.newPassword), 'text-500': !atLeastOneSpecialChar.test(userChangePassword.newPassword) }">Pelo menos um caractere especial</li>
                                                    <li :class="{ 'text-green-600': atLeastOneDigit.test(userChangePassword.newPassword), 'text-500': !atLeastOneDigit.test(userChangePassword.newPassword) }">Pelo menos um numérico</li>
                                                    <li :class="{ 'text-green-600': minLength.test(userChangePassword.newPassword), 'text-500': !minLength.test(userChangePassword.newPassword) }">Ao menos 12 caracteres</li>
                                                </ul>
                                                <h6 class="text-orange-500">Não é possível reutilizar senhas cadastradas anteriormente</h6>
                                            </template>
                                        </Password>
                                    </span>
                                </div>
                            </div>
                            <div class="mb-3">
                                <div class="flex flex-column p-fluid w-full" style="gap: 5px">
                                    <label for="recover_method" class="block text-700">Confirmar senha:</label>
                                    <span class="p-input-icon-left w-full">
                                        <i class="pi pi-key" />
                                        <Password id="confirm_new_password" class="p-inputtext-sm h-3rem mt-1" v-model="userChangePassword.passwordConfirmation" :feedback="false" placeholder="Reinsira a nova senha" @input="ValidateChangePassword" toggleMask />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="resetSuccess">
                            <div class="col-12">
                                <div class="grid flex flex-column align-items-center">
                                    <span class="font-bold text-3xl mb-2" style="color: #2bd994">Senha alterada</span>
                                    <span class="text-gray-600 text-sm mb-5">As novas sessões devem ser iniciadas usando a nova senha</span>
                                    <Lottie :lottieProp="this.lottieOk" />
                                </div>
                            </div>
                        </div>
                        <div v-else-if="resetError">
                            <div class="col-12">
                                <div class="grid flex flex-column align-items-center">
                                    <span class="font-bold text-3xl mb-2" style="color: #df3135">Erro ao alterar senha</span>
                                    <span class="text-gray-600 text-sm mb-2">Por favor entre em contato com o suporte e reinicie o processo</span>
                                    <Lottie :lottieProp="this.lottieError" />
                                    <div class="flex flex-column align-items-center text-center" style="gap: 5px">
                                        <span class="text-bluegray-600 text-sm">Certifique-se de não esta usando uma senha cadastrada anteriormente.</span>
                                        <span class="text-bluegray-600 text-sm">Caso necessário entre em contato com suporte.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- -->

                        <div class="flex flex-row" style="gap: 10px" v-if="!loading && !resetSuccess && !resetError">
                            <Button label="Sugerir senha" class="w-full p-3 w-full text-base p-button-raised p-button-outlined p-button-rounded" @click="GeneratePassword()"></Button>
                            <Button label="Concluir" type="submit" class="w-full p-3 w-full text-base p-button-raised p-button-success p-button-outlined p-button-rounded" @click="SendResetPassword()" :disabled="!userChangePassword.validPassword"></Button>
                        </div>
                        <div class="flex flex-row" style="gap: 10px" v-else-if="resetSuccess || resetError">
                            <div class="col-12 mt-5 text-center">
                                <i class="pi pi-fw pi-arrow-left text-blue-500 mr-2" style="vertical-align: center"></i>
                                <router-link to="/auth/login" class="text-blue-500">{{ resetError ? 'Voltar para o incio' : 'Fazer login' }}</router-link>
                            </div>
                        </div>
                        <div class="font-medium text-lg text-bluegray-800 mt-5" v-else-if="loading">
                            <div class="flex flex-row justify-content-center text-center" style="gap: 5px">
                                <i class="pi pi-spin pi-spinner text-2xl" />
                                <span>Processando...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer justify-content-center align-items-center">
            <span class="text-lg text-900 font-light mb-1">Copyright © 2024 | IR Tecnologia - Todos os direitos reservados</span>
            <span class="text-sm text-900 font-light mb-2">(S.O.G.E) - Sistemas Operacionais, Gerências e Estratégicos</span>
        </div>

        <Toast position="bottom-right" />
    </div>
</template>

<style scoped>
/* default */
.responsive {
    width: 32rem;
    border-radius: 20px !important;
}

/* mobile small */
@media screen and (max-width: 320px) {
    .responsive {
        width: 20rem;
    }
}

/* mobile medium */
@media screen and (max-width: 480px) {
    .responsive {
        width: 30rem;
    }
}

.no-scroll-container {
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: transparent;
    text-align: center;
    padding: 10px 0;
}

.footer span {
    display: block;
    text-align: center;
}
</style>
