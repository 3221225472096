import AxiosInstance from '@/services/AxiosInstance';

const personTypesEndpoint = '/person-types';

/**
 * Class to interact with the Person Types API
 */
export default class PersonTypesRouter {
    /**
     * Retrieves all person types.
     *
     * @async
     * @returns {Promise<Object>} - A promise that resolves to the list of all person types.
     * @throws {Error} - Throws an error if retrieval fails.
     */
    async FindAll() {
        try {
            const response = await AxiosInstance.get(personTypesEndpoint);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to retrieve person types: ${error.message}`);
        }
    }
}
