<script>
    import { defineComponent } from 'vue';
    import moment from 'moment';
    import InputOptions from './InputOptions.vue';

    export default defineComponent({
        /**
         * @emits selectedFilters - Event emitted when filters are selected.
         */
        emits: ['appliedFilters'],

        /**
         * @props
         * @property {Array} filtersToDisplay - The available filters to be displayed.
         * @property {Object} selectedFilters - The current state of applied filters.
         */
        props: {
            filtersToDisplay: Array,
            selectedFilters: Object
        },
        /**
         * @components
         * @property {Object} InputOptions - A custom component that manages input options for selection.
         */
        components: {
            InputOptions
        },
        data() {
            return {
                /**
                 * @property {Object} filter - The filter object initialized via `appendFilter()`.
                 * @property {Object} filterTypes - Enum for the filter types.
                 */
                filter: {},
                filterTypes: {
                    OneSelectDynamicApiFilterType: 'one-select-dynamic-api',
                    MultiSelectDynamicApiFilterType: 'multi-select-dynamic-api',
                    OneSelectStaticFilterType: 'one-select-static',
                    MultiSelectStaticFilterType: 'multi-select-static',
                    CalendarFilterType: 'calendar',
                    GlobalFilterType: 'global'
                }
                // filterStaticOptions: {
                //     punch: [
                //         { label: 'All', value: 'all' },
                //         { label: 'Pending only', value: 'pending' }
                //     ],
                //     envelopeStatus: [
                //         { label: 'Created', value: 'created' },
                //         { label: 'Sent', value: 'sent' },
                //         { label: 'Queued', value: 'wait' },
                //         { label: 'Failed', value: 'error' }
                //     ],
                //     scheduled: [
                //         { label: 'All', value: 'all' },
                //         { label: 'Scheduled only', value: 'scheduled' },
                //         { label: 'Not scheduled', value: 'not_scheduled' }
                //     ],
                //     userStatus: [
                //         { label: 'All', value: 'all' },
                //         { label: 'Active only', value: 'enabled' },
                //         { label: 'Inactive only', value: 'disabled' }
                //     ]
                // }
            };
        },

        mounted() {
            this.digestCurrentFilters();
        },

        methods: {
            /**
             * @function appendFilter
             * @description Adds or updates filter properties (selection or records) in the filter list.
             * If the filter object with the given name does not exist, it will be created.
             *
             * @param {String} name - The key representing the filter in the filter object. Must be a valid string.
             * @param {Any} [selection=null] - The selected filter values. If provided, this will update or set the `selection` property for the filter.
             * @param {Array} [records=null] - A list of options fetched from the server. If provided, this will update or set the `records` property for the filter.
             *
             * @throws {Error} Will throw an error if the `name` parameter is missing or not a valid string.
             */
            appendFilter(name, selection = null, records = null) {
                if (!name || typeof name !== 'string') {
                    throw new Error('A valid key for the filter object must be provided.');
                }

                if (!this.filter[name]) {
                    this.filter[name] = {};
                }

                if (selection !== null) {
                    this.filter[name].selection = selection;
                }

                if (records !== null) {
                    this.filter[name].records = records;
                }
            },

            /**
             * Controls input actions for the filter (set, clear or remove).
             *
             * @param {String} entity - The filter entity to modify.
             * @param {String} action - The action to perform (set, clear or remove).
             * @param {Object|null} [event=null] - The event data to apply to the filter entity.
             * @param {String|null} [dataKey=null] - The data key to return to the caller.
             */
            inputController(entity, action, event = null, dataKey = null) {
                const entityExists = !!this.filter[entity];
                if (!entityExists) {
                    this.filter[entity] = Array.isArray(event) ? [] : {};
                }

                switch (action) {
                    case 'set':
                        if (!event || typeof event !== 'object') {
                            console.error(`Invalid event data for setting entry: ${entity}`);
                        } else {
                            this.filter[entity] = event;
                        }

                        break;

                    case 'clear':
                        this.filter[entity] = null;
                        break;

                    case 'remove':
                        if (Array.isArray(this.filter[entity]) && event?.ID) {
                            this.filter[entity] = this.filter[entity].filter((item) => item.ID !== event.ID);
                        } else {
                            console.error(`Error removing item from entity: ${entity}`);
                        }
                        break;

                    default:
                        console.error(`Invalid action: ${action}`);
                }

                this.emitFilterSelection(entity, this.filter[entity], dataKey);
            },

            /**
             * @function digestCurrentFilters
             * @description Digest current filter selection and initialize the filter state.
             */
            digestCurrentFilters() {
                if (this.selectedFilters == null) return;

                Object.keys(this.selectedFilters).forEach((key) => {
                    const value = this.selectedFilters[key];
                    this.appendFilter(key, value);
                });
            },

            /**
             * @function emitFilterSelection
             * @description Emits the filter selection event.
             * @param {string} filterName - The name of the filter.
             * @param {Object} filter - The selected filter object.
             * @param {Object} dataKey - The data key to return to the caller.
             */
            emitFilterSelection(filterName, filter, dataKey = null) {
                this.$emit('appliedFilters', { name: filterName, value: filter, dataKey: dataKey });
            },

            /**
             * @function formatDate
             * @description Formats a date according to the specified layout.
             * @param {string} layout - The date format.
             * @param {string|Date} dates - The date string or array to format
             * @returns {string|Array} - The formatted date.
             */
            formatDate(layout, dates) {
                if (Array.isArray(dates)) {
                    return dates.map((date) => moment(date).format(layout));
                }

                return moment(dates).format(layout);
            }
        }
    });
</script>

<template>
    <div class="p-input-filled p-button-filled p-fluid">
        <div v-for="filterToDisplay in filtersToDisplay" :key="filterToDisplay.name" class="field">
            <label :for="filterToDisplay.name">{{ filterToDisplay.label }}</label>

            <InputText
                v-if="filterToDisplay.type === filterTypes.GlobalFilterType"
                :name="filterToDisplay.name"
                :placeholder="filterToDisplay.inputPlaceholder"
                v-model="filter[filterToDisplay.name]"
                @blur="emitFilterSelection(filterToDisplay.name, { value: filter[filterToDisplay.name], matchMode: filterToDisplay.matchMode })" />

            <InputOptions
                v-if="filterToDisplay.type === filterTypes.OneSelectDynamicApiFilterType || filterToDisplay.type === filterTypes.MultiSelectDynamicApiFilterType"
                @selection="inputController(filterToDisplay.name, 'set', $event, filterToDisplay.dataKey)"
                @unSelection="inputController(filterToDisplay.name, 'remove', $event, filterToDisplay.dataKey)"
                @cleared="inputController(filterToDisplay.name, 'clear', filterToDisplay.dataKey)"
                :name="filterToDisplay.name"
                :additionalFilters="filterToDisplay.additionalFilters"
                :currentSelection="filter[filterToDisplay.name]"
                :multiselect="filterToDisplay.type === filterTypes.MultiSelectDynamicApiFilterType"
                :multiselectDisplayFieldName="filterToDisplay.multiselectDisplayFieldName"
                :showColumns="filterToDisplay.multiSelectShowColumns"
                :searchAPI="filterToDisplay.searchApi"
                :apiPropertyName="filterToDisplay.searchApiPropertyName"
                :inputPlaceholder="filterToDisplay.inputPlaceholder"
                :showSearch="filterToDisplay.showSearch"
                :showClear="filter[filterToDisplay.name] != null"
                :enableConfirmationButton="filter[filterToDisplay.name] != null"
                :lazyLoadDialogMaximizable="true"
                :lazyLoadTextHeader="filterToDisplay.lazyLoadTextHeader" />

            <Dropdown
                v-if="filterToDisplay.options && filterToDisplay.type === filterTypes.OneSelectStaticFilterType"
                v-model="filter[filterToDisplay.name]"
                emptyMessage="Sem resultados"
                emptyFilterMessage="Sem resultados"
                :name="filterToDisplay[name]"
                :optionLabel="filterToDisplay.optionLabel || 'label'"
                :optionValue="filterToDisplay.optionValue || 'value'"
                :placeholder="filterToDisplay.inputPlaceholder"
                :options="filterToDisplay.options"
                :filter="true"
                @change="emitFilterSelection(filterToDisplay.name, filter[filterToDisplay.name], filterToDisplay.dataKey)" />

            <MultiSelect
                v-if="filterToDisplay.name && filterToDisplay.type === filterTypes.MultiSelectStaticFilterType"
                v-model="filter[filterToDisplay.name]"
                emptyMessage="Sem resultados"
                emptyFilterMessage="Sem resultados"
                :name="filterToDisplay[name]"
                :optionLabel="filterToDisplay.optionLabel || 'label'"
                :optionValue="filterToDisplay.optionValue || 'value'"
                :placeholder="filterToDisplay.inputPlaceholder"
                :options="filterToDisplay.options"
                :filter="true"
                @change="emitFilterSelection(filterToDisplay.name, filter[filterToDisplay.name], filterToDisplay.dataKey)" />

            <Calendar
                v-if="filterToDisplay.type === filterTypes.CalendarFilterType"
                v-model="filter[filterToDisplay.name]"
                :showButtonBar="true"
                :name="filterToDisplay.name"
                :showTime="false"
                :selectionMode="filterToDisplay.selectionMode || 'single'"
                :placeholder="filterToDisplay.inputPlaceholder"
                :manualInput="filterToDisplay.manualInput || false"
                :showIcon="true"
                @hide="emitFilterSelection(filterToDisplay.name, formatDate('YYYY-MM-DD', filter[filterToDisplay.name]))" />
        </div>
    </div>
</template>
