import AxiosInstance from '@/services/AxiosInstance';

const collaboratorEndpoint = '/collaborators';
const collaboratorPunches = `${collaboratorEndpoint}/punches`;
const collaboratorPunchesSync = `${collaboratorEndpoint}/punches/sync`;

/**
 * Class to interact with the Collaborator API
 */
export default class CollaboratorRouter {
    /**
     * Searches for collaborators based on the provided filter parameters.
     *
     * @async
     * @param {Object} filterParams - The search criteria for collaborators.
     * @returns {Promise<Object>} - A promise that resolves to the search results.
     * @throws {Error} - Throws an error if the search fails.
     */
    async Search(filterParams) {
        try {
            const response = await AxiosInstance.get(`${collaboratorEndpoint}?search=${filterParams}`);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to search collaborators: ${error.message}`);
        }
    }

    /**
     * Searches for collaborators with pending punches based on filter parameters.
     *
     * @async
     * @param {Object} filterParams - The search criteria for collaborators.
     * @returns {Promise<Object>} - A promise that resolves to the search results.
     * @throws {Error} - Throws an error if the search fails.
     */
    async SearchWithPendingPunches(filterParams) {
        try {
            const response = await AxiosInstance.get(`${collaboratorPunches}/pendencies?search=${filterParams}`);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to search collaborators with pending punches: ${error.message}`);
        }
    }

    /**
     * Retrieves all collaborators.
     *
     * @async
     * @returns {Promise<Object>} - A promise that resolves to the list of all collaborators.
     * @throws {Error} - Throws an error if retrieval fails.
     */
    async FindAll() {
        try {
            const response = await AxiosInstance.get(collaboratorEndpoint);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to retrieve all collaborators: ${error.message}`);
        }
    }

    /**
     * Creates a new collaborator.
     *
     * @async
     * @param {Object} payload - The data for the new collaborator.
     * @returns {Promise<Object>} - A promise that resolves to the created collaborator data.
     * @throws {Error} - Throws an error if creation fails.
     */
    async Create(payload) {
        try {
            const response = await AxiosInstance.post(collaboratorEndpoint, payload);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to create collaborator: ${error.message}`);
        }
    }

    /**
     * Updates an existing collaborator by ID.
     *
     * @async
     * @param {Object} payload - The updated data for the collaborator.
     * @param {number} id - The unique identifier of the collaborator.
     * @returns {Promise<Object>} - A promise that resolves to the updated collaborator data.
     * @throws {Error} - Throws an error if the update fails.
     */
    async Update(payload, id) {
        try {
            const response = await AxiosInstance.put(`${collaboratorEndpoint}/${id}`, payload);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to update collaborator: ${error.message}`);
        }
    }

    /**
     * Removes one or more collaborators.
     *
     * @async
     * @param {Object} payload - The data identifying the collaborators to remove.
     * @returns {Promise<Object>} - A promise that resolves when the removal is complete.
     * @throws {Error} - Throws an error if the removal fails.
     */
    async Remove(payload) {
        try {
            const response = await AxiosInstance.delete(collaboratorEndpoint, { data: payload });
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to remove collaborators: ${error.message}`);
        }
    }

    /**
     * Finds punch pendencies for the authenticated user.
     *
     * @async
     * @returns {Promise<Object>} - A promise that resolves to the punch pendencies data.
     * @throws {Error} - Throws an error if the retrieval fails.
     */
    async FindPunchPendencies() {
        try {
            const response = await AxiosInstance.get(`${collaboratorPunches}/user-pendencies`);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to find punch pendencies: ${error.message}`);
        }
    }

    /**
     * Synchronizes punches for the given collaborator IDs.
     *
     * @async
     * @param {Array<number>} collaboratorIDs - The list of collaborator IDs to sync punches for.
     * @returns {Promise<Object>} - A promise that resolves to the synchronization data.
     * @throws {Error} - Throws an error if synchronization fails.
     */
    async SyncPunches(collaboratorIDs = []) {
        try {
            const response = await AxiosInstance.post(collaboratorPunchesSync, collaboratorIDs);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to sync punches: ${error.message}`);
        }
    }

    /**
     * Checks the synchronization status of punches.
     *
     * @async
     * @returns {Promise<Object>} - A promise that resolves to the sync status data.
     * @throws {Error} - Throws an error if status check fails.
     */
    async CheckSyncStatus() {
        try {
            const response = await AxiosInstance.get(`${collaboratorPunchesSync}/status`);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to check sync status: ${error.message}`);
        }
    }
}
