import AxiosInstance from '@/services/AxiosInstance';

const assessmentMetricOptionsEndpoint = 'assessment-metric-options';

/**
 * Class responsible for interacting with the Assessment Metric Options API.
 */
export default class AssessmentMetricOptionsRouter {

    /**
     * Searches for assessment metric options based on the provided search criteria.
     *
     * @async
     * @param {Object} payload - The search criteria for assessment metric options.
     * @returns {Promise<Object>} - A promise that resolves to the search results.
     * @throws {Error} - Throws an error if the search request fails.
     */
    async Search(payload) {
        try {
            const response = await AxiosInstance.post(`${assessmentMetricOptionsEndpoint}/search`, payload);
            return response.data?.data;
        } catch (error) {
            throw new Error(`Failed to search assessment metric options: ${error.message}`);
        }
    }

    /**
     * Creates a new assessment metric option.
     *
     * @async
     * @param {Object} payload - The data for the new assessment metric option.
     * @returns {Promise<Object>} - A promise that resolves to the created assessment metric option.
     * @throws {Error} - Throws an error if the creation request fails.
     */
    async Create(payload) {
        try {
            const response = await AxiosInstance.post(`${assessmentMetricOptionsEndpoint}`, payload);
            return response.data;
        } catch (error) {
            throw new Error(`Failed to create assessment metric option: ${error.message}`);
        }
    }

    /**
     * Updates an existing assessment metric option by its ID.
     *
     * @async
     * @param {number} ID - The unique identifier of the assessment metric option.
     * @param {Object} payload - The updated data for the assessment metric option.
     * @returns {Promise<Object>} - A promise that resolves to the updated assessment metric option.
     * @throws {Error} - Throws an error if the update request fails.
     */
    async Update(ID, payload) {
        try {
            const response = await AxiosInstance.put(`${assessmentMetricOptionsEndpoint}/${ID}`, payload);
            return response.data;
        } catch (error) {
            throw new Error(`Failed to update assessment metric option with ID ${ID}: ${error.message}`);
        }
    }

    /**
     * Removes one or more assessment metric options based on their IDs.
     *
     * @async
     * @param {Array<string>} ids - The list of unique identifiers for the assessment metric options to remove.
     * @returns {Promise<void>} - A promise that resolves when the deletion is complete.
     * @throws {Error} - Throws an error if the deletion request fails.
     */
    async Remove(ids) {
        try {
            await AxiosInstance.delete(assessmentMetricOptionsEndpoint, { data: ids });
        } catch (error) {
            throw new Error(`Failed to remove assessment metric options: ${error.message}`);
        }
    }
}
