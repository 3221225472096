<script>
    import { defineComponent } from 'vue';
    import AssessmentMetricGroupRouter from '../../../api/routes/AssessmentMetricGroup';
    import { FilterMatchMode } from 'primevue/api';
    import ProgressTemplate from '../../Utils/Progress';
    import FilterTemplate from '../../Utils/Filter.vue';

    /**
     * @module MetricGroupComponent
     * @description Vue component responsible for managing assessment metric groups, including fetching, filtering,
     * creating, updating, and deleting metric groups.
     */
    export default defineComponent({
        components: {
            ProgressTemplate,
            FilterTemplate
        },
        data() {
            return {
                hasFilters: false,
                pageRows: 10,
                pagination: {},
                filterModal: {
                    display: false,
                    displayFilters: ['general'],
                    selectedFilters: {}
                },
                filters: {},
                apis: {
                    assessmentMetricGroups: new AssessmentMetricGroupRouter()
                },
                groups: {
                    loading: false,
                    records: [],
                    selection: [],
                    totalRecords: 0
                },
                groupMetricModal: {
                    slotData: null,
                    display: false,
                    progress: false,
                    currentAction: 0,
                    actions: {
                        CREATE: 0,
                        UPDATE: 1
                    }
                },
                form: {
                    ID: null,
                    name: '',
                    description: ''
                }
            };
        },
        created() {
            this.initializeFilters();
        },
        mounted() {
            this.pageRows = this.$refs.dt.rows;
            this.loadMetricGroups();
        },
        methods: {
            /**
             * @function loadMetricGroups
             * @description Fetches and updates metric group data from the API.
             * @returns {Promise<void>} Resolves once the metric groups have been successfully loaded.
             */
            async loadMetricGroups() {
                this.groups.loading = true;
                try {
                    const jsonData = JSON.stringify(this.pagination);
                    const { assessmentMetricGroups, totalRecords } = await this.apis.assessmentMetricGroups.Search(jsonData);
                    this.groups.records = assessmentMetricGroups || [];
                    this.groups.totalRecords = totalRecords || 0;
                } catch (err) {
                    console.error('Error loading metric groups:', err);
                } finally {
                    this.groups.loading = false;
                }
            },
            /**
             * @function displayGroupMetricModal
             * @description Opens the modal for creating or updating a metric group.
             * @param {number} currentAction - Indicates whether the action is 'create' or 'update'.
             * @param {...Object} [slotData] - Optional data used to populate the form for editing.
             */
            displayGroupMetricModal(currentAction, ...slotData) {
                this.groupMetricModal.currentAction = currentAction;
                if (slotData.length > 0) {
                    this.form = { ...slotData[0] };
                }
                this.groupMetricModal.display = true;
            },
            /**
             * @function confirmDeleteGroupMetrics
             * @description Opens a confirmation dialog to delete the selected metric groups.
             */
            confirmDeleteGroupMetrics() {
                if (this.groups.selection.length === 0) {
                    this.$toast.add({
                        severity: 'warning',
                        summary: 'Atenção',
                        detail: 'Você precisa selecionar pelo menos 1 item.'
                    });
                    return;
                }

                this.$confirm.require({
                    message: `Você está prestes a excluir ${this.groups.selection.length} registros. Deseja continuar?`,
                    header: 'Confirmar exclusão',
                    icon: 'far fa-question-circle',
                    acceptLabel: 'Sim',
                    rejectLabel: 'Não',
                    accept: () => {
                        const idsToRemove = this.groups.selection.map((group) => group.ID);
                        this.deleteItems(idsToRemove);
                    }
                });
            },
            /**
             * @function deleteItems
             * @description Deletes metric groups by their IDs.
             * @param {Array<string>} ids - Array of metric group IDs to delete.
             */
            async deleteItems(ids) {
                if (!ids || ids.length === 0) return;
                try {
                    await this.apis.assessmentMetricGroups.Remove(ids);
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Sucesso',
                        detail: 'O registro foi removido'
                    });
                } catch (err) {
                    console.error('Error deleting metric groups:', err);
                } finally {
                    this.loadMetricGroups();
                }
            },
            /**
             * @function saveForm
             * @description Saves the current metric group data (create or update).
             * @returns {Promise<void>} Resolves once the save operation is completed.
             */
            async saveForm() {
                this.groupMetricModal.progress = true;
                try {
                    const { ID, payload, update } = this.getPayload();
                    const response = update ? await this.apis.assessmentMetricGroups.Update(ID, payload) : await this.apis.assessmentMetricGroups.Create(payload);

                    this.$toast.add({
                        severity: 'success',
                        summary: 'Sucesso',
                        detail: 'Operação realizada com sucesso',
                        life: 3000
                    });

                    return response;
                } catch (err) {
                    console.error('Unable to save metric groups:', err);
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro',
                        detail: 'Não foi possível concluir a operação.',
                        life: 5000
                    });
                } finally {
                    this.groupMetricModal.display = false;
                    this.loadMetricGroups();
                }
            },
            /**
             * @function getPayload
             * @description Prepares the payload for creating or updating a metric group.
             * @returns {Object} Contains the form data and update status.
             */
            getPayload() {
                const payload = { ...this.form };
                const ID = this.form.ID;
                const update = this.groupMetricModal.currentAction === this.groupMetricModal.actions.UPDATE;
                return { ID, payload, update };
            },
            /**
             * @function onFilter
             * @description Applies selected filters and reloads the metric group data.
             */
            onFilter() {
                if (Object.keys(this.filterModal.selectedFilters).length > 0) {
                    this.hasFilters = true;
                }
                this.pagination.filters = this.filters;
                this.loadMetricGroups();
            },
            /**
             * @function onPage
             * @description Handles pagination events.
             * @param {Object} event - Event object containing pagination data.
             */
            onPage(event) {
                this.pagination = event;
                this.loadMetricGroups();
            },
            /**
             * @function initializeFilters
             * @description Sets up the initial filter and pagination configuration.
             */
            initializeFilters() {
                this.pagination = {
                    page: 1,
                    rows: this.pageRows
                };
                this.filters = {
                    global: {
                        value: '',
                        matchMode: FilterMatchMode.CONTAINS
                    }
                };
            },
            clearAllFilters() {
                this.filterModal.selectedFilters = {};
                this.initializeFilters();
                this.hasFilters = false;
                this.onFilter();
            },
            makeFilter(filter) {
                this.filterModal.selectedFilters = {
                    ...this.filterModal.selectedFilters,
                    ...filter
                };
                this.filters.global = {
                    ...this.filters.global,
                    ...filter
                };
            },
            showFilterModal() {
                this.filterModal.display = true;
            },
            filterSearch() {
                this.filterModal.display = false;
                this.onFilter();
            },
            /**
             * @function resetGroupModalState
             * @description Resets the modal state and clears the form data.
             */
            resetGroupModalState() {
                this.groupMetricModal.display = false;
                this.groupMetricModal.progress = false;
                this.form = {
                    name: '',
                    description: ''
                };
            }
        },
        computed: {
            /**
             * @function groupMetricModalTitle
             * @description Computes the title for the metric group modal based on the current action.
             * @returns {string} Modal title based on the action type.
             */
            groupMetricModalTitle() {
                const mainDlgTitle = 'grupo de métricas';
                if (!this.groupMetricModal.progress) {
                    return this.groupMetricModal.currentAction === this.groupMetricModal.actions.CREATE ? `Novo ${mainDlgTitle}` : `Editar ${mainDlgTitle}`;
                }
                return `Aguarde enquanto salvamos o ${mainDlgTitle}`;
            },
            /**
             * @function validateForm
             * @description Computes to validate form data input
             * @returns {bool} True if form data inputs is valid
             */
            validateForm() {
                return Boolean(this.form.name?.trim().length > 0);
            }
        }
    });
</script>

<template>
    <Card>
        <!-- Title Section -->
        <template #title>
            <span class="font-light text-700 text-xl">Grupos de Métricas</span>
        </template>

        <!-- Toolbar Section -->
        <template #content>
            <Toolbar class="h-6rem">
                <!-- Start Buttons (Add, Delete) -->
                <template #start>
                    <div class="lg:mt-0 sm:mt-2 flex flex-row" style="gap: 5px">
                        <Button
                            icon="fas fa-plus"
                            class="p-button-rounded p-button-success p-button-outlined p-button-raised"
                            title="Adicionar novo grupo de métricas"
                            @click="displayGroupMetricModal(groupMetricModal.actions.CREATE)"
                            :disabled="loading" />
                        <Button
                            icon="fas fa-trash"
                            title="Excluir um ou mais itens"
                            class="p-button-rounded p-button-danger p-button-outlined p-button-raised"
                            @click="confirmDeleteGroupMetrics"
                            :disabled="!groups.selection.length" />
                    </div>
                </template>

                <!-- End Buttons (Refresh, Filter) -->
                <template #end>
                    <div class="lg:mt-0 sm:mt-2 flex flex-row" style="gap: 5px">
                        <Button
                            icon="fas fa-redo-alt"
                            class="p-button-rounded p-button-info p-button-outlined p-button-raised"
                            title="Atualizar lista"
                            @click="loadMetricGroups"
                            :disabled="groups.loading" />
                        <div class="relative">
                            <Button
                                icon="fas fa-filter"
                                class="p-button p-button-rounded p-button-info p-button-outlined p-button-raised"
                                @click="filterModal.display = true"
                                :disabled="groups.loading"
                                title="Escolher filtros" />
                            <Button
                                v-if="hasFilters"
                                icon="fas fa-broom"
                                class="p-button-rounded p-button-raised clear-filter"
                                title="Limpar filtros"
                                @click="clearAllFilters"
                                :disabled="groups.loading" />
                        </div>
                    </div>
                </template>
            </Toolbar>
        </template>

        <!-- Data Table Section -->
        <template #footer>
            <DataTable
                lazy
                ref="dt"
                dataKey="ID"
                class="p-datatable-sm"
                responsiveLayout="scroll"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} registros"
                v-model:selection="groups.selection"
                v-model:filters="filters"
                @filter="onFilter"
                @page="onPage"
                :value="groups.records"
                :paginator="true"
                :loading="groups.loading"
                :totalRecords="groups.totalRecords"
                :rows="10"
                :rowsPerPageOptions="[10, 25, 50]">
                <Column selectionMode="multiple" headerStyle="width:.2%; min-width:1rem;" />
                <Column field="ID" header="ID" :sortable="false" headerStyle="width:.40%; min-width:2rem;" />
                <Column field="name" header="Nome" :sortable="false" headerStyle="width:6%; min-width:2rem;" />
                <Column field="description" header="Descrição" :sortable="false" headerStyle="width:10%; min-width:2rem;" />
                <Column headerStyle="width: 5%; min-width:5rem;">
                    <template #body="slotProps">
                        <Button
                            icon="fas fa-pencil"
                            class="p-button-outlined p-button-rounded p-button-sm p-button-warning p-button-raised action-button"
                            title="Editar pessoa"
                            @click="displayGroupMetricModal(groupMetricModal.actions.UPDATE, slotProps.data)" />
                    </template>
                </Column>
            </DataTable>
        </template>
    </Card>
    <Dialog v-model:visible="filterModal.display" header="Filtro global" :modal="true" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '30vw' }" :maximizable="false">
        <FilterTemplate @filterSelection="makeFilter($event)" :showFilters="filterModal.displayFilters" :currentFilters="filterModal.selectedFilters" />
        <template #footer>
            <div class="flex flex-row justify-content-end">
                <Button label="Pesquisar" iconPos="left" icon="pi pi-search" autofocus @click="filterSearch()" class="w-10rem" />
                <Button label="Cancelar" iconPos="left" icon="pi pi-times" autofocus @click="filterModal.display = false" class="p-button-outlined p-button-danger w-10rem" />
            </div>
        </template>
    </Dialog>
    <Dialog
        v-model:visible="groupMetricModal.display"
        :header="groupMetricModalTitle"
        :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        :style="{ width: '30vw' }"
        :maximizable="false"
        :closable="!groupMetricModal.progress"
        @hide="resetGroupModalState">
        <ProgressTemplate v-if="groupMetricModal.progress" />
        <div class="p-input-filled p-button-filled" v-if="!groupMetricModal.progress">
            <div class="flex flex-column field">
                <label for="name">
                    <span class="text-danger">*</span>
                    Nome:
                </label>
                <InputText id="name" v-model="form.name" placeholder="Nome do grupos de métricas (Obrigatório)" class="w-full border-transparent" />
            </div>
            <div class="flex flex-column field">
                <label for="description">Descrição:</label>
                <InputText id="description" v-model="form.description" placeholder="Descrição do grupo de métricas" class="w-full border-transparent" />
            </div>
        </div>
        <template #footer>
            <div class="flex flex-row justify-content-end" v-if="!groupMetricModal.progress">
                <Button label="Salvar" iconPos="left" icon="fas fa-save" autofocus :disabled="!validateForm" @click="saveForm()" class="w-10rem" />
                <Button autofocus label="Cancelar" iconPos="left" icon="pi pi-times" class="p-button-outlined p-button-danger w-10rem" @click="this.groupMetricModal.display = false" />
            </div>
        </template>
    </Dialog>
</template>
