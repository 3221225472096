import AxiosInstance from '@/services/AxiosInstance';

const accountEndpoint = '/account';

/**
 * Class to interact with the Account API
 */
export default class AccountRouter {
    /**
     * Creates a new account with the provided payload.
     *
     * @async
     * @param {Object} payload - The data for the new account.
     * @returns {Promise<Object>} - A promise that resolves to the created account data.
     * @throws {Error} - Throws an error if account creation fails.
     */
    async Create(payload) {
        try {
            const response = await AxiosInstance.post(accountEndpoint, payload);
            return response.data?.data;
        } catch (error) {
            throw new Error(`Failed to create account: ${error.message}`);
        }
    }

    /**
     * Updates an existing account with the provided payload.
     *
     * @async
     * @param {Object} payload - The data to update the account.
     * @returns {Promise<Object>} - A promise that resolves to the updated account data.
     * @throws {Error} - Throws an error if account update fails.
     */
    async Update(payload) {
        try {
            const response = await AxiosInstance.put(accountEndpoint, payload);
            return response.data?.data;
        } catch (error) {
            throw new Error(`Failed to update account: ${error.message}`);
        }
    }

    /**
     * Registers a Firebase token for push notifications.
     *
     * @async
     * @param {Object} payload - The Firebase registration token.
     * @returns {Promise<Object>} - A promise that resolves to the registered token data.
     * @throws {Error} - Throws an error if token registration fails.
     */
    async FirebaseRegistrationToken(payload) {
        try {
            const response = await AxiosInstance.post(`${accountEndpoint}/firebase-token`, payload);
            return response.data?.data;
        } catch (error) {
            throw new Error(`Failed to register Firebase token: ${error.message}`);
        }
    }

    /**
     * Toggles Telegram notifications.
     *
     * @async
     * @param {boolean} accepted - Whether to accept or reject Telegram notifications.
     * @returns {Promise<Object>} - A promise that resolves to the notification status.
     * @throws {Error} - Throws an error if the notification setting fails.
     */
    async TelegramNotification(accepted) {
        try {
            const response = await AxiosInstance.get(`${accountEndpoint}/telegram-notification?accepted=${accepted}`);
            return response.data;
        } catch (error) {
            throw new Error(`Failed to set Telegram notifications: ${error.message}`);
        }
    }

    /**
     * Changes the account password with the provided payload.
     *
     * @async
     * @param {Object} payload - The data to change the password.
     * @returns {Promise<Object>} - A promise that resolves when the password is changed.
     * @throws {Error} - Throws an error if the password change fails.
     */
    async ChangePassword(payload) {
        try {
            const response = await AxiosInstance.post(`${accountEndpoint}/change-password`, payload);
            return response.data;
        } catch (error) {
            throw new Error(`Failed to change password: ${error.message}`);
        }
    }
}
