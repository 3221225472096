<script>
import { defineComponent } from "vue";
import MenuRouter from "@/api/routes/Menu";
import PermissionRouter from "@/api/routes/Permission";
import RoleRouter from "@/api/routes/Role";
export default defineComponent({
  props: ["action", "role", "save"],
  emits: ["valid", "close", "progress"],
  data() {
    return {
      roleForm: {
        name: null,
        description: null,
      },
      menus: {
        records: [],
        selectedMenus: [],
        totalRecords: 0,
        loading: false,
        indexPage: null,
      },
      permissions: {
        records: [],
        selectedPermissions: [],
        totalRecords: 0,
      },
      MenuApi: null,
      PermissionApi: null,
      RoleApi: null,
    };
  },
  created() {
    this.MenuApi = new MenuRouter();
    this.PermissionApi = new PermissionRouter();
    this.RoleApi = new RoleRouter();
  },
  async mounted() {
    await this.LoadMenus();
    await this.LoadPermissions();
    this.GetAction();
  },
  methods: {
    GetAction() {
      if (this.action == "update") {
        this.roleForm = { ...this.role };
        this.menus.selectedMenus = this.DigestSelectedMenus();
        this.menus.indexPage = this.role.indexPageId
        this.permissions.selectedPermissions = this.role?.permissions?.map(
          (p) => {
            return p.ID;
          }
        );
      }
    },
    Valid() {
      if (this.roleForm.name?.length > 0 && this.menus.indexPage != null) {
        this.$emit("valid", true);
      } else {
        this.$emit("valid", false);
      }
    },
    Save() {
      switch (this.action) {
        case "create":
          this.CreateRole();
          break;
        case "update":
          this.UpdateRole();
          break;
      }
    },
    async UpdateRole() {
      this.$emit("progress", true);
      setTimeout(async () => {
        await this.RoleApi.Update(this.GetPayload(), this.roleForm.ID)
          .then(() => {
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Operação realizada com sucesso",
              life: 3000,
            });
          })
          .finally(() => {
            this.$emit("progress", false);
            this.$emit("close");
          });
      }, 2000);
    },
    async CreateRole() {
      this.$emit("progress", true);
      setTimeout(async () => {
        await this.RoleApi.Create(this.GetPayload())
          .then(() => {
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: `Operação realizada com sucesso`,
              life: 2000,
            });
          })
          .finally(() => {
            this.$emit("progress", false);
            this.$emit("close");
          });
      }, 2000);
    },
    async LoadPermissions() {
      this.permissions.loading = true;
      this.permissions.records = await this.PermissionApi.FindAll()
        .then((resp) => {
          return resp?.permissions.map((p) => {
            return {
              label: p.name,
              value: p.ID,
            };
          });
        })
        .finally((this.permissions.loading = false));
    },
    async LoadMenus() {
      this.menus.loading = true;
      await this.MenuApi.FindAll()
        .then((resp) => this.DigestMenus(resp.menus))
        .finally((this.menus.loading = false));
    },
    async DigestSelectedMenus() {
      let menus = {};
      let parentsSelecteds = [];
      await this.role?.menus?.map((m) => {
        if (m?.parentId) {
          parentsSelecteds.push(m.parentId);
          let allChecked = this.menus.records
            .find((x) => x.key === m.parentId)
            ?.children.filter((f) => !parentsSelecteds.includes(f.parent));
          menus[m.parentId] = {
            checked: allChecked.length == 0,
            partialChecked: allChecked.length != 0,
            id: m.parentId,
          };
          m.ID != m.parentId
            ? (menus[`${m.parentId}-${m.ID}`] = {
                checked: true,
                partialChecked: false,
                id: m.ID,
              })
            : null;
        }
      });
      this.menus.selectedMenus = menus;
    },
    DigestMenus(menus) {
      let root = [];
      let children = [];
      menus?.map((m) => {
        if (!m.parentId) {
          root.push({
            key: m.ID,
            label: m.label,
            children: [],
          });
        }
        if (m.parentId) {
          children.push({
            parent: m.parentId,
            key: m.parentId + "-" + m.ID,
            label: m.label,
            icon: m.icon,
          });
        }
      });
      children?.map((c) => {
        root.find((m) => m.key === c.parent)?.children.push(c);
      });
      this.menus.records = root;
    },
    GetPayload() {
      const menuKeys = Object.keys(this.menus.selectedMenus);
      let menuIds = [];
      menuKeys.forEach((key) => {
        if (key.includes("-")) {
          let rootChildren = key.split("-");
          menuIds.push(parseInt(rootChildren[1]));
        } else {
          menuIds.push(parseInt(key));
        }
      });
      let payload = {};
      try {
        payload = {
          name: this.roleForm.name,
          description: this.roleForm?.description,
          menuIds: menuIds,
          permissionIds: this.permissions.selectedPermissions,
          indexPageId: this.menus.indexPage
        };
        return payload;
      } catch {
        this.$toast.add({
          severity: "error",
          summary: "Erro critico",
          detail: "Ocorreu um erro ao obter as informações necessárias",
          life: 3000,
        });
      }
    },
  },
  watch: {
    save: function () {
      this.Save();
    },
  },
  computed: {
    GetIndexPages() {
      return this.menus.records
        .map((m) => {
          return m.children.map((c) => {
            return { label: c.label, value: parseInt(c.key.split("-")[1]) };
          });
        })
        .flat();
    },
  },
});
</script>

<template>
  <div class="flex flex-column field">
    <label for="name">Nome:</label>
    <InputText
      id="name"
      v-model="roleForm.name"
      placeholder="Nome da função"
      @input="Valid()"
    />
  </div>
  <div class="flex flex-column field">
    <label for="description">Descrição:</label>
    <InputText
      id="description"
      v-model="roleForm.description"
      placeholder="Descrição da função"
      @input="Valid()"
    />
  </div>
  <div class="flex flex-column field">
    <label for="menus">Menus:</label>
    <TreeSelect
      id="menus"
      v-model="menus.selectedMenus"
      selectionMode="checkbox"
      placeholder="Selecione os menus da função"
      emptyFilterMessage="Sem resultados"
      emptyMessage="Sem resultados"
      :options="menus.records"
      :loading="menus.loading"
      @node-select="Valid()"
      @node-unselect="Valid()"
    />
  </div>
  <div class="flex flex-column field">
    <label for="permissions">Permissões:</label>
    <MultiSelect
      id="permissions"
      v-model="permissions.selectedPermissions"
      optionLabel="label"
      optionValue="value"
      placeholder="Selecione as permissões da função"
      emptyFilterMessage="Sem resultados"
      emptyMessage="Sem resultados"
      :filter="true"
      :options="permissions.records"
      :loading="permissions.loading"
      @change="Valid()"
    />
  </div>
  <div class="flex flex-column field">
    <label for="indexPage">Página inicial:</label>
    <Dropdown
      id="indexPage"
      optionLabel="label"
      optionValue="value"
      placeholder="Selecione um tipo de pessoa"
      emptyFilterMessage="Sem resultados"
      emptyMessage="Sem resultados"
      v-model="menus.indexPage"
      :filter="true"
      :options="GetIndexPages"
      :loading="menus.loading"
      @change="Valid()"
    />
  </div>

</template>

