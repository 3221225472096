<script>
import { defineComponent } from "@vue/runtime-core";
import moment from "moment";
export default defineComponent({
  props: ["selectedEnvelope", "envelopeEventTypes"],
  data() {
    return {
      envelope: {},
    };
  },
  created() {
    this.envelope = { ...this.selectedEnvelope };
  },
  methods: {
    FormatDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm:ss")
    },
    EnvelopeEventDescription(id) {
        return this.envelopeEventTypes.find(eet => eet.ID === id).description
    }
  },
});
</script>

<template>
  <div class="flex flex-column field">
    <label>Data do evento:</label>
    <strong class="text-lg">{{ FormatDate(envelope.envelopeEvents[0].createdAt) }} </strong>
  </div>
  <div class="flex flex-column field">
    <label>Descrição do evento:</label>
    <strong class="text-lg"> {{ EnvelopeEventDescription(envelope.envelopeEvents[0].envelopeEventTypeID) }} </strong>
  </div>
  <div class="flex flex-column field" v-if="envelope.envelopeEvents[0]?.observations">
    <label for="observations">Observações:</label>
    <strong
      id="observations"
      class="text-md"
      :disabled="true"
      :autoResize="false"
    >
    {{ envelope.envelopeEvents[0].observations }}
    </strong>
  </div>
</template>
