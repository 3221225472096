/**
 * @constant {string} AuthToken - The key used to store the authorization token (access token) in localStorage.
 */
export const AuthToken = 'accessToken';

/**
 * @constant {string} RefreshToken - The key used to store the refresh token in localStorage.
 */
export const RefreshToken = 'refreshToken';

/**
 * @constant {string} ThemeAppearance - The key used to store the user's theme appearance preference in localStorage.
 */
export const ThemeAppearance = 'themeAppearance';

/**
 * @constant {string} UserInfo - The key used to store user information in localStorage.
 */
export const UserInfo = 'userInfo';

/**
 * @constant {string} AccessType - The key used to store the access type for the user (e.g., admin or user role) in localStorage.
 */
export const AccessType = 'accessType';
