<script>
    import Lottie from './Lottie.vue';
    import Loading from '@/layout/lotties/loading.json';
    export default {
        components: {
            Lottie
        },
        data() {
            return {
                lottie: {
                    animation: Loading,
                    height: 200,
                    width: 200,
                    speed: 1.1,
                    loop: true
                }
            };
        }
    };
</script>

<template>
    <div class="flex align-items-center">
        <Lottie :lottieProp="this.lottie" />
    </div>
</template>
