<script>
import { defineComponent } from 'vue';
import Success from '../../../layout/lotties/success2.json';
import Pending from '../../../layout/lotties/pending.json';
import Error from '../../../layout/lotties/error.json';
import Congratulations from '../../../layout/lotties/congratulations.json';
import Lottie from '../../Utils/Lottie.vue';
import CollaboratorRouter from '../../../api/routes/Collaborator';
import moment from 'moment';
import FilterModal from '../../Utils/Filter';
import { FilterMatchMode } from 'primevue/api';

export default defineComponent({
    components: { Lottie, FilterModal },
    data() {
        return {
            loading: false,
            employeeIsInVacation: false,
            employeeHasNotCalendars: false,
            employeeHasNotWorkSchedule: false,
            punchesInfo: [],
            totalRecords: 0,
            punchesDT: {},
            hasFilters: false,
            pagination: {},
            filters: {
                name: {
                    value: '',
                    matchMode: 'contains'
                }
            },
            filterModal: {
                show: false,
                showFilters: ['punchTimeRange', 'punchStatus'],
                selectedFilters: {}
            },
            lottiePunchPendingAnim: {
                animation: Pending,
                height: 70,
                width: 70,
                speed: 0.5,
                loop: false
            },
            lottiePunchOkAnim: {
                animation: Success,
                height: 70,
                width: 70,
                speed: 0.6,
                loop: false
            },
            lottiePunchError: {
                animation: Error,
                height: 200,
                width: 200,
                speed: 0.6,
                loop: false
            },
            lottieCongratulations: {
                animation: Congratulations,
                height: 200,
                width: 200,
                speed: 0.6,
                loop: false
            },
            home: { icon: 'pi pi-home', to: '/' },
            items: [{ label: 'Pontos em atraso', to: this.$router.currentRoute._value }],
            CollaboratorApi: null
        };
    },
    created() {
        this.CollaboratorApi = new CollaboratorRouter();
    },
    mounted() {
        this.FindPunchPendencies();
    },
    methods: {
        FilterInit() {
            this.pagination = {
                page: 1,
                rows: this.pageRows
            };
            this.filters = {
                global: {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS
                }
            };
        },
        CloseFilterModal() {
            this.filterModal.show = false;
        },
        ShowFilterModal() {
            this.filterModal.show = true;
        },
        MakeFilter(filter) {
            this.filterModal.selectedFilters = Object.assign({}, this.filterModal.selectedFilters, filter);
            this.filters.global = Object.assign({}, this.filters.global, filter);
        },
        ClearFilters() {
            this.filterModal.selectedFilters = [];
            this.FilterInit();
            this.hasFilters = false;
            this.OnFilter();
        },
        FilterSearch() {
            this.filterModal.show = false;
            this.OnFilter();
        },
        OnPage(event) {
            this.pagination = event;
            this.LoadCollaborators();
        },
        OnFilter() {
            if (Object.keys(this.filterModal.selectedFilters).length > 0) {
                this.hasFilters = true;
            }
            this.pagination.filters = this.filters;
            this.LoadCollaborators();
        },
        async FindPunchPendencies() {
            this.punchesInfo = [];
            this.loading = true;
            try {
                const {
                    collaboratorPunches: { isInVacation, notCalendarsAssociated, notWorkScheduleAssociated, punchesInfo }
                } = await this.CollaboratorApi.FindPunchPendencies();

                this.employeeIsInVacation = isInVacation;
                this.employeeHasNotCalendars = notCalendarsAssociated;
                this.employeeHasNotWorkSchedule = notWorkScheduleAssociated;
                this.punchesInfo = punchesInfo;
            } catch (e) {
              console.error(e)
            } finally {
                this.loading = false;
            }
        },
        FormatDate(layout, date) {
            return moment(date).format(layout);
        },
        RowClass(data) {
            return data.pendencies?.length > 0 ? 'punch-pending' : null;
        }
    }
});
</script>

<template>
    <Breadcrumb :home="home" :model="items" />
    <Divider />
    <Card>
        <template #title>
            <span class="font-light text-700 text-xl">Pontos em atraso</span>
        </template>
        <template #content>
            <div v-if="employeeHasNotCalendars || employeeHasNotWorkSchedule">
                <div class="flex flex-column justify-content-center align-items-center">
                    <lottie :lottieProp="this.lottiePunchError" />
                    <span class="text-xl text-center">Por favor entre em contato com GP e solicite a revisão dos seus dados cadastrais</span>
                </div>
            </div>
            <div v-else-if="punchesInfo == null || punchesInfo?.length == 0">
                <div class="flex flex-column justify-content-center align-items-center">
                    <lottie :lottieProp="this.lottieCongratulations" />
                    <span class="text-xl text-center">Perfeito, você não possui nenhum ajuste de ponto pendente!</span>
                </div>
            </div>
            <div v-else>
                <DataTable
                    ref="dt"
                    dataKey="ID"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} entradas"
                    responsiveLayout="scroll"
                    :lazy="false"
                    :value="punchesInfo"
                    :paginator="true"
                    :rows="50"
                    :loading="loading"
                    :totalRecords="totalRecords"
                    @page="OnPage($event)"
                    @filter="OnFilter($event)"
                    @sort="OnSort($event)"
                >
                    <template #header>
                        <Toolbar style="flex-wrap: nowrap; gap: 1rem">
                            <template v-slot:start>
                                <div class="lg:mt-0 sm:mt-2 flex flex-row" style="gap: 0.5rem">
                                    <Button icon="fas fa-redo-alt" class="p-button-rounded p-button-info p-button-outlined p-button-raised" @click="FindPunchPendencies()" :disabled="loading" title="Atualizar lista" />
                                    <!-- <div class="relative">
                                        <Button icon="fas fa-filter" class="p-button p-button-rounded p-button-info p-button-outlined p-button-raised" @click="filterModal.show = true" :disabled="loading" title="Escolher filtros" />
                                        <Button v-if="hasFilters" icon="fas fa-broom" class="p-button-rounded p-button-raised clear-filter" title="Limpar filtros" @click="ClearFilters()" :disabled="loading" />
                                    </div> -->
                                </div>
                            </template>
                            <template v-slot:end>
                                <div class="lg:mt-0 sm:mt-2 flex flex-row justify-content-end">
                                    <div class="lg:w-8 flex align-items-center text-sm w-16" style="gap: 6px">
                                        <i class="fa-solid fa-circle-info"></i>
                                        <span>Por favor, note: Ao ajustar um ou mais pontos, as informações listadas, serão atualizadas nas próximas 24 horas.</span>
                                    </div>
                                </div>
                            </template>
                        </Toolbar>
                    </template>

                    <Column :headerStyle="{ width: '1rem' }" />
                    <Column :headerStyle="{ width: '1rem' }">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.pendencies?.length > 0">
                                <lottie :lottieProp="this.lottiePunchPendingAnim" />
                            </div>
                            <div v-else>
                                <lottie :lottieProp="this.lottiePunchOkAnim" />
                            </div>
                        </template>
                    </Column>
                    <Column field="date" header="Data">
                        <template #body="slotProps">
                            <span class="item-category">{{ slotProps.data.date }}</span>
                            <span class="item-category" v-if="slotProps.data.isHoliday">(Feriado)</span>
                        </template>
                    </Column>
                    <Column field="pendencies" header="Pendências">
                        <template #body="slotProps">
                            <div class="grid-container">
                                <div v-for="punch in slotProps.data.pendencies" v-bind:key="punch">
                                    <InputText type="text" class="w-12 text-900 text-center border-1 border-solid punch-time surface-overlay" style="border-color: red" :value="punch" readonly />
                                </div>
                            </div>
                        </template>
                    </Column>
                    <Column field="hitPunches" header="Batidas">
                        <template #body="slotProps">
                            <div class="grid-container">
                                <div v-for="punch in slotProps.data.hitPunches" v-bind:key="punch">
                                    <InputText type="text" class="w-12 text-900 text-center border-1 border-solid punch-time border-bluegray-500 surface-overlay" :value="punch" readonly />
                                </div>
                            </div>
                        </template>
                    </Column>
                    <Column field="workSchedule" header="Escala do dia">
                        <template #body="slotProps">
                            <div class="grid-container">
                                <div v-for="punch in slotProps.data?.workSchedule" v-bind:key="punch">
                                    <InputText type="text" class="w-12 text-900 text-center border-1 border-solid punch-time border-bluegray-500 surface-overlay" :value="punch" readonly />
                                </div>
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </template>
    </Card>

    <Dialog v-model:visible="filterModal.show" header="Filtros" :modal="true" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '30vw' }" :maximizable="false">
        <FilterModal @filterSelection="MakeFilter($event)" :showFilters="filterModal.showFilters" :currentFilters="filterModal.selectedFilters" />
        <template #footer>
            <div class="flex flex-row justify-content-between">
                <Button label="Cancelar" iconPos="right" icon="pi pi-times" autofocus @click="CloseFilterModal()" class="w-12rem" />
                <Button label="Pesquisar" iconPos="right" icon="pi pi-search" autofocus @click="FilterSearch()" class="w-12rem" />
            </div>
        </template>
    </Dialog>
</template>

<style scoped>
.grid-container {
    display: grid;
    grid-auto-flow: column dense;
    grid-template-rows: 40px 40px;
    grid-auto-columns: 70px;
    column-gap: 5px;
    row-gap: 5px;
}

.card {
    padding: 2rem;
    border-radius: 4px;
    margin-bottom: 2rem;
}

.item-category {
    font-size: 16px;
    font-weight: 600;
    vertical-align: bottom;
}

.punch-time {
    font-size: 14px;
    font-weight: 600;
    vertical-align: middle;
    align-items: center;
}

:deep(.punch-pending) {
    background-color: rgba(255, 0, 0, 0.15) !important;    
}
</style>
