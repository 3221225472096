/** * @module CollaboratorFormComponent */

<script>
    import { defineComponent } from 'vue';
    import InputOptions from '../../Utils/InputOptions';
    import DepartmentRouter from '@/api/routes/Department';
    import CollaboratorRouter from '@/api/routes/Collaborator';
    import CompanyRouter from '@/api/routes/Company';
    import PersonRouter from '@/api/routes/Person';
    import CollaboratorEventTypeRouter from '@/api/routes/CollaboratorEventTypes';
    import CollaboratorCalendarRouter from '@/api/routes/CollaboratorCalendar';
    import CollaboratorWorkScheduleRouter from '@/api/routes/CollaboratorWorkSchedule';

    export default defineComponent({
        components: { InputOptions },
        emits: ['valid', 'close', 'progress'],
        props: {
            /**
             * Action type for the form (create or update)
             * @type {string}
             */
            action: String,

            /**
             * Boolean to trigger form save
             * @type {boolean}
             */
            save: Boolean,

            /**
             * Collaborator object containing information to be populated in the form
             * @type {Object}
             */
            collaborator: Object
        },
        data() {
            return {
                /**
                 * API instances to handle requests for different resources
                 * @type {Object}
                 */
                apis: {
                    collaborator: new CollaboratorRouter(),
                    eventType: new CollaboratorEventTypeRouter(),
                    calendar: new CollaboratorCalendarRouter(),
                    workSchedule: new CollaboratorWorkScheduleRouter(),
                    department: new DepartmentRouter(),
                    company: new CompanyRouter(),
                    person: new PersonRouter()
                },

                /**
                 * Form data
                 * @type {Object}
                 */
                form: {
                    collaboratorID: null,
                    isInVacation: false,
                    skipExternalSync: false,
                    employerRegistrationNumber: null,
                    person: this.createDefaultState(),
                    company: this.createDefaultState(),
                    departments: this.createDefaultState(),
                    workSchedule: this.createDefaultState(),
                    eventTypes: this.createDefaultState(),
                    calendars: this.createDefaultState(),
                    valid: false
                },
                departmentShowColumns: [
                    { field: 'ID', header: 'ID', sortable: false },
                    { field: 'name', header: 'Nome', sortable: false },
                ],
                companyShowColumns: [
                    { field: 'ID', header: 'ID', sortable: false },
                    { field: 'name', header: 'Nome', sortable: false },
                    { field: 'txID', header: 'CPF/CNPJ', sortable: false }
                ],
                personShowColumns: [
                    { field: 'ID', header: 'ID', sortable: false },
                    { field: 'name', header: 'Nome', sortable: false },
                    { field: 'txID', header: 'CPF/CNPJ', sortable: false }
                ],
            };
        },
        async mounted() {
            try {
                await this.loadAllData();
                this.handleAction();
            } catch (error) {
                console.error('Error loading data:', error);
            }
        },
        methods: {
            /**
             * Creates a default state object for form fields.
             * @returns {Object} Default state object.
             */
            createDefaultState() {
                return {
                    loading: false,
                    records: null,
                    selected: null,
                    valid: false
                };
            },

            /**
             * Handles form action depending on whether it's an update or create operation.
             */
            handleAction() {
                if (this.action === 'update') {
                    this.populateCollaboratorForm();
                }
            },

            /**
             * Populates the form fields with collaborator data for updating.
             */
            populateCollaboratorForm() {
                const { collaborator } = this;
                this.form = {
                    ...this.form,
                    collaboratorID: collaborator?.ID || null,
                    calendars: { ...this.form.calendars, selected: collaborator?.collaboratorCalendar?.map((c) => c.ID) || [] },
                    workSchedule: { ...this.form.workSchedule, selected: collaborator?.collaboratorWorkSchedule?.ID || null },
                    eventTypes: { ...this.form.eventTypes, selected: this.getMostRecentEventType(collaborator?.events) || null },
                    isInVacation: collaborator?.isInVacation || false,
                    skipExternalSync: collaborator?.skipExternalSync || false,
                    employerRegistrationNumber: collaborator?.employerRegistrationNumber || ''
                };

                this.inputController('company', 'set', collaborator?.company || null);
                this.inputController('person', 'set', collaborator?.person || null);
                this.inputController('departments', 'set', collaborator?.departments || []);
            },

            /**
             * Retrieves the most recent event type from the list of events.
             * @param {Array} events - List of events.
             * @returns {number|null} ID of the most recent event type, or null if no events are present.
             */
            getMostRecentEventType(events) {
                if (!events?.length) return null;
                return (
                    events.reduce((latest, event) => (new Date(event.createdAt) > new Date(latest.createdAt) ? event : latest), events[0])
                        ?.collaboratorEvent?.ID || null
                );
            },

            /**
             * Loads all necessary data for the form (calendars, event types, work schedules).
             */
            async loadAllData() {
                const promises = [
                    this.loadResource('calendars', this.apis.calendar, 'collaboratorCalendars'),
                    this.loadResource('eventTypes', this.apis.eventType, 'collaboratorEventTypes'),
                    this.loadResource('workSchedule', this.apis.workSchedule, 'collaboratorWorkSchedules')
                ];
                await Promise.all(promises);
            },

            /**
             * Loads a resource and populates the form field with the retrieved records.
             * @param {string} entity - The form entity to update.
             * @param {Object} api - The API instance to use for fetching data.
             * @param {string} apiProperty - The property name in the API response to use.
             */
            async loadResource(entity, api, apiProperty) {
                this.form[entity].loading = true;
                try {
                    const response = await api.FindAll();
                    this.form[entity].records = response[apiProperty] || [];
                } catch (err) {
                    console.error(`Error loading ${entity}:`, err);
                    this.showToast('error', `Failed to load ${entity}`);
                } finally {
                    this.form[entity].loading = false;
                }
            },
            
            /**
             * Controls input actions for the form (set or clear).
             * @param {string} entity - The form entity to modify.
             * @param {string} action - The action to perform (set or clear).
             * @param {...any} event - The event data to apply to the form entity.
             */
            inputController(entity, action, ...event) {
                try {
                    switch (action) {
                        case 'set':
                            if (event.length > 0) {
                                this.form[entity] = {
                                    ...this.form[entity],
                                    selected: event[0],
                                    valid: !!event[0]
                                };
                            }
                            break;
                        case 'clear':
                            this.form[entity] = this.createDefaultState();
                            break;
                        default:
                            console.error(`Invalid action: ${action}`);
                    }
                } finally {
                    this.validate();
                }
            },
            /**
             * Validates the form, ensuring required fields are filled out.
             */
            validate() {
                const { employerRegistrationNumber, person, company, departments } = this.form;
                this.form.valid = employerRegistrationNumber && person.valid && company.valid && departments.valid;
                this.$emit('valid', this.form.valid);
            },

            /**
             * Stores the form data by either creating or updating a collaborator record.
             */
            async storeForm() {
                this.$emit('progress', true);
                try {
                    const payload = this.getRequestPayload();
                    if (this.action === 'update') {
                        await this.apis.collaborator.Update(payload, this.form.collaboratorID);
                    } else if (this.action === 'create') {
                        await this.apis.collaborator.Create(payload);
                    }
                    this.showToast('success', 'Operação realizada com sucesso');
                } catch (error) {
                    console.error('Erro ao salvar colaborador:', error);
                } finally {
                    this.$emit('progress', false);
                    this.$emit('close');
                }
            },

            /**
             * Constructs the payload for the form submission request.
             * @returns {Object} The request payload.
             */
            getRequestPayload() {
                const { person, company, workSchedule, departments, calendars, eventTypes } = this.form;
                return {
                    employerRegistrationNumber: this.form.employerRegistrationNumber,
                    personID: person.selected?.ID,
                    companyID: company.selected?.ID,
                    collaboratorWorkScheduleID: workSchedule.selected,
                    departmentsIDs: departments.selected?.map((d) => d.ID),
                    collaboratorCalendarsIDs: calendars.selected,
                    isInVacation: this.form.isInVacation,
                    skipExternalSync: this.form.skipExternalSync,
                    collaboratorEventTypeID: this.action === 'update' ? eventTypes.selected?.ID : undefined
                };
            },

            /**
             * Displays a toast message with a given severity and detail.
             * @param {string} severity - The severity of the message (error, success, etc.).
             * @param {string} detail - The message detail to display.
             */
            showToast(severity, detail) {
                this.$toast.add({ severity, summary: 'Erro', detail, life: 3000 });
            }
        },
        watch: {
            /**
             * Watches the save property and triggers form submission when it changes.
             */
            save() {
                this.storeForm();
            }
        }
    });
</script>

<template>
    <div class="p-input-filled p-button-filled">
        <div class="flex flex-column field">
            <label for="employerRegistrationNumber">Matricula:</label>
            <input-text
                id="employerRegistrationNumber"
                v-model="form.employerRegistrationNumber"
                :placeholder="'Numero da matricula do colaborador'"
                @input="validate()" />
        </div>
        <div class="flex flex-column field">
            <label for="person">Pessoa:</label>
            <input-options
                name="person"
                @selection="inputController('person', 'set', $event)"
                @cleared="inputController('person', 'clear')"
                @disablePersisteButton="$emit('disablePersisteButton')"
                :inputCurrentSelectionValue="form.person.selected?.name"
                :currentSelection="form.person.selected"
                :multiselect="false"
                :showColumns="personShowColumns"
                :searchAPI="apis.person"
                :apiPropertyName="'people'"
                :showSearch="true"
                :inputPlaceholder="'Selecione uma pessoa'"
                :showClear="form.person.selected != null"
                :enableConfirmationButton="form.person.valid"
                :lazyLoadDialogMaximizable="true"
                :lazyLoadTextHeader="'Selecione uma pessoa'" />
        </div>
        <div class="flex flex-column field">
            <label for="company">Empresa:</label>
            <input-options
                name="company"
                @selection="inputController('company', 'set', $event)"
                @cleared="inputController('company', 'clear')"
                @disablePersisteButton="$emit('disablePersisteButton')"
                :inputCurrentSelectionValue="form.company.selected?.name"
                :currentSelection="form.company.selected"
                :multiselect="false"
                :showColumns="companyShowColumns"
                :searchAPI="apis.company"
                :apiPropertyName="'companies'"
                :showSearch="true"
                :inputPlaceholder="'Selecione uma empresa'"
                :showClear="form.company.selected != null"
                :enableConfirmationButton="form.company.valid"
                :lazyLoadDialogMaximizable="true"
                :lazyLoadTextHeader="'Selecione uma empresa'" />
        </div>
        <div class="flex flex-column field">
            <label for="departments">Departamentos:</label> 
            <input-options 
                name="departments"
                @selection="inputController('departments', 'set', $event)"
                @cleared="inputController('departments', 'clear')"
                @disablePersisteButton="$emit('disablePersisteButton')"
                :currentSelection="form.departments.selected"
                :multiselect="true"
                :multiselectDisplayFieldName="'name'"
                :showColumns="departmentShowColumns"
                :searchAPI="apis.department"
                :apiPropertyName="'departments'"
                :inputPlaceholder="'Selecione um ou mais departamentos'"
                :showSearch="true"
                :showClear="form.departments.selected != null"
                :enableConfirmationButton="form.departments.valid"
                :lazyLoadDialogMaximizable="true"
                :lazyLoadTextHeader="'Selecione o(s) departamento(s)'" />
        </div>
        <div class="flex flex-column field">
            <label for="collaborator_work_schedule">Escala de trabalho:</label> 
            <Dropdown
                id="collaborator_work_schedule"
                v-model="form.workSchedule.selected"
                optionLabel="name"
                optionValue="ID"
                placeholder="Selecione a escala de trabalho"
                emptyFilterMessage="Sem resultados"
                emptyMessage="Sem resultados"
                :filter="true"
                :options="form.workSchedule.records"
                :loading="form.workSchedule.loading"
                @change="validate()" />
        </div>
        <div class="flex flex-column field">
            <label for="calendars">Calendários:</label>
            <MultiSelect
                id="calendars"
                v-model="form.calendars.selected"
                optionLabel="name"
                optionValue="ID"
                placeholder="Selecione um ou mais calendários"
                emptyFilterMessage="Sem resultados"
                emptyMessage="Sem resultados"
                :filter="true"
                :options="form.calendars.records"
                :loading="form.calendars.loading"
                @change="validate()" />
        </div>
        <div class="flex flex-column field" v-if="action == 'update'">
            <label for="status">Status:</label>
            <Dropdown
                id="status"
                v-model="form.eventTypes.selected"
                optionLabel="description"
                optionValue="ID"
                placeholder="Selecione o status do empregado"
                emptyFilterMessage="Sem resultados"
                emptyMessage="Sem resultados"
                :filter="true"
                :options="form.eventTypes.records"
                :loading="form.eventTypes.loading"
                @change="validate()" />
        </div>
        <div class="flex flex-row field" v-if="action == 'update'">
            <div class="flex" style="gap: 5px" title="Ao marcar esta opção o colaborador(a) não receberá notificações">
                <Checkbox id="is_in_vacation" v-model="this.form.isInVacation" :binary="true" @change="validate()" />
                <label for="is_in_vacation" style="margin-top: 2px">Colaborador(a) em férias</label>
            </div>
        </div>
        <div class="flex flex-row field" v-if="action == 'update'">
            <div
                class="flex"
                style="gap: 5px"
                title="Ao marcar esta opção como verdadeira as sincronizações externas não terão efeito sobre este colaborador">
                <Checkbox id="skip_external_sync" v-model="this.form.skipExternalSync" :binary="true" @change="validate()" />
                <label for="skip_external_sync" style="margin-top: 2px">Não sincronizar com serviços externos</label>
            </div>
        </div>
    </div>
</template>
