<script>
    import { defineComponent } from 'vue';
    import LazyLoad from './LazyLoad.vue';

    export default defineComponent({
        components: { LazyLoad },
        emits: ['selection', 'unSelection', 'cleared', 'disablePersisteButton'],
        props: {
            /**
             * Holds the current selection data.
             * @type {Object}
             */
            currentSelection: Object,

            /**
             * Enables or disables multiselect functionality.
             * @type {boolean}
             */
            multiselect: Boolean,

            /**
             * Multiselect display field
             * @type {String}
             */
            multiselectDisplayFieldName: String,

            /**
             * Additional to implements in search
             * @type {boolean}
             */
            additionalFilters: Object,

            /**
             * Determines whether columns should be shown in LazyLoad.
             * @type {boolean}
             */
            showColumns: Boolean,

            /**
             * The text of placeholder in input field
             * @type {String}
             */
            inputPlaceholder: String,

            /**
             * Function to perform search API operations.
             * @type {Function}
             */
            searchAPI: Function,

            /**
             * Property name used by the search API.
             * @type {string}
             */
            apiPropertyName: String,

            /**
             * Toggles visibility of the search button.
             * @type {boolean}
             */
            showSearch: Boolean,

            /**
             * Toggles visibility of the clear button.
             * @type {boolean}
             */
            showClear: Boolean,

            /**
             * Text for the LazyLoad dialog header.
             * @type {string}
             */
            lazyLoadTextHeader: String,

            /**
             * Determines if the LazyLoad dialog is maximizable.
             * @type {boolean}
             */
            lazyLoadDialogMaximizable: Boolean,

            /**
             * Current value to be displayed in the input field.
             * @type {string}
             */
            inputCurrentSelectionValue: String,

            /**
             * Enables or disables the confirmation button.
             * @type {boolean}
             */
            enableConfirmationButton: Boolean
        },
        data() {
            return {
                lastInputValue: null,
                showLazyLoadDialog: false,
                showClearButton: this.showClear,
                inputTextValue: this.inputCurrentSelectionValue,
                inputMultiselectValue: this.currentSelection,
                enableLazyDialogConfirmButton: this.enableConfirmationButton
            };
        },
        created() {
            setTimeout(() => {
                this.lastInputValue = this.inputCurrentSelectionValue;
            }, 1000);
        },
        methods: {
            /**
             * Emits the 'selection' event with the selected value.
             * @param {Event} event - The event triggered when selection occurs.
             */
            onSelect(event) {
                this.$emit('selection', event);
            },
            /**
             * Emits the 'selection' event with the selected value.
             * @param {Event} event - The event triggered when selection occurs.
             */
            onUnselect(event) {
                this.$emit('unSelection', event);
            },
            /**
             * Emits the 'cleared' event when the clear button is clicked.
             */
            emitClear() {
                this.$emit('cleared');
            },
            /**
             * Set the last value of input and close lazy load dialog
             */
            lazyLoadCancel() {
                this.$emit('disablePersisteButton');
                this.inputTextValue = this.lastInputValue;
                this.showLazyLoadDialog = false;
            }
        },
        watch: {
            enableConfirmationButton(enable) {
                this.enableLazyDialogConfirmButton = enable;
            },
            inputCurrentSelectionValue(newVal) {
                this.inputTextValue = newVal;
            },
            currentSelection(newVal) {
                this.inputMultiselectValue = newVal;
            },
            showClear(show) {
                this.showClearButton = show;
            }
        }
    });
</script>

<template>
    <div class="p-inputgroup">
        <InputText readonly id="single-select" v-model="inputTextValue" class="w-full border-1" :placeholder="inputPlaceholder" v-if="!multiselect" />
        <AutoComplete
            readonly
            multiple
            id="muti-select"
            v-model="inputMultiselectValue"
            class="w-full border-none"
            :field="multiselectDisplayFieldName"
            :removable="false"
            :placeholder="!inputMultiselectValue ? inputPlaceholder : ''"
            v-if="multiselect" />
        <Button
            v-if="showClearButton"
            icon="fas fa-broom"
            class="surface-ground text-orange-500 border-transparent hover:bg-black-alpha-10"
            title="Limpar seleção"
            @click="emitClear" />
        <Button
            v-if="showSearch"
            icon="fas fa-search"
            class="surface-ground text-primary border-transparent hover:bg-black-alpha-10"
            title="Selecionar novo"
            @click="showLazyLoadDialog = true" />
    </div>
    <Dialog
        v-model:visible="showLazyLoadDialog"
        :header="lazyLoadTextHeader"
        modal
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        :style="{ width: '50vw' }"
        :maximizable="lazyLoadDialogMaximizable">
        <LazyLoad
            @selection="onSelect"
            @unSelection="onUnselect"
            :additionalFilters="additionalFilters"
            :currentSelection="currentSelection"
            :multiselect="multiselect"
            :showColumns="showColumns"
            :searchAPI="searchAPI"
            :apiPropertyName="apiPropertyName"
            :showSearch="lazyLoadDialogMaximizable" />
        <template #footer>
            <div class="flex justify-content-end">
                <Button
                    label="Confirmar"
                    iconPos="left"
                    icon="fas fa-check"
                    autofocus
                    @click="showLazyLoadDialog = false"
                    class="w-10rem p-button-info"
                    :disabled="!enableLazyDialogConfirmButton" />
                <Button
                    label="Cancelar"
                    iconPos="left"
                    icon="fas fa-times"
                    autofocus
                    @click="lazyLoadCancel()"
                    class="p-button-outlined p-button-danger w-10rem" />
            </div>
        </template>
    </Dialog>
</template>
