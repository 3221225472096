<script>
import moment from 'moment';
import { FilterMatchMode } from 'primevue/api';
import { defineComponent } from 'vue';
import CollaboratorRouter from '../../../api/routes/Collaborator';
import Success from '../../../layout/lotties/success2.json';
import Pending from '../../../layout/lotties/pending.json';
import Lottie from '../../Utils/Lottie.vue';
import FilterModal from '../../Utils/Filter.vue';

export default defineComponent({
    components: {
        Lottie,
        FilterModal
    },
    data() {
        return {
            loading: false,
            pageRows: 10,
            collaborators: {
                records: [],
                totalRecords: 0,
                selection: []
            },
            expandedRows: [],
            filterModal: {
                show: false,
                showFilters: ['people', 'punchDateRange'],
                selectedFilters: {}
            },
            hasFilters: false,
            filters: {
                name: {
                    value: '',
                    matchMode: FilterMatchMode.CONTAINS
                }
            },
            lottiePunchPendingAnim: {
                animation: Pending,
                height: 40,
                width: 40,
                speed: 0.5,
                loop: false
            },
            lottiePunchOkAnim: {
                animation: Success,
                height: 40,
                width: 40,
                speed: 0.5,
                loop: false
            },
            disableSyncButton: false,
            syncStatus: {
                name: null,
                status: null,
                elapsedTime: null
            },
            syncInterval: null,
            syncMenuItems: [
                {
                    label: 'Sincronizar informações',
                    items: [
                        {
                            label: 'Sincronizar tudo',
                            icon: 'fas fa-arrow-right',
                            command: () => this.SyncPunches()
                        },
                        {
                            label: 'Sincronizar apenas os selecionados',
                            icon: 'fas fa-arrow-right',
                            command: () => this.SyncPunches(true)
                        }
                    ]
                }
            ],
            home: { icon: 'pi pi-home', to: '/' },
            items: [
                { label: 'Geral', to: this.$router.currentRoute._value },
                { label: 'Pendências de pontos', to: this.$router.currentRoute._value }
            ],
            CollaboratorApi: new CollaboratorRouter()
        };
    },
    created() {
        this.FilterInit();
    },
    mounted() {
        this.pageRows = this.$refs.dt?.rows || 10;
        this.CheckSyncInProgress();
        this.LoadCollaboratorsWithPendingPunches();
    },
    unmounted() {
        clearInterval(this.syncInterval);
    },
    methods: {
        async LoadCollaboratorsWithPendingPunches() {
            this.loading = true;
            try {
                const { collaborators, totalRecords } = await this.CollaboratorApi.SearchWithPendingPunches(JSON.stringify(this.pagination));
                this.collaborators.records = collaborators;
                this.collaborators.totalRecords = totalRecords;
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },
        async SyncPunches(selectedsOnly = false) {
            if (selectedsOnly && this.collaborators.selection?.length == 0) {
                this.$toast.add({
                    severity: 'warn',
                    summary: 'Atenção',
                    detail: 'Você deve selecionar no mínimo 1 item para sincronização manual',
                    life: 3000
                });
                return;
            }

            this.disableSyncButton = true;

            let items = [];
            if (selectedsOnly) items = this.collaborators.selection?.map((i) => i.ID);

            this.CollaboratorApi.SyncPunches(items);
            this.syncInterval = setInterval(this.CheckSyncStatus, 1000);

            this.$toast.add({
                severity: 'info',
                summary: 'Sincronização em andamento',
                detail: 'A sincronização esta sendo realizada em segundo plano.',
                life: 5000
            });
        },
        async CheckSyncStatus(showMessages = true) {
            try {
                const response = await this.CollaboratorApi.CheckSyncStatus();

                this.syncStatus = {
                    name: response?.name,
                    status: response?.status,
                    elapsedTime: response?.elapsedTime
                };

                if (['completed', 'noSyncInProgress'].includes(response?.status)) {
                    clearInterval(this.syncInterval);
                    this.disableSyncButton = false;
                    this.LoadCollaboratorsWithPendingPunches();

                    if (showMessages) {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Status',
                            detail: `Sincronização concluída com sucesso.\nTempo decorrido: ${response?.elapsedTime || '(Não calculado)'}`,
                            life: 3000
                        });
                    }

                    return false;
                }

                return true;
            } catch (error) {
                console.error(error);
                clearInterval(this.syncInterval);
                this.disableSyncButton = false;
                this.syncStatus.status = 'completed';

                if (showMessages) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro',
                        detail: 'Erro ao verificar o status da sincronização',
                        life: 3000
                    });
                }

                return false;
            }
        },
        async CheckSyncInProgress() {
            let hasSyncInProgress = await this.CheckSyncStatus(false);
            if (hasSyncInProgress) {
                this.syncInterval = setInterval(this.CheckSyncStatus, 1000);
            }
        },
        ToggleSyncOptions(event) {
            this.$refs.menu.toggle(event);
        },
        NoItemsSelected() {
            return this.collaborators.selection.length == 0;
        },
        expandAll() {
            this.expandedRows = this.products.filter((p) => p.ID);
        },
        collapseAll() {
            this.expandedRows = null;
        },
        FilterInit() {
            this.pagination = {
                page: 1,
                rows: this.pageRows
            };
            this.filters = {
                global: {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS
                }
            };
        },
        ShowFilterModal() {
            this.filterModal.show = true;
        },
        MakeFilter(filter) {
            this.filterModal.selectedFilters = Object.assign({}, this.filterModal.selectedFilters, filter);
            this.filters.global = Object.assign({}, this.filters.global, filter);
        },
        ClearFilters() {
            this.filterModal.selectedFilters = [];
            this.FilterInit();
            this.hasFilters = false;
            this.OnFilter();
        },
        FilterSearch() {
            this.filterModal.show = false;
            this.OnFilter();
        },
        OnPage(event) {
            this.pagination = event;
            this.LoadCollaboratorsWithPendingPunches();
        },
        OnFilter() {
            if (Object.keys(this.filterModal.selectedFilters).length > 0) {
                this.hasFilters = true;
            }
            this.pagination.filters = this.filters;
            this.LoadCollaboratorsWithPendingPunches();
        },
        FormatDate(layout, date) {
            return moment(date).format(layout);
        }
    }
});
</script>

<template>
    <Breadcrumb :home="home" :model="items" />
    <Divider />
    <Card>
        <template #title>
            <span class="font-light text-700 text-xl">Colaboradores com pendências de pontos</span>
        </template>
        <template #content>
            <DataTable
                ref="dt"
                :value="collaborators.records"
                dataKey="ID"
                :paginator="true"
                :rows="10"
                v-model:expandedRows="expandedRows"
                v-model:filters="filters"
                :loading="loading"
                :totalRecords="collaborators.totalRecords"
                @page="OnPage($event)"
                @filter="OnFilter($event)"
                @sort="onSort($event)"
                lazy="true"
                v-model:selection="collaborators.selection"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rowsPerPageOptions="[10, 20, 50, 100, 300]"
                currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} entradas"
                responsiveLayout="scroll"
                class="p-datatable-sm"
            >
                <template #header>
                    <Toolbar style="flex-wrap: nowrap; gap: 1rem">
                        <template v-slot:start>
                            <div class="lg:mt-0 sm:mt-2 flex flex-row" style="gap: 0.5rem">
                                <div v-if="syncStatus.status == 'progress'">
                                    <div class="flex flex-column">
                                        <div class="flex flex-row align-items-center justify-content-left" style="gap: 5px">
                                            <i class="pi pi-spin pi-spinner text-xl" />
                                            <span class="text-800 text-lg font-light">Sincronizando com o Tangerino®...</span>
                                        </div>
                                        <span class="font-light text-700 text-md text-gray-500">{{ syncStatus.name }}</span>
                                    </div>
                                </div>
                                <div v-else>
                                    <Button
                                        type="button"
                                        icon="fas fa-sync"
                                        class="p-button-rounded p-button-info p-button-outlined p-button-raised"
                                        @click="ToggleSyncOptions($event)"
                                        :disabled="loading || disableSyncButton"
                                        title="Sincronizar informações"
                                        aria-haspopup="true"
                                        aria-controls="overlay_menu"
                                    />
                                </div>
                            </div>
                        </template>
                        <template v-slot:end>
                            <div class="lg:mt-0 sm:mt-2 flex flex-row" style="gap: 0.5rem">
                                <Button icon="fas fa-redo-alt" class="p-button-rounded p-button-info p-button-outlined p-button-raised" @click="LoadCollaboratorsWithPendingPunches()" :disabled="loading" title="Atualizar lista" />
                                <div class="relative">
                                    <Button v-if="hasFilters" icon="fas fa-broom" class="p-button-rounded p-button-raised clear-filter" title="Limpar filtros" @click="ClearFilters()" :disabled="loading" />
                                    <Button icon="fas fa-filter" class="p-button p-button-rounded p-button-info p-button-outlined p-button-raised" @click="filterModal.show = true" :disabled="loading" title="Escolher filtros" />
                                </div>
                            </div>
                        </template>
                    </Toolbar>
                    <Menu id="overlay_menu" ref="menu" :model="syncMenuItems" style="width: 22em" :popup="true" />
                </template>

                <Column :expander="true" headerStyle="width:.1%;" />
                <Column selectionMode="multiple" headerStyle="width:.1%;" />
                <Column field="colaborador" header="Colaborador" :sortable="false" headerStyle="width:25%; min-width:2rem;">
                    <template #body="slotProps">
                        <span class="p-column-title">Nome</span>
                        <span class="text-lg">{{ slotProps.data?.person?.name }}</span>
                    </template>
                </Column>
                <Column field="day_pendencies" header="Pendências/Dia" :sortable="false" headerStyle="width:20%; min-width:2rem;">
                    <template #body="slotProps">
                        <div class="flex align-items-start">
                            <div v-if="slotProps.data?.collaboratorPunch?.dayPendencies > 0" class="flex flex-row align-items-center" style="gap: 5px">
                                <lottie :lottieProp="this.lottiePunchPendingAnim" />
                                <span class="text-md text-orange-500">{{ slotProps.data?.collaboratorPunch?.dayPendencies }} dia{{ slotProps.data?.collaboratorPunch?.dayPendencies > 1 ? 's' : '' }} </span>
                            </div>
                            <div v-else>
                                <lottie :lottieProp="this.lottiePunchOkAnim" />
                            </div>
                        </div>
                    </template>
                </Column>
                <Column field="punch_pendencies" header="Pendências/Batidas" :sortable="false" headerStyle="width:20%; min-width:2rem;">
                    <template #body="slotProps">
                        <div class="flex align-items-start">
                            <div v-if="slotProps.data?.collaboratorPunch?.dayPendencies > 0" class="flex flex-row align-items-center" style="gap: 5px">
                                <lottie :lottieProp="this.lottiePunchPendingAnim" />
                                <span class="text-md text-orange-500">{{ slotProps.data?.collaboratorPunch?.punchPendencies }} pendência{{ slotProps.data?.collaboratorPunch?.punchPendencies > 1 ? 's' : '' }} </span>
                            </div>
                            <div v-else>
                                <lottie :lottieProp="this.lottiePunchOkAnim" />
                            </div>
                        </div>
                    </template>
                </Column>
                <Column field="last_sync" header="Última Notificação" :sortable="false" headerStyle="width:20%; min-width:2rem;">
                    <template #body="slotProps">
                        <span class="text-md" v-if="slotProps.data?.collaboratorPunch?.lastNotification">{{ FormatDate('DD/MM/YYYY HH:mm:ss', slotProps.data?.collaboratorPunch?.lastNotification) }}</span>
                    </template>
                </Column>
                <Column field="last_sync" header="Última Sincronização" :sortable="false" headerStyle="width:20%; min-width:2rem;">
                    <template #body="slotProps">
                        <span class="text-md" v-if="slotProps.data?.collaboratorPunch?.lastSync">{{ FormatDate('DD/MM/YYYY HH:mm:ss', slotProps.data?.collaboratorPunch?.lastSync) }}</span>
                    </template>
                </Column>
                <template #expansion="slotProps">
                    <DataTable :value="slotProps.data.collaboratorPunch?.punchesInfo" :showGridlines="true" v-if="slotProps.data?.collaboratorPunch?.punchPendencies > 0" resizableColumns="false">
                        <Column field="date" header="Data" bodyClass="text-center" headerStyle="width:15%">
                            <template #body="slotProps">
                                <span class="item-category">{{ slotProps.data?.date }}</span>
                            </template>
                        </Column>
                        <Column field="pendencies" header="Pendências" bodyClass="text-center" headerStyle="width:27%">
                            <template #body="slotProps">
                                <div class="grid-container">
                                    <div v-for="punch in slotProps.data.pendencies" v-bind:key="punch">
                                        <InputText type="text" class="w-12 text-900 text-center border-1 border-solid punch-time surface-overlay" style="border-color: red" :value="punch" readonly />
                                    </div>
                                </div>
                            </template>
                        </Column>
                        <Column field="hitPunches" header="Batidas" headerStyle="width:27%">
                            <template #body="slotProps">
                                <div class="grid-container">
                                    <div v-for="punch in slotProps.data.hitPunches" v-bind:key="punch">
                                        <InputText type="text" class="w-12 text-900 text-center border-1 border-solid punch-time border-bluegray-500 surface-overlay" :value="punch" readonly />
                                    </div>
                                </div>
                            </template>
                        </Column>
                        <Column field="expectedWorkSchedule" header="Escala do dia" headerStyle="width:27%">
                            <template #body="slotProps">
                                <div class="grid-container">
                                    <div v-for="punch in slotProps.data.workSchedule" v-bind:key="punch">
                                        <InputText type="text" class="w-12 text-900 text-center border-1 border-solid punch-time border-bluegray-500 surface-overlay" :value="punch" readonly />
                                    </div>
                                </div>
                            </template>
                        </Column>
                    </DataTable>
                </template>
            </DataTable>
        </template>
    </Card>
    <Dialog v-model:visible="filterModal.show" header="Filtro avançado" :modal="true" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '30vw' }" :maximizable="false">
        <FilterModal @filterSelection="MakeFilter($event)" :showFilters="filterModal.showFilters" :currentFilters="filterModal.selectedFilters" />
        <template #footer>
            <div class="flex flex-row justify-content-between">
                <Button label="Cancelar" iconPos="right" icon="pi pi-times" autofocus @click="CloseFilterModal()" class="w-12rem" />
                <Button label="Pesquisar" iconPos="right" icon="pi pi-search" autofocus @click="FilterSearch()" class="w-12rem" />
            </div>
        </template>
    </Dialog>
</template>

<style>
.grid-container {
    display: grid;
    grid-auto-flow: column dense;
    grid-template-rows: 40px 40px;
    grid-auto-columns: 70px;
    column-gap: 5px;
    row-gap: 8px;
    align-items: center;
    justify-content: center;
}

.card {
    padding: 2rem;
    border-radius: 4px;
    margin-bottom: 2rem;
}

.item-category {
    font-size: 16px;
    font-weight: 600;
    vertical-align: bottom;
}

.punch-time {
    font-size: 14px;
    font-weight: 600;
    vertical-align: middle;
    align-items: center;
}
</style>
