import AxiosInstance from '@/services/AxiosInstance';

const userEndpoint = '/users';

/**
 * Class to interact with the User API
 */
export default class UserRouter {
    /**
     * Searches for users based on the provided filter parameters.
     *
     * @async
     * @param {Object} filterParams - The search criteria for users.
     * @returns {Promise<Object>} - A promise that resolves to the search results.
     * @throws {Error} - Throws an error if the search fails.
     */
    async Search(filterParams) {
        try {
            const response = await AxiosInstance.get(`${userEndpoint}?search=${filterParams}`);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to search users: ${error.message}`);
        }
    }

    /**
     * Creates a new user with the provided data.
     *
     * @async
     * @param {Object} payload - The data for the new user.
     * @returns {Promise<Object>} - A promise that resolves to the created user data.
     * @throws {Error} - Throws an error if creation fails.
     */
    async Create(payload) {
        try {
            const response = await AxiosInstance.post(userEndpoint, payload);
            return response?.data;
        } catch (error) {
            throw new Error(`Failed to create user: ${error.message}`);
        }
    }

    /**
     * Updates an existing user with the provided data.
     *
     * @async
     * @param {Object} payload - The updated data for the user.
     * @param {number} id - The unique identifier of the user.
     * @returns {Promise<Object>} - A promise that resolves to the updated user data.
     * @throws {Error} - Throws an error if the update fails.
     */
    async Update(payload, id) {
        try {
            const response = await AxiosInstance.put(`${userEndpoint}/${id}`, payload);
            return response?.data;
        } catch (error) {
            throw new Error(`Failed to update user: ${error.message}`);
        }
    }

    /**
     * Removes one or more users based on the provided data.
     *
     * @async
     * @param {Object} payload - The data identifying the users to remove.
     * @returns {Promise<Object>} - A promise that resolves when the removal is complete.
     * @throws {Error} - Throws an error if removal fails.
     */
    async Remove(payload) {
        try {
            const response = await AxiosInstance.delete(userEndpoint, { data: payload });
            return response?.data;
        } catch (error) {
            throw new Error(`Failed to remove user(s): ${error.message}`);
        }
    }

    /**
     * Switches to a different user by their ID.
     *
     * @async
     * @param {number} id - The unique identifier of the user to switch to.
     * @returns {Promise<Object>} - A promise that resolves to the switch result.
     * @throws {Error} - Throws an error if the switch fails.
     */
    async Switch(id) {
        try {
            const response = await AxiosInstance.post(`${userEndpoint}/switch/${id}`);
            return response?.data;
        } catch (error) {
            throw new Error(`Failed to switch user: ${error.message}`);
        }
    }

    /**
     * Generates a new password for the user by their ID.
     *
     * @async
     * @param {number} id - The unique identifier of the user.
     * @returns {Promise<Object>} - A promise that resolves to the new password.
     * @throws {Error} - Throws an error if password generation fails.
     */
    async GenerateNewPassword(id) {
        try {
            const response = await AxiosInstance.post(`${userEndpoint}/generate-new-password/${id}`);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to generate new password: ${error.message}`);
        }
    }
}
