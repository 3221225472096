import AxiosInstance from '@/services/AxiosInstance';

const quickValidationEndpoint = '/quick-validation';

/**
 * Class to interact with the Quick Validation API
 */
export default class QuickValidationRouter {
    /**
     * Retrieves the list of pending envelopes for validation.
     *
     * @async
     * @returns {Promise<Object>} - A promise that resolves to the list of pending envelopes.
     * @throws {Error} - Throws an error if retrieval fails.
     */
    async PendingEnvelopes() {
        try {
            const response = await AxiosInstance.get(`${quickValidationEndpoint}/pending-envelopes`);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to retrieve pending envelopes: ${error.message}`);
        }
    }

    /**
     * Retrieves the document view for a specific document by its ID.
     *
     * @async
     * @param {number} documentId - The unique identifier of the document.
     * @returns {Promise<Object>} - A promise that resolves to the document view data.
     * @throws {Error} - Throws an error if retrieval fails.
     */
    async DocumentView(documentId) {
        try {
            const response = await AxiosInstance.get(`${quickValidationEndpoint}/document-view/${documentId}`);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to retrieve document view: ${error.message}`);
        }
    }

    /**
     * Adds an envelope event with the provided data.
     *
     * @async
     * @param {Object} payload - The data for the envelope event.
     * @returns {Promise<Object>} - A promise that resolves when the envelope event is added.
     * @throws {Error} - Throws an error if the addition fails.
     */
    async AddEnvelopeEvent(payload) {
        try {
            const response = await AxiosInstance.post(`${quickValidationEndpoint}/add-envelope-event`, payload);
            return response?.data;
        } catch (error) {
            throw new Error(`Failed to add envelope event: ${error.message}`);
        }
    }
}
