import AxiosInstance from '@/services/AxiosInstance';

const menuTypesEndpoint = '/menu-types';

/**
 * Class to interact with the Menu Types API
 */
export default class MenuTypesRouter {
    /**
     * Retrieves all menu types.
     *
     * @async
     * @returns {Promise<Object>} - A promise that resolves to the list of all menu types.
     * @throws {Error} - Throws an error if retrieval fails.
     */
    async FindAll() {
        try {
            const response = await AxiosInstance.get(menuTypesEndpoint);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to retrieve menu types: ${error.message}`);
        }
    }
}
