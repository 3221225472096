import AxiosInstance from '@/services/AxiosInstance';

const dashboardEndpoint = '/dashboard';
const widgetsEndpoint = `${dashboardEndpoint}/widgets`;

/**
 * Class to interact with the Dashboard API
 */
export default class DashboardRouter {
    /**
     * Retrieves the count of envelopes for the dashboard widget.
     *
     * @async
     * @returns {Promise<Object>} - A promise that resolves to the envelope count data.
     * @throws {Error} - Throws an error if the retrieval fails.
     */
    async EnvelopesCount() {
        try {
            const response = await AxiosInstance.get(`${widgetsEndpoint}/envelopes-count`);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to retrieve envelopes count: ${error.message}`);
        }
    }

    /**
     * Retrieves the punches information for the dashboard widget.
     *
     * @async
     * @returns {Promise<Object>} - A promise that resolves to the punches info data.
     * @throws {Error} - Throws an error if the retrieval fails.
     */
    async PunchesInfo() {
        try {
            const response = await AxiosInstance.get(`${widgetsEndpoint}/punches/info`);
            return response?.data?.data;
        } catch (error) {
            throw new Error(`Failed to retrieve punches info: ${error.message}`);
        }
    }
}
